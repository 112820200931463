import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "@/app/form";

import { usePlaceOrderContext } from "../context";

type Props = {
  isPendingOrder: ReturnType<typeof usePlaceOrderContext>["isPendingOrder"];
  isBuyOrder: ReturnType<typeof usePlaceOrderContext>["isBuyOrder"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  isDemoAccount: boolean;
  blockedFunds: number | void;
};

const PlaceOrderSubmitButton: FC<Props> = ({
  hasNoFreeMargin,
  isBuyOrder,
  isDemoAccount,
  isPendingOrder,
  blockedFunds,
}) => {
  const { t } = useTranslation();

  const label = useMemo(() => {
    if (hasNoFreeMargin) {
      if (isDemoAccount) {
        return t("terminal.place-order.submit-button.deposit-demo");
      }

      if (blockedFunds) {
        return t("terminal.source-of-funds.banner-button");
      }

      return t("terminal.place-order.submit-button.deposit-real");
    }
    if (isPendingOrder) {
      return t("terminal.place-order.submit-button.pending");
    }
    if (isBuyOrder) {
      return t("terminal.place-order.submit-button.market-buy");
    }

    return t("terminal.place-order.submit-button.market-sell");
  }, [blockedFunds, hasNoFreeMargin, isBuyOrder, isDemoAccount, isPendingOrder, t]);

  return <SubmitButton fullWidth>{label}</SubmitButton>;
};

export { PlaceOrderSubmitButton };
