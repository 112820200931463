import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { authRoutes } from "@/routes/auth.routes";
import type { PlatformInfo } from "@/services/openapi";
import { Checkbox, EmailInput, Link as UILink, PasswordInput, Text } from "@/shared/ui";

import { AuthDownloadButtons } from "../../download-buttons";
import { OAuthButtonsBlock } from "../../oauth/buttons-block";

const Anchor: FC<any> = props => (
  <UILink variant="inherit" asChild>
    <Link to={authRoutes.signUp} data-test="sign-in-sign-up-link" {...props} />
  </UILink>
);

enum Fields {
  EMAIL = "email",
  PASSWORD = "password",
  REMEMBER_ME = "rememberMe",
}

type FormValues = {
  [Fields.EMAIL]: string;
  [Fields.PASSWORD]: string;
  [Fields.REMEMBER_ME]: boolean;
};

type Props = {
  onSubmit: (values: FormValues) => void;
  platformInfo: PlatformInfo;
  showDownloadButtons: boolean;
  downloadLink: string;
};

const SignInForm: FC<Props> = ({ onSubmit, platformInfo, showDownloadButtons, downloadLink }) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.EMAIL]: "",
      [Fields.PASSWORD]: "",
      [Fields.REMEMBER_ME]: false,
    },
  });

  const { control } = form;

  const [emailField, { invalid: emailInvalid, pending: pendingEmail, errorMessage: errorMessageEmail }] =
    useControlledField({
      name: Fields.EMAIL,
      control,
      rules: {
        required: t("auth.errors.email-required")!,
      },
    });

  const [passwordField, { invalid: passwordInvalid, pending: pendingPassword, errorMessage: errorMessagePassword }] =
    useControlledField({
      name: Fields.PASSWORD,
      control,
      rules: {
        required: t("auth.errors.password-required")!,
      },
    });

  const [{ value: rememberValue, onChange: rememberOnChange, ...checkboxField }] = useControlledField({
    name: Fields.REMEMBER_ME,
    control,
  });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <div className="flex flex-col gap-4">
        <EmailInput
          label={t("auth.common.email")}
          pending={pendingEmail}
          invalid={emailInvalid}
          descriptor={errorMessageEmail}
          data-test="sign-in-email-input"
          {...emailField}
        />
        <PasswordInput
          autoComplete="current-password"
          label={t("auth.common.password")}
          pending={pendingPassword}
          invalid={passwordInvalid}
          descriptor={errorMessagePassword}
          data-test="sign-in-password-input"
          {...passwordField}
        />
      </div>
      <Checkbox
        className="mb-6 mt-3"
        label={t("auth.sign-in.remember-me")}
        checked={rememberValue}
        onCheckedChange={rememberOnChange}
        {...checkboxField}
      />

      <SubmitButton fullWidth data-test="sign-in-btn">
        {t("auth.sign-in.action")}
      </SubmitButton>

      {platformInfo.signIn!.facebook!.isEnabled && platformInfo.signIn!.google!.isEnabled && (
        <OAuthButtonsBlock className="mb-9 mt-7" platformInfo={platformInfo} />
      )}

      <Text color="primary" variant="M Compact / Medium" align="center" className="mb-4 mt-7">
        <Trans
          i18nKey="auth.sign-in.sign-up-link"
          components={{
            anchor: <Anchor />,
          }}
        />
      </Text>
      <div className="grid place-items-center">
        <UILink variant="M Compact / Medium" asChild>
          <Link to={authRoutes.forgotPassword}>{t("auth.sign-in.forgot-password-link")}</Link>
        </UILink>
      </div>
      {showDownloadButtons && <AuthDownloadButtons className="mt-14" link={downloadLink} />}
    </HookForm>
  );
};

export { SignInForm };
