import type { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";

import { NotificationsContainer } from "./notifications.container";

const NotificationsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <CabinetPage
      size="sm"
      header={
        <CabinetHeader backButton={<CabinetHeader.BackLink to={cabinetRoutes.profileSettings} />}>
          <CabinetHeader.Title>{t("cabinet.navigation.notifications")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <NotificationsContainer />
    </CabinetPage>
  );
};

export { NotificationsPage };
