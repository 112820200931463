import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { terminalAccountParamsName } from "@/routes/terminal.routes";
import { AvailabilityStatus, TradingServerPlatform } from "@/services/openapi";
import { useAccountQuery, useLastAccountQuery } from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useDateQuery } from "@/state/server/platform";
import {
  useSymbolsUpdatesQuery,
  useTerminalSymbolsFavoritesQuery,
  useTerminalSymbolsQuery,
} from "@/state/server/terminal";

import { useTerminalNavigate } from "./helpers";
import { setServerTime } from "./helpers/server-time";

const useTerminalHandle = ({
  accountIdUrl,
  isMobileAppMode,
}: {
  accountIdUrl: string | null;
  isMobileAppMode: boolean;
}) => {
  const navigate = useTerminalNavigate(isMobileAppMode);
  const [_, setSearchParams] = useSearchParams();

  const { data: lastAccount } = useLastAccountQuery(
    { status: AvailabilityStatus.Active, platform: TradingServerPlatform.MetaTrader5 },
    {
      enabled: !accountIdUrl,
      cacheTime: 0,
      staleTime: 0,
      onError: () => {
        navigate(cabinetRoutes.myAccounts, { replace: true });
      },
    },
  );

  useEffect(() => {
    if (!lastAccount || accountIdUrl) {
      return;
    }

    setSearchParams(
      prevParams => {
        prevParams.set(terminalAccountParamsName, lastAccount.id!);
        return prevParams;
      },
      {
        replace: true,
      },
    );
  }, [lastAccount]);

  // account
  const { data: account } = useAccountQuery(accountIdUrl!, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: () => {
      navigate(cabinetRoutes.myAccounts, { replace: true });
    },
    enabled: !!accountIdUrl,
  });

  // symbols
  const { data: symbols } = useTerminalSymbolsQuery(account?.id!, {
    enabled: !!account,
  });
  const { data: symbolsUpdates } = useSymbolsUpdatesQuery(
    { tradingAccountId: account?.id! },
    { cacheTime: 0, enabled: !!account },
  );
  const { data: favorites } = useTerminalSymbolsFavoritesQuery(accountIdUrl!, {
    cacheTime: 0,
    enabled: !!accountIdUrl,
  });

  // other
  const { data: bonuses } = useBonusesQuery();

  const { isSuccess: dateIsSuccess } = useDateQuery({
    onSuccess: res => {
      setServerTime(res as string);
    },
  });

  const showLoader = !account || !symbolsUpdates || !symbols || !favorites || !bonuses || !dateIsSuccess;

  return {
    account: account!,
    symbolsUpdates: symbolsUpdates!,
    symbols: symbols!,
    favorites: favorites!,
    bonuses: bonuses!,
    showLoader,
  };
};

export { useTerminalHandle };
