import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { NumberFormat, PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { TooltipQuestionIcon } from "@/components/icons";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { type BonusUserPlatform, TradingAccountType } from "@/services/openapi";
import { DataList, Tooltip } from "@/shared/ui";

type Props = {
  showBonus: boolean;
  accountCurrency: string;
  equity: number;
  marginLevel: number;
  margin: number;
  pnl: number;
  currencyDecimalScale: number;
  marginFree: number;
  credit: number;
  balance: number;
  type: TradingAccountType;
  bonus: BonusUserPlatform | null;
  availableToWithdraw: number;
  blockedFunds: number | void;
};

const AccountDetailsDataList: FC<Props> = ({
  showBonus,
  accountCurrency,
  equity,
  marginLevel,
  margin,
  pnl,
  currencyDecimalScale,
  marginFree,
  credit,
  balance,
  type,
  bonus,
  availableToWithdraw,
  blockedFunds,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="rounded-lg border border-card-border bg-card-bg p-4">
      <DataList>
        {!!blockedFunds && (
          <DataList.Item
            label={
              <DataList.Label
                tooltip={
                  <Tooltip
                    content={t("terminal.source-of-funds.banner-text")}
                    action={
                      <Tooltip.Action onClick={() => navigate(onboardingRoutes.kyc)}>
                        {t("terminal.source-of-funds.banner-button")}
                      </Tooltip.Action>
                    }
                  >
                    <TooltipQuestionIcon size="sm" />
                  </Tooltip>
                }
              >
                {t("terminal.account-details.blocked-amount")}
              </DataList.Label>
            }
            value={
              <DataList.Value>
                <PositiveNumberFormat
                  value={blockedFunds}
                  decimalScale={currencyDecimalScale}
                  currency={accountCurrency}
                />
              </DataList.Value>
            }
          />
        )}
        <DataList.Item
          label={
            <DataList.Label
              tooltip={
                <Tooltip content={t("terminal.account-details.equity-description")}>
                  <TooltipQuestionIcon size="sm" />
                </Tooltip>
              }
            >
              {t("terminal.account-details.equity")}
            </DataList.Label>
          }
          value={
            <DataList.Value>
              <PositiveNumberFormat value={equity} decimalScale={currencyDecimalScale} currency={accountCurrency} />
            </DataList.Value>
          }
        />
        {type === TradingAccountType.Real && (
          <DataList.Item
            label={
              <DataList.Label
                tooltip={
                  <Tooltip content={t("terminal.account-details.balance-description")}>
                    <TooltipQuestionIcon size="sm" />
                  </Tooltip>
                }
              >
                {t("terminal.account-details.balance")}
              </DataList.Label>
            }
            value={
              <DataList.Value>
                <PositiveNumberFormat value={balance} decimalScale={currencyDecimalScale} currency={accountCurrency} />
              </DataList.Value>
            }
          />
        )}
        {showBonus && bonus && (
          <DataList.Item
            label={
              <DataList.Label
                tooltip={
                  <BonusTooltip
                    bonus={bonus}
                    actionText={t("accounts.list.bonus.button")}
                    content={t("accounts.list.bonus.description", { percent: getBonusMainPercent(bonus) })}
                  />
                }
              >
                {t("terminal.account-details.bonus")}
              </DataList.Label>
            }
            value={
              <DataList.Value>
                <NumberFormat value={credit} decimalScale={currencyDecimalScale} currency={accountCurrency} />
              </DataList.Value>
            }
          />
        )}
        <DataList.Item
          label={<DataList.Label>{t("terminal.account-details.pnl")}</DataList.Label>}
          value={
            <DataList.Value color={getNumberTextColor(pnl)}>
              <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={accountCurrency} />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={
            <DataList.Label
              tooltip={
                <Tooltip content={t("terminal.account-details.margin-description")}>
                  <TooltipQuestionIcon size="sm" />
                </Tooltip>
              }
            >
              {t("terminal.account-details.margin")}
            </DataList.Label>
          }
          value={
            <DataList.Value>
              <NumberFormat value={margin} decimalScale={currencyDecimalScale} currency={accountCurrency} />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={
            <DataList.Label
              tooltip={
                <Tooltip content={t("terminal.account-details.free-margin-description")}>
                  <TooltipQuestionIcon size="sm" />
                </Tooltip>
              }
            >
              {t("terminal.account-details.free-margin")}
            </DataList.Label>
          }
          value={
            <DataList.Value>
              <PositiveNumberFormat value={marginFree} decimalScale={currencyDecimalScale} currency={accountCurrency} />
            </DataList.Value>
          }
        />
        {type === TradingAccountType.Real && (
          <DataList.Item
            label={<DataList.Label>{t("terminal.account-details.available-to-withdraw")}</DataList.Label>}
            value={
              <DataList.Value>
                <NumberFormat
                  value={availableToWithdraw}
                  decimalScale={currencyDecimalScale}
                  currency={accountCurrency}
                />
              </DataList.Value>
            }
          />
        )}
        <DataList.Item
          label={
            <DataList.Label
              tooltip={
                showBonus && (
                  <Tooltip content={t("terminal.account-details.margin-level.description")}>
                    <TooltipQuestionIcon size="sm" />
                  </Tooltip>
                )
              }
            >
              {t("terminal.account-details.margin-level.title")}
            </DataList.Label>
          }
          value={
            <DataList.Value>
              {marginLevel !== 0 ? <NumberFormat value={marginLevel} decimalScale={0} suffix="%" /> : "–"}
            </DataList.Value>
          }
        />
      </DataList>
    </div>
  );
};

export { AccountDetailsDataList };
