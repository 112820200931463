import { cva, type VariantProps } from "class-variance-authority";
import { Slot } from "radix-ui";
import type { ComponentPropsWithoutRef, ElementRef, FC, ReactNode } from "react";
import { forwardRef } from "react";

import { IconClose } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { Image, Text, UnstyledButton } from "..";

const ActionButton = forwardRef<
  ElementRef<typeof UnstyledButton>,
  ComponentPropsWithoutRef<typeof UnstyledButton> & { asChild?: boolean }
>(({ asChild, ...props }, ref) => {
  const Comp = asChild ? Slot.Root : UnstyledButton;
  return <Comp className="typography-S-Medium text-positive-text" ref={ref} {...props} />;
});

const CloseButton = forwardRef<ElementRef<typeof UnstyledButton>, ComponentPropsWithoutRef<typeof UnstyledButton>>(
  (props, ref) => (
    <UnstyledButton
      className="absolute end-4 top-4 grid size-6 place-items-center rounded-full bg-static-white/20 text-static-white backdrop-blur-[2px] *:size-4"
      ref={ref}
      {...props}
    >
      <IconClose />
    </UnstyledButton>
  ),
);

const imgSmStyles = cva("-end-[95px] bottom-[-60px] h-[190px] w-[269px]");
const imgMdStyles = cva("md:-bottom-[53px] md:-end-[20px] md:h-[190px] md:w-[269px]");

const imgStyles = cva("absolute aspect-square max-w-[inherit] object-cover", {
  variants: {
    size: {
      sm: imgSmStyles(),
      default: cn(imgSmStyles(), imgMdStyles()),
    },
  },
});

type Props = {
  text: string;
  actionButton: ReactNode;
  closeButton?: ReactNode;
  bonusImg?: string;
  bonusImgSize?: VariantProps<typeof imgStyles>["size"];
};

const Banner: FC<Props> = ({ text, actionButton, closeButton, bonusImg, bonusImgSize = "default" }) => {
  return (
    <div className="relative flex overflow-hidden rounded-xl bg-surface-elevation-3 md:min-h-[92px]">
      <div className="flex w-2/3 flex-col items-start gap-2 px-6 py-5 pe-1 md:w-3/4">
        <Text color="staticWhite" variant="M / Medium">
          {text}
        </Text>
        {actionButton}
      </div>
      {/* TODO: image styles should come from component props */}
      {bonusImg && (
        <div className="relative grid grow place-items-center">
          <Image src={bonusImg} className={imgStyles({ size: bonusImgSize })} alt="" />
        </div>
      )}
      {closeButton}
    </div>
  );
};

const Component = Object.assign(Banner, { ActionButton, CloseButton });

export { Component as Banner, type Props as BannerProps };
