import { type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Text, ToggleGroup } from "@/shared/ui";

import { useLayoutContext } from "../../layout/context";
import { useCurrentSymbolContext } from "../../symbol-info/current-symbol-context";
import { SymbolInfoDialogButton } from "../../symbol-info/dialog/button";

type Props = {
  children?: ReactNode;
};

const MobilePlaceOrderTabs: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const { insightsSection, changeInsightsSection } = useLayoutContext();

  const { symbolInfo } = useCurrentSymbolContext();

  return (
    <>
      <div className="mb-3 flex flex-col gap-1 px-4">
        <div className="flex items-center justify-between gap-2">
          <Text as="h3" color="primary" variant="M / Medium">
            {symbolInfo.name}
          </Text>
          <SymbolInfoDialogButton />
        </div>
        <ToggleGroup value={insightsSection === "none" ? "none" : "analytics"} onValueChange={changeInsightsSection}>
          <ToggleGroup.Item value="none">{t("terminal.place-order.tabs.trade")}</ToggleGroup.Item>
          <ToggleGroup.Item value="analytics">{t("terminal.place-order.tabs.insights")}</ToggleGroup.Item>
        </ToggleGroup>
      </div>
      {children}
    </>
  );
};

export { MobilePlaceOrderTabs };
