import { getHome } from "@/services/home";
import type { ApiResponseType } from "@/services/types";

import { useBaseQuery } from "..";
import type { QueryOptionsType } from "../types";
import { homeQueryKeys } from ".";

export const useHomeQuery = (options?: QueryOptionsType<ApiResponseType<typeof getHome>>) => {
  return useBaseQuery<ApiResponseType<typeof getHome>>({
    queryKey: homeQueryKeys.base,
    queryFn: getHome,
    ...options,
  });
};
