import { type FC } from "react";

import { PaymentLoader } from "@/features/payment/ui/loader";
import type { PaymentMethod, TradingAccount } from "@/services/openapi";
import { useDepositMethodQuery } from "@/state/server/payment";

import { PaymentMethodStep } from "./step";

type Props = {
  currentAccount: TradingAccount;
  setPaymentMethod: (method: PaymentMethod) => void;
  currentPaymentMethod: PaymentMethod | null;
  next: () => void;
};

const PaymentMethodStepContainer: FC<Props> = ({ currentAccount, next, setPaymentMethod, currentPaymentMethod }) => {
  const { data: paymentMethodsData } = useDepositMethodQuery({
    accountId: currentAccount.id!,
  });

  return paymentMethodsData ? (
    <PaymentMethodStep
      next={next}
      categories={paymentMethodsData.categories!}
      currentPaymentMethod={currentPaymentMethod}
      paymentMethods={paymentMethodsData.items!}
      setPaymentMethod={setPaymentMethod}
      currentAccount={currentAccount}
    />
  ) : (
    <PaymentLoader />
  );
};

export { PaymentMethodStepContainer };
