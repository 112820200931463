import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import { Checkbox } from "@/shared/ui";

import { ProfileFields } from "./profile.form";

const NotUSACitizenField: FC = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const [{ value, onChange, ...field }, { invalid }] = useControlledField({
    name: ProfileFields.IS_NOT_USA_RESIDENT,
    control,
    rules: {
      required: true,
    },
  });

  return (
    <Checkbox
      label={t("onboarding.address.us-citizen-checkbox")}
      invalid={invalid}
      checked={value}
      onCheckedChange={onChange}
      {...field}
    />
  );
};

export { NotUSACitizenField };
