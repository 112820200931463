import type { FC } from "react";

import type {
  BonusUserPlatform,
  TerminalAccountSymbols,
  TerminalSymbols,
  TerminalSymbolsLiveData,
  TradingAccount,
} from "@/services/openapi";

import { TerminalAccountSummaryContextProvider } from "../account-summary/context";
import { TerminalChartContextProvider } from "../chart/context";
import { TerminalLayoutContextProvider } from "../layout/context";
import { TerminalOrdersContextProvider } from "../orders/context";
import { TerminalExtendedOrdersContextProvider } from "../orders/extended-orders.context";
import { TerminalSignalContextProvider } from "../signal/context";
import { TerminalCurrentSymbolContextProvider } from "../symbol-info/current-symbol-context";
import { TerminalAccountContextProvider } from "./account.context";
import { TerminalSymbolsContextProvider } from "./symbols.context";

interface Props {
  symbols: TerminalSymbols;
  symbolsUpdates: TerminalSymbolsLiveData;
  favorites: TerminalAccountSymbols;
  account: TradingAccount;
  bonuses: BonusUserPlatform[];
  children: React.ReactNode;
  symbol: string;
  isMobile: boolean;
  isMobileAppMode: boolean;
  isMobileChartPage: boolean;
  changeSymbol: (symbol: string) => void;
}

export const TerminalProvider: FC<Props> = ({
  children,
  account,
  bonuses,
  favorites,
  symbolsUpdates,
  symbols,
  changeSymbol,
  symbol,
  isMobileAppMode,
  isMobile,
  isMobileChartPage,
}) => {
  return (
    <TerminalLayoutContextProvider
      isMobile={isMobile}
      accountType={account.type!}
      isMobileAppMode={isMobileAppMode}
      isMobileChartPage={isMobileChartPage}
    >
      <TerminalAccountContextProvider account={account} bonuses={bonuses}>
        <TerminalSymbolsContextProvider
          accountId={account.id!}
          symbolsUpdates={symbolsUpdates}
          symbols={symbols}
          favorites={favorites}
        >
          <TerminalCurrentSymbolContextProvider symbol={symbol} changeSymbol={changeSymbol}>
            <TerminalSignalContextProvider>
              <TerminalOrdersContextProvider account={account}>
                <TerminalExtendedOrdersContextProvider
                  currencyDecimalScale={account.digits!}
                  accountCurrency={account.currency!}
                  leverage={account.leverage!}
                >
                  <TerminalAccountSummaryContextProvider account={account}>
                    <TerminalChartContextProvider accountId={account.id!}>{children}</TerminalChartContextProvider>
                  </TerminalAccountSummaryContextProvider>
                </TerminalExtendedOrdersContextProvider>
              </TerminalOrdersContextProvider>
            </TerminalSignalContextProvider>
          </TerminalCurrentSymbolContextProvider>
        </TerminalSymbolsContextProvider>
      </TerminalAccountContextProvider>
    </TerminalLayoutContextProvider>
  );
};
