import dayjs from "dayjs";
import { atom } from "nanostores";
import { useEffect } from "react";

import { logError } from "@/app/libs/sentry";
import {
  type WebSocketsCommand,
  type WebSocketsCommands,
  WebSocketsCommandType,
  type WebSocketsEventAccounts,
  type WebSocketsEventAccountsBalances,
  type WebSocketsEventDeals,
  type WebSocketsEventDealsNotices,
  type WebSocketsEventMarketWatch,
  type WebSocketsEventNotices,
  type WebSocketsEventSymbolsHistoryPrices,
  type WebSocketsEventSymbolsSessions,
  type WebSocketsEventSymbolsSignals,
  type WebSocketsEventSymbolsWidgets,
  type WebSocketsEventTick,
  type WebSocketsEventTradingInit,
  WebSocketsEventType,
} from "@/services/openapi";
import { token } from "@/utils/token";

import { $wsUrl } from "../api";

// https://space307.atlassian.net/wiki/spaces/DevDoto/pages/656343152/WebSockets+2.0

// TODO:
// handle initialize call duplicates
// handle server subscriptions
// Trading notifications

const $tradingInitMessage = atom<WebSocketsEventTradingInit | null>(null);
const $tickMessage = atom<WebSocketsEventTick | null>(null);
const $symbolsWidgetsMessage = atom<WebSocketsEventSymbolsWidgets | null>(null);
const $symbolsSignalsMessage = atom<WebSocketsEventSymbolsSignals | null>(null);
const $symbolsSessionsMessage = atom<WebSocketsEventSymbolsSessions | null>(null);
const $symbolsHistoryPricesMessage = atom<WebSocketsEventSymbolsHistoryPrices | null>(null);
const $noticesMessage = atom<WebSocketsEventNotices | null>(null);
const $marketWatchMessage = atom<WebSocketsEventMarketWatch | null>(null);
const $dealsNoticesMessage = atom<WebSocketsEventDealsNotices | null>(null);
const $dealsMessage = atom<WebSocketsEventDeals | null>(null);
const $accountsBalancesMessage = atom<WebSocketsEventAccountsBalances | null>(null);
const $accountsMessage = atom<WebSocketsEventAccounts | null>(null);
// TODO:
const $userMessage = atom<any | null>(null);

class WebSocketClient {
  private socket: WebSocket | null;
  private connectionUrl: string | null;
  private tokenString: string | null;
  private reconnectCount: number = 0;
  private maximumReconnectAttempts: number = 5;
  private pingTimer: NodeJS.Timer | null = null;
  private heartbeatTimer: NodeJS.Timer | null = null;
  private reconnectTimer: NodeJS.Timer | null = null;
  private heartbeatDate: dayjs.Dayjs | null = null;
  private subscriptions: Set<
    | "tick"
    | "marketWatch"
    | "accounts"
    | "accountsBalances"
    | "tradingInit"
    | "deals"
    | "dealsNotices"
    | "symbolsSessions"
    | "signals"
    | "widgets"
    | "historyPrices"
    | "notices"
    | "user"
  > = new Set();
  private subscribeCommands: WebSocketsCommand[] = [];

  constructor() {
    this.socket = null;
    this.connectionUrl = null;
    this.tokenString = null;
  }

  private get fullConnectionUrl() {
    if (!this.connectionUrl) {
      return this.connectionUrl;
    }

    return `${this.connectionUrl}/v2.0/ws`;
  }

  initialize(url: string, tokenString: string) {
    this.connectionUrl = url;
    this.tokenString = tokenString;

    this.connect();
  }

  close() {
    this.cancelReconnectTimer();

    if (this.socket && (this.socket.readyState === WebSocket.CONNECTING || this.socket.readyState === WebSocket.OPEN)) {
      this.socket.onclose = () => {};
      this.socket.close(1000); // FIXME: code and reason doesn't work
      this.socket = null;
      this.subscribeCommands = [];
      this.clearMessages();
      this.subscriptions.clear();
      this.reconnectCount = 0;
      this.cancelPingTimer();
      this.cancelHeartbeatTimer();
    }
  }

  private clearMessages() {
    $tradingInitMessage.set(null);
    $tickMessage.set(null);
    $symbolsWidgetsMessage.set(null);
    $symbolsSignalsMessage.set(null);
    $symbolsSessionsMessage.set(null);
    $symbolsHistoryPricesMessage.set(null);
    $noticesMessage.set(null);
    $marketWatchMessage.set(null);
    $dealsNoticesMessage.set(null);
    $dealsMessage.set(null);
    $accountsBalancesMessage.set(null);
    $accountsMessage.set(null);
    $userMessage.set(null);
  }

  updateConnectionUrl(url: string) {
    this.connectionUrl = url;
  }

  updateTokenString(tokenString: string) {
    this.tokenString = tokenString;
  }

  private reconnect() {
    if (this.reconnectCount > this.maximumReconnectAttempts) {
      logError("Maximum websocket reconnect attempts reached");
      return;
    }

    const delay = 1000 * Math.pow(2, this.reconnectCount); // exponential backoff

    this.reconnectTimer = setTimeout(() => {
      this.connect();
    }, delay);
  }

  private handlePing() {
    this.cancelPingTimer();

    this.pingTimer = setInterval(() => {
      this.ping();
    }, 1200000); // 20 minutes
  }

  private cancelPingTimer() {
    if (this.pingTimer) {
      clearInterval(this.pingTimer);
    }
  }

  private handleHeartbeat() {
    this.cancelHeartbeatTimer();

    this.heartbeatTimer = setInterval(() => {
      const currentDate = dayjs();

      // 3 minutes
      if (this.heartbeatDate && currentDate.diff(this.heartbeatDate) > 180000) {
        this.reconnect();
      }
    }, 10000);
  }

  private cancelHeartbeatTimer() {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
    }
  }

  private cancelReconnectTimer() {
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
    }
  }

  private sendMessage(commands: WebSocketsCommand | WebSocketsCommand[]) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const msg: WebSocketsCommands = { commands: Array.isArray(commands) ? commands : [commands] };
      this.socket.send(JSON.stringify(msg));
      return;
    }
  }

  private connect() {
    this.socket = new WebSocket(this.fullConnectionUrl!);

    this.socket.onopen = () => {
      if (this.tokenString) {
        this.sendMessage({
          type: WebSocketsCommandType.SignIn,
          token: this.tokenString,
        });

        this.handlePing();
        this.handleHeartbeat();
        this.reconnectCount = 0;

        if (!this.subscribeCommands.length) {
          return;
        }

        const commands: WebSocketsCommand[] = [];

        this.subscribeCommands.forEach(command => commands.push(command));

        this.sendMessage(commands);
      }
    };
    this.socket.onerror = () => {
      logError("Error while connecting to websocket");
    };
    this.socket.onclose = () => {
      // FIXME:
      // we override onClose event on manual close
      // this logic should work with event.code, but it always returns 1006 https://stackoverflow.com/questions/19304157/getting-the-reason-why-websockets-closed-with-close-code-1006
      this.reconnect();
    };

    this.socket.onmessage = event => {
      const { t, d }: { t: WebSocketsEventType; d: unknown } = JSON.parse(event.data as string);

      switch (t) {
        case WebSocketsEventType.TTick: {
          if (this.subscriptions.has("tick")) {
            $tickMessage.set(d as WebSocketsEventTick);
          }
          break;
        }
        case WebSocketsEventType.TMarketWatch: {
          if (this.subscriptions.has("marketWatch")) {
            $marketWatchMessage.set(d as WebSocketsEventMarketWatch);
          }
          break;
        }
        case WebSocketsEventType.AccountsBalances: {
          if (this.subscriptions.has("accountsBalances")) {
            $accountsBalancesMessage.set(d as WebSocketsEventAccountsBalances);
          }
          break;
        }
        case WebSocketsEventType.Accounts: {
          if (this.subscriptions.has("accounts")) {
            $accountsMessage.set(d as WebSocketsEventAccounts);
          }
          break;
        }
        case WebSocketsEventType.Notices: {
          if (this.subscriptions.has("notices")) {
            $noticesMessage.set(d as WebSocketsEventNotices);
          }
          break;
        }
        case WebSocketsEventType.TDeals: {
          if (this.subscriptions.has("deals")) {
            $dealsMessage.set(d as WebSocketsEventDeals);
          }
          break;
        }
        case WebSocketsEventType.TDealsNotices: {
          if (this.subscriptions.has("dealsNotices")) {
            $dealsNoticesMessage.set(d as WebSocketsEventDealsNotices);
          }
          break;
        }
        case WebSocketsEventType.THistoryPrices: {
          if (this.subscriptions.has("historyPrices")) {
            $symbolsHistoryPricesMessage.set(d as WebSocketsEventSymbolsHistoryPrices);
          }
          break;
        }
        case WebSocketsEventType.TSignals: {
          if (this.subscriptions.has("signals")) {
            $symbolsSignalsMessage.set(d as WebSocketsEventSymbolsSignals);
          }
          break;
        }
        case WebSocketsEventType.TSymbolSessions: {
          if (this.subscriptions.has("symbolsSessions")) {
            $symbolsSessionsMessage.set(d as WebSocketsEventSymbolsSessions);
          }
          break;
        }
        case WebSocketsEventType.TTradingInit: {
          if (this.subscriptions.has("tradingInit")) {
            $tradingInitMessage.set(d as WebSocketsEventTradingInit);
          }
          break;
        }
        case WebSocketsEventType.TWidgets: {
          if (this.subscriptions.has("widgets")) {
            $symbolsWidgetsMessage.set(d as WebSocketsEventSymbolsWidgets);
          }
          break;
        }
        case WebSocketsEventType.Heartbeat: {
          this.heartbeatDate = dayjs();
          break;
        }
        case WebSocketsEventType.User: {
          // TODO:
          break;
        }
        case WebSocketsEventType.Subscription: {
          // TODO:
          break;
        }
      }
    };
  }

  private filterCommand(type: WebSocketsCommandType) {
    this.subscribeCommands = this.subscribeCommands.filter(command => command.type !== type);
  }

  // COMMANDS

  private ping() {
    this.sendMessage({ type: WebSocketsCommandType.Ping });
  }

  subscribeUser() {
    this.filterCommand(WebSocketsCommandType.UserSubscribe);
    const command: WebSocketsCommand = { type: WebSocketsCommandType.UserSubscribe };
    this.sendMessage(command);
    this.subscribeCommands.push(command);
    this.subscriptions.add("user");
    this.subscriptions.add("notices");
  }

  unsubscribeUser() {
    this.sendMessage({ type: WebSocketsCommandType.UserUnsubscribe });
    this.filterCommand(WebSocketsCommandType.UserSubscribe);
    this.subscriptions.delete("user");
    this.subscriptions.delete("notices");
    $noticesMessage.set(null);
    $userMessage.set(null);
  }

  subscribeMarketWatch(symbols: string[] = []) {
    this.filterCommand(WebSocketsCommandType.TMarketWatchSubscribe);
    const command: WebSocketsCommand = { type: WebSocketsCommandType.TMarketWatchSubscribe, symbols };
    this.sendMessage(command);
    this.subscribeCommands.push(command);
    this.subscriptions.add("marketWatch");
  }

  unsubscribeMarketWatch() {
    this.sendMessage({ type: WebSocketsCommandType.TMarketWatchUnsubscribe });
    this.filterCommand(WebSocketsCommandType.TMarketWatchSubscribe);
    this.subscriptions.delete("marketWatch");
    $marketWatchMessage.set(null);
  }

  subscribeSymbolsUpdates() {
    this.filterCommand(WebSocketsCommandType.TSymbolsSubscribe);
    const command: WebSocketsCommand = { type: WebSocketsCommandType.TSymbolsSubscribe };
    this.sendMessage(command);
    this.subscribeCommands.push(command);
    this.subscriptions.add("symbolsSessions");
    this.subscriptions.add("signals");
    this.subscriptions.add("widgets");
    this.subscriptions.add("historyPrices");
  }

  unsubscribeSymbolsUpdates() {
    this.sendMessage({ type: WebSocketsCommandType.TSymbolsUnsubscribe });
    this.filterCommand(WebSocketsCommandType.TSymbolsSubscribe);
    this.subscriptions.delete("symbolsSessions");
    this.subscriptions.delete("signals");
    this.subscriptions.delete("widgets");
    this.subscriptions.delete("historyPrices");
    $symbolsSessionsMessage.set(null);
    $symbolsSignalsMessage.set(null);
    $symbolsWidgetsMessage.set(null);
    $symbolsHistoryPricesMessage.set(null);
  }

  subscribeTick(symbols: string[]) {
    this.filterCommand(WebSocketsCommandType.TTickSubscribe);
    const command: WebSocketsCommand = { type: WebSocketsCommandType.TTickSubscribe, symbols };
    this.sendMessage(command);
    this.subscribeCommands.push(command);
    this.subscriptions.add("tick");
  }

  unsubscribeTick() {
    this.sendMessage({ type: WebSocketsCommandType.TTickUnsubscribe });
    this.filterCommand(WebSocketsCommandType.TTickSubscribe);
    this.subscriptions.delete("tick");
    $tickMessage.set(null);
  }

  subscribeTrading(tradingAccounts: string[]) {
    this.filterCommand(WebSocketsCommandType.TTradingSubscribe);
    const command: WebSocketsCommand = { type: WebSocketsCommandType.TTradingSubscribe, tradingAccounts };
    this.sendMessage(command);
    this.subscribeCommands.push(command);
    this.subscriptions.add("tradingInit");
    this.subscriptions.add("deals");
    this.subscriptions.add("dealsNotices");
  }

  unsubscribeTrading() {
    this.sendMessage({ type: WebSocketsCommandType.TTradingUnsubscribe });
    this.filterCommand(WebSocketsCommandType.TTradingSubscribe);
    this.subscriptions.delete("tradingInit");
    this.subscriptions.delete("deals");
    this.subscriptions.delete("dealsNotices");
    $tradingInitMessage.set(null);
    $dealsMessage.set(null);
    $dealsNoticesMessage.set(null);
  }

  subscribeAccounts() {
    this.filterCommand(WebSocketsCommandType.AccountsSubscribe);
    const command: WebSocketsCommand = { type: WebSocketsCommandType.AccountsSubscribe };
    this.sendMessage(command);
    this.subscribeCommands.push(command);
    this.subscriptions.add("accounts");
  }

  unsubscribeAccounts() {
    this.sendMessage({ type: WebSocketsCommandType.AccountsUnsubscribe });
    this.filterCommand(WebSocketsCommandType.AccountsSubscribe);
    this.subscriptions.delete("accounts");
    $accountsMessage.set(null);
  }

  subscribeAccountsBalances() {
    this.filterCommand(WebSocketsCommandType.AccountsBalancesSubscribe);
    const command: WebSocketsCommand = { type: WebSocketsCommandType.AccountsBalancesSubscribe };
    this.sendMessage(command);
    this.subscribeCommands.push(command);
    this.subscriptions.add("accountsBalances");
  }

  unsubscribeAccountsBalances() {
    this.sendMessage({ type: WebSocketsCommandType.AccountsBalancesUnsubscribe });
    this.filterCommand(WebSocketsCommandType.AccountsBalancesSubscribe);
    this.subscriptions.delete("accountsBalances");
    $accountsBalancesMessage.set(null);
  }
}

const socketClient = new WebSocketClient();

const useSocket = () => {
  useEffect(() => {
    socketClient.initialize($wsUrl(), token.getHeaderString());
    return () => {
      socketClient.close();
    };
  }, []);
};

export {
  $accountsBalancesMessage,
  $accountsMessage,
  $dealsMessage,
  $dealsNoticesMessage,
  $marketWatchMessage,
  $noticesMessage,
  $symbolsHistoryPricesMessage,
  $symbolsSessionsMessage,
  $symbolsSignalsMessage,
  $symbolsWidgetsMessage,
  $tickMessage,
  $tradingInitMessage,
  socketClient,
  useSocket,
};
