import type { FC } from "react";
import { useTranslation } from "react-i18next";

import type { PlatformInfo } from "@/services/openapi";
import { Text } from "@/shared/ui";

import { OAuthFacebookButton } from "./facebook-button";
import { OAuthGoogleButton } from "./google-button";

type Props = {
  platformInfo: PlatformInfo;
  className?: string;
};

const OAuthButtonsBlock: FC<Props> = ({ className, platformInfo }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="relative mb-7 flex justify-center after:absolute after:top-1/2 after:h-px after:w-full after:-translate-y-1/2 after:bg-contrast-quaternary">
        <div className="z-10 bg-surface-elevation-1 px-3">
          <Text color="secondary" variant="M / Regular">
            {t("auth.common.or")}
          </Text>
        </div>
      </div>
      <div className="flex items-center justify-center gap-4">
        <OAuthGoogleButton />
        <OAuthFacebookButton platformInfo={platformInfo} />
      </div>
    </div>
  );
};

export { OAuthButtonsBlock };
