import { type FC } from "react";

import { NumberFormat } from "@/app/components";
import { AccountBadge } from "@/entities/accounts/badge";
import { type TradingAccount } from "@/services/openapi";
import { DialogSelect, Text } from "@/shared/ui";

type Props = {
  account: TradingAccount;
};

const AccountDetailsSelectItem: FC<Props> = ({ account }) => {
  const { id, type, login, currency, equity, digits } = account;

  return (
    <DialogSelect.Item value={id!}>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-3">
          <AccountBadge type={type!} />
          <Text variant="S / Regular" color="secondary">
            #{login}
          </Text>
        </div>
        <div className="flex items-center gap-3">
          <Text variant="S / Regular" color="primary" align="end">
            <NumberFormat currency={currency!} value={equity} decimalScale={digits} />
          </Text>
        </div>
      </div>
    </DialogSelect.Item>
  );
};

export { AccountDetailsSelectItem };
