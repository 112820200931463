import { cva } from "class-variance-authority";

const sheetStyles = cva("fixed z-50 ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out", {
  variants: {
    side: {
      start:
        "inset-y-0 start-0 h-full w-[min(300px,_75%)] data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left",
      end: "inset-y-0 end-0 h-full w-[min(300px,_75%)] data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right",
    },
  },
});

const overlayStyles = cva("fixed inset-0 z-50 bg-surface-overlay", {
  variants: {
    hasAnimation: {
      true: "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
    },
  },
  defaultVariants: {
    hasAnimation: true,
  },
});

const mainDialogContentStyles = cva("relative z-50 my-[5dvh]", {
  variants: {
    hasAnimation: {
      true: "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
    },
  },
  defaultVariants: {
    hasAnimation: true,
  },
});

const centeredDialogContentStyles = cva("fixed start-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%]", {
  variants: {
    hasAnimation: {
      true: "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-1/2 data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-1/2",
    },
  },
  defaultVariants: {
    hasAnimation: true,
  },
});

const popoverContentStyles = cva(
  "relative z-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
);

const tooltipContentStyles = cva(
  "relative z-50 animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
);

export {
  overlayStyles,
  mainDialogContentStyles,
  centeredDialogContentStyles,
  popoverContentStyles,
  sheetStyles,
  tooltipContentStyles,
};
