import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { createImagePathUrl } from "@/app/utils/images/helpers";
import type { HomeBlockEducation } from "@/services/openapi";
import { ExternalLink, Text } from "@/shared/ui";

import { HomeCard } from "../ui/card";
import { HomeCarousel } from "../ui/carousel";

type Props = {
  education: HomeBlockEducation[];
};

const EducationContainer: FC<Props> = ({ education }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <Text as="h3" variant="L / Medium" color="primary">
        {t("home.education.title")}
      </Text>
      <HomeCarousel
        items={education}
        renderItem={item => (
          <HomeCarousel.Item key={item.title}>
            <HomeCard
              title={item.title}
              imageUrl={item.imageUrlPathsPreview![0] ? createImagePathUrl(item.imageUrlPathsPreview![0]) : null}
              type="education"
              onClick={() => {
                track(amplitudeEvents.home.education.open, {
                  learn_name: item.analyticName,
                });
              }}
              asChild
            >
              <ExternalLink href={item.url!} />
            </HomeCard>
          </HomeCarousel.Item>
        )}
      />
    </div>
  );
};

export { EducationContainer };
