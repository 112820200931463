import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import type { BonusUserPlatform } from "@/services/openapi";
import { DataList, Text } from "@/shared/ui";

type Props = {
  bonus: BonusUserPlatform;
};

const DepositInfo: FC<Props> = ({ bonus }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 rounded-xl border border-card-border bg-card-bg-accent px-6 py-5">
      <DataList>
        {bonus.amountSet!.map(({ amount, limitMaxOriginal }, i, arr) => {
          const isFirst = i === 0;
          return (
            <DataList.Item
              key={i}
              label={
                <DataList.Label>
                  {isFirst ? (
                    <Trans
                      i18nKey="bonus.dialog.terms.deposit.first"
                      components={{
                        value: <NumberFormat prefix="$" value={limitMaxOriginal} />,
                      }}
                    />
                  ) : (
                    <Trans
                      i18nKey="bonus.dialog.terms.deposit.range"
                      components={{
                        fromValue: <NumberFormat prefix="$" value={arr.at(i - 1)!.limitMaxOriginal} />,
                        toValue: <NumberFormat prefix="$" value={limitMaxOriginal} />,
                      }}
                    />
                  )}
                </DataList.Label>
              }
              value={<DataList.Value>{t("bonus.dialog.terms.deposit.bonus", { value: amount })}</DataList.Value>}
            />
          );
        })}
        <DataList.Item
          label={
            <DataList.Label>
              <Trans
                i18nKey="bonus.dialog.terms.deposit.over"
                components={{
                  value: <NumberFormat prefix="$" value={bonus.amountSet!.at(-1)!.limitMaxOriginal} />,
                }}
              />
            </DataList.Label>
          }
          value={<DataList.Value>{t("bonus.dialog.terms.deposit.no-bonus")}</DataList.Value>}
        />
      </DataList>
      <Text variant="S / Regular" color="secondary">
        <Trans
          i18nKey="bonus.dialog.terms.deposit.caption"
          components={{
            value: <NumberFormat prefix="$" value={bonus.amountMax} />,
          }}
        />
      </Text>
    </div>
  );
};

export { DepositInfo };
