import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconHistory } from "@/domains/icons";
import { EmptyState } from "@/shared/ui";

const TransactionsEmptyState: FC = () => {
  const { t } = useTranslation();

  return <EmptyState icon={<IconHistory />} title={t("transactions.no-items.title")} />;
};

export { TransactionsEmptyState };
