import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { DialogName, useDialog } from "@/hooks/dialog.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { AvailabilityStatus } from "@/services/openapi";
import { Button, Dialog, Text } from "@/shared/ui";

type Props = {
  onCreateNewAccount: () => void;
};

/**
 * @deprecated
 */
const NoAccountsDialog: FC<Props> = ({ onCreateNewAccount }) => {
  const { t } = useTranslation();
  const { open: openMainDialog, onClose: onCloseMainDialog } = useDialog(DialogName.NO_ACCOUNTS);

  return (
    <Dialog open={openMainDialog} onOpenChange={onCloseMainDialog}>
      <Dialog.Content>
        <Dialog.Header title={t("accounts.dialogs.no-accounts.title")} centered />
        <Text variant="S / Regular" color="primary" align="center">
          {t("accounts.dialogs.no-accounts.description")}
        </Text>
        <Dialog.Buttons>
          <Button onClick={onCreateNewAccount}>{t("accounts.dialogs.no-accounts.button-1")}</Button>
          <Dialog.Close asChild>
            <Button variant="secondary" asChild>
              <Link
                to={{
                  pathname: cabinetRoutes.myAccounts,
                  search: createSearchParams({ tab: AvailabilityStatus.Archived }).toString(),
                }}
              >
                {t("accounts.dialogs.no-accounts.button-2")}
              </Link>
            </Button>
          </Dialog.Close>
        </Dialog.Buttons>
      </Dialog.Content>
    </Dialog>
  );
};

export { NoAccountsDialog };
