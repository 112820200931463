import type { FC } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { authRoutes } from "@/routes/auth.routes";

import { EmailConfirmContainer } from "./email-confirm.container";

const EmailConfirmPage: FC = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const code = searchParams.get("code");

  if (!userId || !code) {
    return <Navigate to={authRoutes.signIn} replace />;
  }

  return <EmailConfirmContainer userId={userId} code={code} />;
};

export { EmailConfirmPage };
