import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconChart } from "@/domains/icons";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { TileButton } from "@/shared/ui";

import { AddFundsButton } from "./add-funds";
import { RemoveFundsButton } from "./remove-funds";
import { AccountButtonWrapper } from "./wrapper";

type Props = {
  account: TradingAccount;
};

const AccountDemoButtons: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const { id, platform, actions } = account;

  const { canMakeDemoDeposit } = actions!;

  const buttonsCount = 2 * Number(canMakeDemoDeposit) + Number(platform == TradingServerPlatform.MetaTrader5);
  const isEven = buttonsCount % 2 === 0;

  return (
    <AccountButtonWrapper isEven={isEven}>
      {canMakeDemoDeposit && <AddFundsButton account={account} />}
      {canMakeDemoDeposit && <RemoveFundsButton account={account} />}
      {platform == TradingServerPlatform.MetaTrader5 && (
        <TileButton variant="secondary" icon={<IconChart />} asChild>
          <Link to={getTerminalRoute(id!)}>{t("accounts.buttons.trade")}</Link>
        </TileButton>
      )}
    </AccountButtonWrapper>
  );
};

export { AccountDemoButtons };
