import { type FC } from "react";
import { useTranslation } from "react-i18next";

import mailImg from "@/assets/images/mail.png";
import { Button, Dialog, Image } from "@/shared/ui";

const ForgotPasswordSuccessDialog: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        title={t("auth.forgot-password.dialog.title")}
        centered
        image={
          <Dialog.Image size="lg">
            <Image src={mailImg} />
          </Dialog.Image>
        }
      />
      <Dialog.Description>{t("auth.forgot-password.dialog.description")}</Dialog.Description>
      <Dialog.Buttons>
        <Dialog.Close asChild>
          <Button>{t("auth.forgot-password.dialog.action")}</Button>
        </Dialog.Close>
      </Dialog.Buttons>
    </>
  );
};

export { ForgotPasswordSuccessDialog };
