import type { ComponentProps, FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useDisclosure } from "@/hooks/disclosure.hook";
import { authRoutes } from "@/routes/auth.routes";
import { Dialog } from "@/shared/ui";
import { useForgotPasswordMutation } from "@/state/server/auth";

import { AuthWrapper } from "../../wrapper";
import { ForgotPasswordForm } from "./forgot-password.form";
import { ForgotPasswordSuccessDialog } from "./success-dialog";

const ForgotPasswordContainer: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [opened, { open, close }] = useDisclosure();

  const { mutateAsync: forgotPassword } = useForgotPasswordMutation();

  const submitForgot: ComponentProps<typeof ForgotPasswordForm>["onSubmit"] = useCallback(
    values => {
      return forgotPassword(
        { passwordForgotRequest: values },
        {
          onSuccess: () => {
            open();
          },
        },
      );
    },
    [forgotPassword, open],
  );

  return (
    <>
      <AuthWrapper title={t("auth.forgot-password.title")}>
        <ForgotPasswordForm onSubmit={submitForgot} />
      </AuthWrapper>
      <Dialog
        open={opened}
        onOpenChange={() => {
          close();
          navigate(authRoutes.signIn);
        }}
      >
        <Dialog.Content>
          <ForgotPasswordSuccessDialog />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export { ForgotPasswordContainer };
