import { cva, type VariantProps } from "class-variance-authority";
import { Switch as SwitchPrimitive } from "radix-ui";
import { type ComponentPropsWithoutRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const rootStyles = cva(
  "inline-flex items-center rounded-full bg-contrast-quaternary outline-none transition-colors hover:bg-contrast-tertiary focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-positive-bg-active disabled:bg-contrast-quinary data-[disabled]:pointer-events-none data-[state=checked]:bg-positive-bg data-[state=checked]:hover:bg-positive-bg-hover data-[state=checked]:disabled:bg-contrast-quinary",
  {
    variants: {
      size: {
        sm: "h-5 w-8",
        md: "h-7 w-[44px]",
      },
    },
  },
);

const thumbStyles = cva(
  "block rounded-full bg-accent-over shadow-switch transition-transform data-[disabled]:shadow-none",
  {
    variants: {
      size: {
        sm: "size-4 translate-x-0.5 data-[state=checked]:translate-x-[14px]",
        md: "size-6 translate-x-0.5 data-[state=checked]:translate-x-[18px]",
      },
    },
  },
);

type Props = ComponentPropsWithoutRef<typeof SwitchPrimitive.Root> & VariantProps<typeof rootStyles>;

const Switch = forwardRef<React.ElementRef<typeof SwitchPrimitive.Root>, Props>(
  ({ className, size = "md", ...props }, ref) => (
    <SwitchPrimitive.Root className={cn(rootStyles({ size }), className)} {...props} ref={ref}>
      <SwitchPrimitive.Thumb className={thumbStyles({ size })} />
    </SwitchPrimitive.Root>
  ),
);

export { Switch };
