import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { createImagePathUrl } from "@/app/utils/images/helpers";
import type { LocaleInfoItemsContainer } from "@/services/openapi";
import { DialogSelect, Image, Text } from "@/shared/ui";

type Props = {
  languagesInfo: LocaleInfoItemsContainer;
  onChange: (locale: string) => void;
};

const LanguagesSelect: FC<Props> = ({ languagesInfo, onChange }) => {
  const { i18n } = useTranslation();

  return (
    <DialogSelect value={i18n.resolvedLanguage} onValueChange={onChange}>
      {languagesInfo.items!.map(({ code, iconUrlPath, name }) => (
        <DialogSelect.Item key={code} value={code!}>
          <div className="flex w-full items-center justify-between gap-3">
            <div className="size-6 p-0.5 *:size-full">
              <Image src={createImagePathUrl(iconUrlPath!)} alt={name!} />
            </div>
            <div className="flex grow items-center justify-between gap-3">
              <Text variant="S / Regular" color="primary">
                {name}
              </Text>
            </div>
          </div>
        </DialogSelect.Item>
      ))}
    </DialogSelect>
  );
};

export { LanguagesSelect };
