import { type FC, type ReactNode } from "react";

import { Carousel } from "@/shared/ui";

const Item: FC<{ children: ReactNode }> = ({ children }) => (
  <Carousel.Item className="basis-[256px]">{children}</Carousel.Item>
);

type Props<T> = {
  items: T[];
  renderItem: (item: T) => JSX.Element;
};

const HomeCarousel = <T,>({ items, renderItem }: Props<T>) => {
  return (
    <Carousel opts={{ dragFree: true, align: "start" }}>
      <Carousel.Content>{items.map(renderItem)}</Carousel.Content>
      <Carousel.Previous />
      <Carousel.Next />
    </Carousel>
  );
};

const Component = Object.assign(HomeCarousel, {
  Item,
});

export { Component as HomeCarousel };
