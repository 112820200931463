import { useGoogleLogin } from "@react-oauth/google";
import { useCallback } from "react";
import type { ReactFacebookLoginInfo } from "react-facebook-login";
import { useNavigate } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { useSignInFacebookMutation, useSignInGoogleMutation } from "@/state/server/auth";

const useGoogleSignIn = () => {
  const navigate = useNavigate();

  const { mutate } = useSignInGoogleMutation();

  const signIn = useGoogleLogin({
    onSuccess: ({ code }) => {
      // FIXME:
      const timezoneOffsetMinutes: number = new Date().getTimezoneOffset();
      const timezone: number = -timezoneOffsetMinutes * 60;
      const languageCode = localStorage.getItem("i18nextLng");

      mutate(
        { tokenSignInRequest: { value: code, timezone, languageCode } },
        {
          onSuccess: ({ isFirstLogin }) => {
            if (isFirstLogin) {
              // TODO: dataLayer
              window.dataLayer?.push({
                event: "gtm_events",
                ec: "register",
                ea: "register",
                el: "Google",
                ga4_event_name: "register",
              });
              navigate(onboardingRoutes.onboarding);
              return;
            }
            navigate(cabinetRoutes.home);
          },
        },
      );
    },
    flow: "auth-code",
  });

  return { signIn };
};

const useFacebookSignIn = () => {
  const navigate = useNavigate();

  const { mutate } = useSignInFacebookMutation();

  const callback = useCallback(
    (userInfo: ReactFacebookLoginInfo) => {
      if (userInfo.accessToken) {
        // FIXME:
        const timezoneOffsetMinutes: number = new Date().getTimezoneOffset();
        const timezone: number = -timezoneOffsetMinutes * 60;
        const languageCode = localStorage.getItem("i18nextLng");

        mutate(
          { tokenSignInRequest: { value: userInfo.accessToken, timezone, languageCode } },
          {
            onSuccess: ({ isFirstLogin }) => {
              if (isFirstLogin) {
                // TODO: dataLayer
                window.dataLayer?.push({
                  event: "gtm_events",
                  ec: "register",
                  ea: "register",
                  el: "Facebook",
                  ga4_event_name: "register",
                });
                navigate(onboardingRoutes.onboarding);
                return;
              }
              navigate(cabinetRoutes.home);
            },
          },
        );
      }
    },
    [mutate, navigate],
  );

  return { callback };
};

export { useGoogleSignIn, useFacebookSignIn };
