import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { type _ExternalWrapperProps, _Input, _Wrapper } from "../_base";

type Props = Omit<_ExternalWrapperProps, "hasInputValue"> &
  OverrideProps<
    Omit<ComponentPropsWithoutRef<typeof _Input>, "type">,
    {
      value: string;
      onChange: (value: string) => void;
    }
  >;

const EmailInput = forwardRef<ElementRef<typeof _Input>, Props>(
  (
    {
      value,
      onChange,
      className,
      label,
      startSection,
      disabled,
      invalid,
      pending,
      descriptor,
      floatingLabel = true,
      endSection,
      ...props
    },
    ref,
  ) => {
    return (
      <_Wrapper
        type="input"
        className={className}
        startSection={startSection}
        invalid={invalid}
        pending={pending}
        label={label}
        disabled={disabled}
        descriptor={descriptor}
        floatingLabel={floatingLabel}
        hasInputValue={value !== ""}
        endSection={endSection}
      >
        <_Input
          autoComplete="email"
          inputMode="email"
          type="email"
          disabled={disabled}
          value={value}
          onChange={e => onChange(e.target.value)}
          {...props}
          ref={ref}
        />
      </_Wrapper>
    );
  },
);

export { EmailInput };
