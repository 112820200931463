import { GoogleOAuthProvider as LibProvider } from "@react-oauth/google";
import type { FC, ReactElement } from "react";

import { usePlatformData } from "@/state/server/platform/platform.hooks";

const GoogleOAuthProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const platform = usePlatformData();

  const clientId = platform.signIn!.google!.clientId;

  if (!clientId) {
    return children;
  }

  return <LibProvider clientId={clientId}>{children}</LibProvider>;
};

export { GoogleOAuthProvider };
