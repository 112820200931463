import dayjs from "dayjs";
import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { ScrollRestoration } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { logError } from "@/app/libs/sentry";
import { BonusBanner } from "@/entities/bonuses/banner";
import { getShowBonusBanner } from "@/entities/bonuses/helpers";
import { useClientId } from "@/hooks/client-id.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import type { BonusUserPlatform, TradingAccount } from "@/services/openapi";
import { bonusesQueryKeys } from "@/state/server/bonuses";
import { useDepositMutation } from "@/state/server/payment";
import { useRateQuery } from "@/state/server/rate";

import { useDepositInfo } from "../../deposit-info.hook";
import { AmountStepContainer } from "../../steps/amount/container";
import { CheckStepContainer } from "../../steps/check/container";
import { PaymentMethodStepContainer } from "../../steps/payment-method/container";

type Props = {
  currentAccount: TradingAccount;
  bonuses: BonusUserPlatform[];
  from: string | null;
};

const DepositOptionsForm: FC<Props> = ({ currentAccount, bonuses, from }) => {
  const clientId = useClientId();
  const { t } = useTranslation();

  const [paymentLink, setPaymentLink] = useState("");

  const bonus = bonuses[0] as BonusUserPlatform | null;

  const queryClient = useQueryClient();

  const [uniqueId] = useState(() => uuidv4());

  const hasBonus = !!bonus;

  const { data: rate, isIdle: rateIsIdle } = useRateQuery(
    { from: bonus?.currency!, to: currentAccount.currency! },
    { enabled: !!bonus },
  );

  const {
    currentStep,
    currentPaymentMethod,
    currentAmount,
    isBonusAccepted,
    bonusAmount,
    backFromAmount,
    backFromCheck,
    nextFromAmount,
    setCurrentPaymentMethod,
    nextFromPaymentMethod,
    setCurrentAmount,
    setIsBonusAccepted,
  } = useDepositInfo({ bonus: bonus, rate: rate });
  const { mutateAsync: deposit, isLoading: submitIsloading } = useDepositMutation();

  const onSubmit = () => {
    deposit(
      {
        accountId: currentAccount.id,
        amount: currentAmount,
        deviceType: "desktop",
        isBonusesRejected: hasBonus ? !isBonusAccepted : void 0,
        paymentMethodId: currentPaymentMethod!.id,
        recurringId: currentPaymentMethod!.recurringId!,
        id: uniqueId,
      },
      {
        onSuccess: res => {
          setPaymentLink(res);
          if (hasBonus && isBonusAccepted) {
            queryClient.invalidateQueries(bonusesQueryKeys.base);
          }

          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            client_id: clientId,
            ec: "attempt_purch",
            ea: "no_error",
            el: "no_error",
            ga4_event_name: "attempt_purch",
          });

          window.open(res, "_blank");
        },
        onError: ({ code, errorMessage }) => {
          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            client_id: clientId,
            ec: "attempt_purch",
            ea: "error",
            el: code,
            ga4_event_name: "attempt_purch",
          });

          logError(errorMessage);
        },
      },
    );
  };

  return (
    <>
      <ScrollRestoration key={currentStep} />

      {currentStep === "paymentMethod" && (
        <CabinetPage
          size="sm"
          header={
            <CabinetHeader backButton={from && <CabinetHeader.BackLink to={from} />}>
              <CabinetHeader.Title>{t("deposit.payment-methods.title")}</CabinetHeader.Title>
            </CabinetHeader>
          }
          banner={
            bonus &&
            getShowBonusBanner(bonus, true) && (
              <CabinetPage.Banner>
                <BonusBanner bonus={bonus} showCloseButton={false} bonusImgSize="sm" />
              </CabinetPage.Banner>
            )
          }
        >
          <PaymentMethodStepContainer
            currentPaymentMethod={currentPaymentMethod}
            next={nextFromPaymentMethod}
            setPaymentMethod={setCurrentPaymentMethod}
            currentAccount={currentAccount}
          />
        </CabinetPage>
      )}

      {currentStep === "amount" && (
        <CabinetPage
          size="sm"
          header={
            <CabinetHeader backButton={<CabinetHeader.BackButton onClick={backFromAmount} />}>
              <CabinetHeader.Title>{t("deposit.form.amount.title")}</CabinetHeader.Title>
            </CabinetHeader>
          }
        >
          <AmountStepContainer
            bonus={bonus}
            setIsBonusAccepted={setIsBonusAccepted}
            currentAmount={currentAmount}
            currentAccount={currentAccount}
            currentPaymentMethod={currentPaymentMethod!}
            isBonusAccepted={isBonusAccepted}
            bonusAmount={bonusAmount}
            setCurrentAmount={setCurrentAmount}
            next={nextFromAmount}
          />
        </CabinetPage>
      )}

      {currentStep === "check" && (
        <CabinetPage
          size="sm"
          header={
            <CabinetHeader backButton={<CabinetHeader.BackButton onClick={backFromCheck} />}>
              <CabinetHeader.Title>{t("payments.check.title")}</CabinetHeader.Title>
            </CabinetHeader>
          }
        >
          <CheckStepContainer
            paymentLink={paymentLink}
            submitIsloading={submitIsloading}
            bonus={bonus}
            currentAmount={currentAmount}
            currentAccount={currentAccount}
            isBonusAccepted={isBonusAccepted}
            currentPaymentMethod={currentPaymentMethod!}
            onSubmit={onSubmit}
            rate={rate}
            rateIsIdle={rateIsIdle}
          />
        </CabinetPage>
      )}
    </>
  );
};

export { DepositOptionsForm };
