import { type FC, memo, useMemo, useState } from "react";

import { useSymbolsContext } from "../contexts/symbols.context";
import {
  filterSymbolsSearch,
  getSymbolsFilteringFunction,
  isSymbolAvailable,
  sortWatchlistSymbols,
  StaticSymbolGroup,
} from "../helpers/symbols";
import { useLayoutContext } from "../layout/context";
import { Watchlist } from "./watchlist";

type Props = {
  onClose: () => void;
};

const WatchlistContainer: FC<Props> = ({ onClose }) => {
  const { isMobile } = useLayoutContext();

  const { symbols, symbolGroups, group, changeGroup, favorites } = useSymbolsContext();

  const [search, setSearch] = useState("");

  const hasSearch = !!search;

  const filteringFunction = useMemo(
    () =>
      getSymbolsFilteringFunction({
        popular: favorites.popular!,
        group,
        hasSearch,
      }),
    [group, favorites, hasSearch],
  );

  const visibleSymbols = useMemo(
    () => symbols.filter(filterSymbolsSearch(search)).filter(filteringFunction),
    [symbols, search, filteringFunction],
  );

  const sortedSymbols = useMemo(
    () => sortWatchlistSymbols({ symbols: visibleSymbols, group, favoriteData: favorites, hasSearch }),
    [visibleSymbols, group, favorites, hasSearch],
  );

  const { isGroupAvailable, periodDateEnd } = useMemo(() => {
    let isGroupAvailable: boolean = false;
    let periodDateEnd: string | null = null;

    sortedSymbols.forEach(symbol => {
      if (!isGroupAvailable && isSymbolAvailable(symbol.state!)) {
        isGroupAvailable = true;
      }
      if (symbol.periodDateEnd) {
        periodDateEnd = periodDateEnd === null ? symbol.periodDateEnd : periodDateEnd;
        // Find the earliest date
        periodDateEnd = +periodDateEnd >= +symbol.periodDateEnd ? symbol.periodDateEnd : periodDateEnd;
      }
    });

    return { isGroupAvailable, periodDateEnd };
  }, [sortedSymbols]);

  const showUnavailableAlert = useMemo(() => {
    return (
      !isGroupAvailable && !hasSearch && !(group === StaticSymbolGroup.FAVORITES || group === StaticSymbolGroup.POPULAR)
    );
  }, [group, hasSearch, isGroupAvailable]);

  return (
    <Watchlist
      periodDateEnd={periodDateEnd}
      showUnavailableAlert={showUnavailableAlert}
      hasSearch={hasSearch}
      symbols={sortedSymbols}
      group={group}
      symbolGroups={symbolGroups}
      search={search}
      changeGroup={changeGroup}
      setSearch={setSearch}
      onClose={onClose}
      isMobile={isMobile}
    />
  );
};

const Component = memo(WatchlistContainer);

export { Component as WatchlistContainer };
