import { type FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { useLocationPath } from "@/app/hooks";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import verifyWhiteImg from "@/assets/images/verify-white.png";
import type { MaximumLimitDescription } from "@/services/openapi";
import { Button, Dialog, Image, Text } from "@/shared/ui";

export const fromDepositState = "fromDeposit";

type Props = {
  open: boolean;
  submitDeposit: () => void;
  onClose: (open: boolean) => void;
  depositLimit: number | undefined;
  currency: string;
  decimalScale: number;
  withdrawLimit: MaximumLimitDescription;
  isSurveyMandatory: boolean;
  verificationRoute: string;
};

const VerificationContainer: FC<Props> = ({
  currency,
  decimalScale,
  depositLimit,
  onClose,
  open,
  submitDeposit: submitDepositProps,
  withdrawLimit,
  isSurveyMandatory,
  verificationRoute,
}) => {
  const { t } = useTranslation();
  const path = useLocationPath();

  useEffect(() => {
    if (open) {
      track(amplitudeEvents.deposit.verificationDialogVisit);
    }
  }, [open]);

  const submitDeposit = () => {
    submitDepositProps();
    track(amplitudeEvents.deposit.verificationGoToPayment);
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.Header
          centered
          title={
            !isSurveyMandatory
              ? t("deposit.verification.dialog.mandatory.title")!
              : t("deposit.verification.dialog.title")!
          }
          image={
            <Dialog.Image size="lg">
              <Image src={verifyWhiteImg} />
            </Dialog.Image>
          }
        />
        <Text variant="S / Regular" color="primary" align="center">
          {!isSurveyMandatory ? (
            t("deposit.verification.dialog.mandatory.description")
          ) : (
            <Trans
              i18nKey="deposit.verification.dialog.description"
              components={{
                depositAmount: <NumberFormat value={depositLimit} decimalScale={decimalScale} currency={currency} />,
                withdrawAmount: (
                  <NumberFormat
                    value={withdrawLimit.extendedInfo!.withdrawCurrentLimitNoKyc}
                    decimalScale={decimalScale}
                    currency={currency}
                  />
                ),
              }}
            />
          )}
        </Text>
        <Dialog.Buttons>
          {isSurveyMandatory ? (
            <>
              <Dialog.Close asChild>
                <Button onClick={submitDeposit}>{t("deposit.verification.dialog.button-payment")}</Button>
              </Dialog.Close>

              <Button variant="secondary" asChild>
                <Link to={{ pathname: verificationRoute, search: createSearchParams({ from: path }).toString() }}>
                  {t("deposit.verification.dialog.button-verify")}
                </Link>
              </Button>
            </>
          ) : (
            <Button asChild>
              <Link
                to={{ pathname: verificationRoute, search: createSearchParams({ from: path }).toString() }}
                onClick={() => {
                  track(amplitudeEvents.deposit.verificationGoToVerification);
                }}
              >
                {t("deposit.verification.dialog.button-verify")}
              </Link>
            </Button>
          )}
        </Dialog.Buttons>
      </Dialog.Content>
    </Dialog>
  );
};

export { VerificationContainer };
