import type {
  FcmNotification,
  FcmNotificationAction,
  FcmNotificationButton,
  FcmNotificationContent,
  FcmNotificationLocation,
} from "@/services/openapi";
import { ClientButtonColor, FcmNotificationShowMode, FcmNotificationType } from "@/services/openapi";

export type {
  FcmNotification,
  FcmNotificationContent,
  FcmNotificationButton,
  FcmNotificationAction,
  FcmNotificationType,
  FcmNotificationLocation,
};

enum FirebaseActions {
  MAKE_DEPOSIT = "make-deposit",
  CONTACT_SUPPORT = "contact-support",
  TERMINAL_OPEN = "terminal-open",
  TRANSACTION_DETAILS = "txs-details",
}

export { FcmNotificationShowMode, ClientButtonColor, FirebaseActions };
