import { type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Navigate, useSearchParams } from "react-router-dom";

import { useLocationPath } from "@/app/hooks";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { DepositNoAccounts } from "@/features/payment/deposit/components/no-accounts";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes, getDepositRoute } from "@/routes/cabinet.routes";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { useAllAccountsQuery, useLastAccountQuery } from "@/state/server/accounts";
import { useDepositMethodQuery } from "@/state/server/payment";

import { DepositForm } from "./components/form/deposit.form";

const DepositPage: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");

  const { data: accounts } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    sorting: TradingAccountSorting.Newest,
    status: AvailabilityStatus.Active,
  });
  const { data: allPaymentMethods } = useDepositMethodQuery();

  const { data: lastAccount } = useLastAccountQuery({
    type: TradingAccountType.Real,
    status: AvailabilityStatus.Active,
  });

  const path = useLocationPath();

  useEffect(() => {
    track(amplitudeEvents.deposit.visit, {
      entryPoint: from,
    });
  }, [from]);

  if (!accounts || !allPaymentMethods || !lastAccount) {
    return <CabinetPage.Loader />;
  }

  if (!accounts.length) {
    return (
      <CabinetPage
        size="sm"
        header={
          <CabinetHeader>
            <CabinetHeader.Title>{t("cabinet.navigation.deposit")}</CabinetHeader.Title>
          </CabinetHeader>
        }
      >
        <DepositNoAccounts
          paymentMethods={allPaymentMethods.items!}
          link={{ pathname: cabinetRoutes.createAccount, search: createSearchParams({ from: path }).toString() }}
        />
      </CabinetPage>
    );
  }

  if (accounts.length === 1) {
    return <Navigate to={getDepositRoute(accounts[0]!.id!)} replace />;
  }

  return (
    <CabinetPage
      size="sm"
      header={
        <CabinetHeader>
          <CabinetHeader.Title>{t("deposit.account-select.title")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <DepositForm accounts={accounts} lastAccountId={lastAccount.id!} path={path} />
    </CabinetPage>
  );
};

export { DepositPage };
