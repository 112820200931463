import { cva } from "class-variance-authority";
import { Slot } from "radix-ui";
import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

const descriptorButtonStyles = cva(
  "typography-S-Regular pointer-events-auto underline hover:text-contrast-primary hover:transition-colors group-data-[invalid=true]/input-wrapper:hover:text-negative-bg-hover",
)();

const _Descriptor: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="typography-S-Regular mt-2 text-start text-contrast-secondary group-data-[disabled=true]/input-wrapper:pointer-events-none group-data-[invalid=true]/input-wrapper:text-negative-text">
      {children}
    </div>
  );
};

const _DescriptorButton = forwardRef<
  ElementRef<"button">,
  Omit<ComponentPropsWithoutRef<"button">, "className"> & { asChild?: boolean }
>(({ type = "button", asChild, ...props }, ref) => {
  const Comp = asChild ? Slot.Root : "button";
  return <Comp ref={ref} type={type} className={descriptorButtonStyles} {...props} />;
});

export { _Descriptor, _DescriptorButton };
