import { type FC } from "react";

import { DownloadAndroidButton, DownloadIOSButton } from "@/app/components/download-buttons";
import { cn } from "@/shared/styles";

type Props = {
  link: string;
  className?: string;
};

const AuthDownloadButtons: FC<Props> = ({ link, className }) => {
  return (
    <div className={cn("flex justify-center gap-4", className)}>
      <DownloadAndroidButton link={link} />
      <DownloadIOSButton link={link} />
    </div>
  );
};

export { AuthDownloadButtons };
