import { type FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@/shared/ui";

import { TerminalDialogContent } from "../../components/terminal-dialog-content";
import { InsightsContainer } from "../../insights/container";
import { useLayoutContext } from "../context";

const InsightsDialog: FC = () => {
  const { t } = useTranslation();

  const { isMobile, insightsSection, changeInsightsSection } = useLayoutContext();

  const handleClose = useCallback(() => {
    changeInsightsSection("none");
  }, [changeInsightsSection]);

  return (
    <Dialog open={insightsSection !== "none" && isMobile} onOpenChange={handleClose}>
      <TerminalDialogContent title={t("terminal.insights.title")}>
        <InsightsContainer onClose={handleClose} />
      </TerminalDialogContent>
    </Dialog>
  );
};

export { InsightsDialog };
