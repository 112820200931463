import type { FC } from "react";
import { memo, useMemo } from "react";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { getDecimalScaleFromTickSize } from "@/features/terminal/helpers/formatting";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";
import { useTranslation } from "@/hooks/translator.hook";
import type { TerminalPosition } from "@/services/openapi";
import { DataList, Text } from "@/shared/ui";

import { ClosedPositionVolume } from "../../closed-positions/volume";
import { OrderTypeFormat } from "../../ui/order-type-format";
import { TradingCard } from "../card";

type Props = {
  position: TerminalPosition;
  currency: string;
  currencyDecimalScale: number;
};

const ClosedPositionCard: FC<Props> = ({ position, currency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    stopLoss,
    takeProfit,
    swap,
    profit,
    dateOpen,
    volumeSell,
    volumeBuy,
    priceOpen,
    dateClose,
    priceClose,
    position: positionNumber,
    symbolDigits,
    symbolVolumeStep,
    icon,
  } = position;

  const volumeDecimalScale = useMemo(() => getDecimalScaleFromTickSize(symbolVolumeStep!), [symbolVolumeStep]);

  return (
    <TradingCard
      header={
        <TradingCard.Header
          symbol={symbol!}
          symbolUrlPath={icon!.webUrlPath!}
          type={type!}
          volume={
            <ClosedPositionVolume
              decimalScale={volumeDecimalScale}
              type={type!}
              volumeBuy={volumeBuy}
              volumeSell={volumeSell}
            />
          }
          endBottomSection={terminalFormatDate(dateClose!)}
          endTopSection={
            <Text variant="M / Medium" color={getNumberTextColor(profit!)}>
              <PnlFormat value={profit} decimalScale={currencyDecimalScale} currency={currency} />
            </Text>
          }
        />
      }
      body={
        <DataList>
          <DataList.Item
            label={<DataList.Label>{t("common.type")}</DataList.Label>}
            value={
              <DataList.Value>
                <OrderTypeFormat type={type!} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.volume-lot")}</DataList.Label>}
            value={
              <DataList.Value>
                <ClosedPositionVolume
                  decimalScale={volumeDecimalScale}
                  type={type!}
                  volumeBuy={volumeBuy}
                  volumeSell={volumeSell}
                />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.open-time")}</DataList.Label>}
            value={<DataList.Value>{terminalFormatDate(dateOpen!)}</DataList.Value>}
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.open-price")}</DataList.Label>}
            value={
              <DataList.Value>
                <NumberFormat value={priceOpen} decimalScale={symbolDigits} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.stop-loss")}</DataList.Label>}
            value={
              <DataList.Value>
                {stopLoss ? <NumberFormat value={stopLoss} decimalScale={symbolDigits} /> : "—"}
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.take-profit")}</DataList.Label>}
            value={
              <DataList.Value>
                {takeProfit ? <NumberFormat value={takeProfit} decimalScale={symbolDigits} /> : "—"}
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.close-time")}</DataList.Label>}
            value={<DataList.Value>{terminalFormatDate(dateClose!)}</DataList.Value>}
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.close-price")}</DataList.Label>}
            value={
              <DataList.Value>
                <NumberFormat value={priceClose} decimalScale={symbolDigits} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.order")}</DataList.Label>}
            value={<DataList.Value>{positionNumber}</DataList.Value>}
          />
          <DataList.Item
            label={
              <DataList.Label>
                {t("terminal.swap")}, {currency}
              </DataList.Label>
            }
            value={
              <DataList.Value>
                <NumberFormat value={swap} decimalScale={currencyDecimalScale} currency={currency} hideCurrency />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("terminal.pnl-currency", { currency })}</DataList.Label>}
            value={
              <DataList.Value color={getNumberTextColor(profit!)}>
                <PnlFormat value={profit} decimalScale={currencyDecimalScale} currency={currency} hideCurrency />
              </DataList.Value>
            }
          />
        </DataList>
      }
    />
  );
};

const Component = memo(ClosedPositionCard);

export { Component as ClosedPositionCard };
