import { getNotifications } from "@/services/notifications";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseInfiniteQuery } from "..";
import type { InfiniteQueryOptionsType, RequestInfiniteParams } from "../types";
import { notificationsQueryKeys } from "./notifications.keys";

export const useInfiniteNotificationsQuery = (
  filters: RequestInfiniteParams<ApiRequestParamsType<typeof getNotifications>>,
  options?: InfiniteQueryOptionsType<ApiResponseType<typeof getNotifications>>,
) => {
  const { pageSize, ...restFilters } = filters;
  return useBaseInfiniteQuery<ApiResponseType<typeof getNotifications>, ApiRequestParamsType<typeof getNotifications>>(
    notificationsQueryKeys.notificationsInfinite(restFilters),
    getNotifications,
    filters,
    options,
  );
};
