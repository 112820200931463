import React from "react";

import { GlobalLoader } from "@/components/loader";
import { $apiUrl, $filesUrl, $wsUrl, fallbackApiUrls } from "@/services/api";
import { usePlatformApiUrlQuery, usePlatformInfoQuery } from "@/state/server/platform";

export const InitialDataProvider = ({ children }: { children: React.ReactElement }) => {
  const { data } = usePlatformApiUrlQuery({
    onSuccess: ({ apiUrl, wsUrl, filesUrl }) => {
      $apiUrl.set(apiUrl!);
      $wsUrl.set(wsUrl!);
      $filesUrl.set(filesUrl!);
    },
    retryDelay: 0,
    retry: failureCount => {
      if (fallbackApiUrls[failureCount]) {
        $apiUrl.set(fallbackApiUrls[failureCount]!);
        return true;
      }
      return false;
    },
  });
  const { data: platformData } = usePlatformInfoQuery({
    enabled: !!data,
  });

  if (!platformData) {
    return <GlobalLoader />;
  }

  return children;
};
