import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import type { PaymentMethod } from "@/services/openapi";

import { PaymentEmptyState } from "../../ui/empty-state";
import { PaymentNoAccounts } from "../../ui/no-accounts";

type Props = {
  paymentMethods: PaymentMethod[];
  currency: string;
  minAmount: number;
  balance: number;
  decimalScale: number;
};

const WithdrawalLowBalance: FC<Props> = ({ paymentMethods, minAmount, balance, currency, decimalScale }) => {
  const { t } = useTranslation();

  return (
    <PaymentNoAccounts paymentMethods={paymentMethods}>
      <PaymentEmptyState
        title={t("withdrawal.low-balance.title")}
        description={
          <Trans
            i18nKey={"withdrawal.low-balance.text-2"}
            components={{
              value: <NumberFormat value={balance} decimalScale={decimalScale} currency={currency} />,
              minValue: <NumberFormat value={minAmount} decimalScale={decimalScale} currency={currency} />,
            }}
          />
        }
      >
        <PaymentEmptyState.Button asChild>
          <Link to={cabinetRoutes.myAccounts}>{t("withdrawal.low-balance.button")}</Link>
        </PaymentEmptyState.Button>
      </PaymentEmptyState>
    </PaymentNoAccounts>
  );
};

export { WithdrawalLowBalance };
