import { type FC, type ReactNode, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { amplitudeSetUserId } from "@/app/libs/amplitude";
import { NoAccountsDialog } from "@/components/no-accounts-dialog";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useProfileData } from "@/state/server/profile/profile.hooks";
import { Header } from "@/widgets/header";
import { SidebarButton } from "@/widgets/sidebar/button";

import { CabinetAccountDetails } from "./account-details";
import { ActiveAccountContextProvider } from "./active-account.context";
import { CabinetFooter } from "./footer";

type WrapperProps = {
  children: ReactNode;
  headerSlot: ReactNode;
  sidebarSlot: ReactNode;
  isDesktop: boolean;
};

const Wrapper: FC<WrapperProps> = ({ children, headerSlot, sidebarSlot, isDesktop }) => {
  return (
    <div className="flex h-full min-h-0 flex-col bg-surface-under-canvas lg:gap-px">
      {isDesktop ? headerSlot : <div className="bg-surface-canvas px-2 py-2">{sidebarSlot}</div>}
      <div className="flex min-h-0 grow flex-col bg-surface-canvas">
        <div className="grid grow grid-rows-[1fr_auto] overflow-y-auto" style={{ scrollbarGutter: "stable" }}>
          {children}
          <CabinetFooter />
        </div>
      </div>
    </div>
  );
};

const CabinetLayout: FC = () => {
  const { id: userId, homeInfo, permissions } = useProfileData();
  const { isDesktop } = useScreenSize();
  const navigate = useNavigate();

  // TODO: FIXME: remove logic from layout
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: userId,
    });

    if (userId) {
      amplitudeSetUserId(userId);
    }
  }, [userId]);

  return (
    <ActiveAccountContextProvider>
      <Wrapper
        isDesktop={isDesktop}
        headerSlot={
          <Header isReadOnly={!permissions!.isEnabled} isHomeAvailable={homeInfo!.isHomeAvailable!}>
            <CabinetAccountDetails />
          </Header>
        }
        sidebarSlot={
          <SidebarButton isReadOnly={!permissions!.isEnabled} isHomeAvailable={homeInfo!.isHomeAvailable!} />
        }
      >
        <Outlet />
      </Wrapper>
      <NoAccountsDialog onCreateNewAccount={() => navigate(cabinetRoutes.myAccounts)} />
    </ActiveAccountContextProvider>
  );
};

const Component = Object.assign(CabinetLayout, {
  Wrapper,
});

export { Component as CabinetLayout };
