import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import warningImg from "@/assets/images/warning.png";
import { Button, Checkbox, Dialog, Image, Text } from "@/shared/ui";

type Props = {
  hideStopOutDialog: boolean;
  showStopOutDialog: boolean;
  setHideStopOutDialog: (value: boolean) => void;
  closeStopOutDialog: () => void;
  onSubmit: () => void;
};

const StopOutDialog: FC<Props> = ({
  setHideStopOutDialog,
  onSubmit,
  showStopOutDialog,
  hideStopOutDialog,
  closeStopOutDialog,
}) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  return (
    <Dialog open={!hideStopOutDialog && showStopOutDialog} onOpenChange={closeStopOutDialog}>
      <Dialog.Content>
        <Dialog.Header
          centered
          title={t("terminal.stop-out.dialog.title")}
          image={
            <Dialog.Image size="md">
              <Image src={warningImg} />
            </Dialog.Image>
          }
        />
        <Text className="mb-4" color="secondary" variant="S / Regular">
          {t("terminal.stop-out.dialog.description")}
        </Text>
        <Checkbox
          label={t("terminal.stop-out.dialog.show-again-message")}
          checked={checked}
          onCheckedChange={setChecked}
        />
        <Dialog.Buttons>
          <Dialog.Close asChild>
            <Button
              onClick={() => {
                track(amplitudeEvents.terminal.stopOutRiskAction, {
                  pressed_switch_do_not_show_again: checked ? "yes" : "no",
                });
                setHideStopOutDialog(checked);
                onSubmit();
              }}
            >
              {t("terminal.stop-out.dialog.action")}
            </Button>
          </Dialog.Close>
        </Dialog.Buttons>
      </Dialog.Content>
    </Dialog>
  );
};

export { StopOutDialog };
