import { cva } from "class-variance-authority";
import { type FC, type ReactNode, useMemo, useRef, useState } from "react";

import { IconChevronLeft, IconChevronRight } from "@/domains/icons";
import { cn } from "@/shared/styles";
import { UnstyledButton } from "@/shared/ui";

const buttonStyles = cva(
  "invisible absolute bottom-0 top-0 grid w-9 place-items-center bg-surface-canvas text-contrast-secondary opacity-0 transition-all *:size-4 after:absolute after:h-full after:w-2 after:from-gradient-canvas-start after:to-gradient-canvas-end hover:text-contrast-primary",
  {
    variants: {
      type: {
        start: "start-10 after:-end-2 after:bg-gradient-to-l",
        end: "-end-3 after:-start-2 after:bg-gradient-to-r",
      },
      show: {
        true: "visible opacity-100",
      },
    },
  },
);

type Props = {
  children: ReactNode;
};

const ChartSymbolsScrollContainer: FC<Props> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isHovered, setIsHovered] = useState(false);

  const [scrollInfo, setScrollInfo] = useState<{ scrollWidth: number; scrollLeft: number; clientWidth: number } | null>(
    null,
  );

  const hasScroll = useMemo(() => {
    if (!scrollInfo) {
      return false;
    }
    return scrollInfo.scrollWidth > scrollInfo.clientWidth;
  }, [scrollInfo]);

  const shouldShowStartButton = useMemo(() => {
    if (!scrollInfo) {
      return false;
    }
    return isHovered && hasScroll && scrollInfo.scrollLeft > 0;
  }, [isHovered, hasScroll, scrollInfo]);

  const shouldShowEndButton = useMemo(() => {
    if (!scrollInfo) {
      return false;
    }

    return isHovered && hasScroll && scrollInfo.scrollWidth - scrollInfo.scrollLeft !== scrollInfo.clientWidth;
  }, [isHovered, hasScroll, scrollInfo]);

  return (
    <div
      className="flex gap-2 overflow-auto scrollbar-hide"
      ref={ref}
      onMouseEnter={() => {
        setIsHovered(true);
        if (ref.current) {
          setScrollInfo({
            scrollWidth: ref.current.scrollWidth,
            scrollLeft: ref.current.scrollLeft,
            clientWidth: ref.current.clientWidth,
          });
        }
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        setScrollInfo(null);
      }}
      onScroll={() => {
        if (ref.current) {
          setScrollInfo({
            scrollWidth: ref.current.scrollWidth,
            scrollLeft: ref.current.scrollLeft,
            clientWidth: ref.current.clientWidth,
          });
        }
      }}
    >
      <UnstyledButton
        onClick={() => ref.current?.scrollTo({ left: 0, behavior: "smooth" })}
        className={useMemo(
          () => cn(buttonStyles({ type: "start", show: shouldShowStartButton })),
          [shouldShowStartButton],
        )}
      >
        <IconChevronLeft />
      </UnstyledButton>
      <UnstyledButton
        onClick={() => ref.current?.scrollTo({ left: ref.current.scrollWidth, behavior: "smooth" })}
        className={useMemo(() => cn(buttonStyles({ type: "end", show: shouldShowEndButton })), [shouldShowEndButton])}
      >
        <IconChevronRight />
      </UnstyledButton>
      {children}
    </div>
  );
};

export { ChartSymbolsScrollContainer };
