import { cva } from "class-variance-authority";
import type { FC } from "react";

import verifyWhiteImg from "@/assets/images/verify-white.png";
import { IconMail, IconTimeRound } from "@/domains/icons";
import { useTranslation } from "@/hooks/translator.hook";
import { Dialog, Image, Text } from "@/shared/ui";

const listItemStyles = cva("flex gap-3 text-contrast-secondary [&_svg]:size-6")();

const VerificationDialogContent: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog.Header
        centered
        title={t("verification.dialog-description")}
        image={
          <Dialog.Image size="lg">
            <Image src={verifyWhiteImg} />
          </Dialog.Image>
        }
      />
      <div className="flex flex-col gap-4 rounded-lg bg-card-bg-accent p-4">
        <div className={listItemStyles}>
          <IconTimeRound />
          <Text variant="S / Regular" color="primary" className="py-0.5">
            {t("verification.dialog-text-1")}
          </Text>
        </div>
        <div className="h-px bg-contrast-quinary" />
        <div className={listItemStyles}>
          <IconMail />
          <Text variant="S / Regular" color="primary" className="py-0.5">
            {t("verification.dialog-text-2")}
          </Text>
        </div>
      </div>
    </>
  );
};

export { VerificationDialogContent };
