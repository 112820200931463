import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import withdrawPendingImg from "@/assets/images/withdraw-pending.png";
import { Button, Dialog, Image, Text } from "@/shared/ui";

type Props = {
  value: string | null;
  currency: string;
};

const WithdrawalSuccessDialogContent: FC<Props> = ({ currency, value }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        centered
        title={t("withdrawal.status-dialog.success-title")}
        image={
          <Dialog.Image size="md">
            <Image src={withdrawPendingImg} />
          </Dialog.Image>
        }
      />
      <Text variant="S / Regular" color="primary" align="center">
        {value ? (
          <Trans
            i18nKey="withdrawal.status-dialog.success-description"
            values={{
              value,
              symbol: currency,
            }}
          />
        ) : (
          t("withdrawal.status-dialog.success-description-2")
        )}
      </Text>
      <Dialog.Buttons>
        <Dialog.Close asChild>
          <Button>{t("button.got-it")}</Button>
        </Dialog.Close>
      </Dialog.Buttons>
    </>
  );
};

export { WithdrawalSuccessDialogContent };
