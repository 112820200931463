import { type FC } from "react";

import { IconMenu } from "@/domains/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { IconButton, Sheet, Skeleton as UISkeleton } from "@/shared/ui";

import { Sidebar } from ".";

const Skeleton = () => <UISkeleton className="size-10 rounded-full" />;

type Props = {
  isReadOnly: boolean;
  isHomeAvailable: boolean;
};

const SidebarButton: FC<Props> = ({ isReadOnly, isHomeAvailable }) => {
  const [opened, { close, onOpenChange }] = useDisclosure();

  return (
    <Sheet open={opened} onOpenChange={onOpenChange}>
      <Sheet.Trigger asChild>
        <IconButton size="sm" variant="flat">
          <IconMenu />
        </IconButton>
      </Sheet.Trigger>
      <Sheet.Content>
        <Sidebar onClose={close} isReadOnly={isReadOnly} isHomeAvailable={isHomeAvailable} />
      </Sheet.Content>
    </Sheet>
  );
};

const Component = Object.assign(SidebarButton, {
  Skeleton,
});

export { Component as SidebarButton };
