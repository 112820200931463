import { useInfiniteScrollQuery } from "@/app/hooks";
import { useSearch } from "@/hooks/search.hook";
import type { Notification, NotificationItemsContainer } from "@/services/openapi";
import { SearchInput, Spinner } from "@/shared/ui";
import { useInfiniteNotificationsQuery } from "@/state/server/notifications";

import { NotificationItem } from "./notification-item";

const NotificationsContainer = () => {
  const { mask, search, setSearch } = useSearch("");

  const { fetchNextPage, hasNextPage, data, isFetchingNextPage, isLoading } = useInfiniteNotificationsQuery({
    pageSize: 20,
    mask,
  });

  const { ref, items } = useInfiniteScrollQuery<Notification, NotificationItemsContainer>({
    fetchNextPage,
    isFetchingNextPage,
    data,
  });

  return (
    <div className="flex flex-col gap-10">
      <SearchInput value={search} onChange={setSearch} />
      {isLoading ? (
        <div className="flex justify-center *:size-6">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-6 px-1">
          {items.map(notification => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
        </div>
      )}
      {hasNextPage && (
        <div ref={ref} className="flex justify-center *:size-6">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export { NotificationsContainer };
