import { type FC } from "react";
import { Outlet } from "react-router-dom";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { useProfileData } from "@/state/server/profile/profile.hooks";
import { Header } from "@/widgets/header";
import { SidebarButton } from "@/widgets/sidebar/button";

const MetatraderLayout: FC = () => {
  const { isDesktop } = useScreenSize();

  const { homeInfo, permissions } = useProfileData();

  return (
    <>
      <div className="flex h-full flex-col bg-surface-under-canvas lg:gap-px">
        {isDesktop ? (
          <Header
            hideTrading
            blankLinks
            isReadOnly={!permissions!.isEnabled}
            isHomeAvailable={homeInfo!.isHomeAvailable!}
          />
        ) : (
          <div className="bg-surface-canvas px-2 py-2">
            <SidebarButton isReadOnly={!permissions!.isEnabled} isHomeAvailable={homeInfo!.isHomeAvailable!} />
          </div>
        )}
        <div className="grow bg-surface-canvas">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export { MetatraderLayout };
