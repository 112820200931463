import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import unionKeychainImage from "@/assets/images/union-keychain.png";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { Button, Dialog, Image, Text } from "@/shared/ui";

type Props = {
  accountId: string;
  onDetailsSelect: () => void;
  onClose: () => void;
};

const AccountMT5Intro: FC<Props> = ({ accountId, onDetailsSelect, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        centered
        title={t("accounts.mt-details.mt5-intro.title")}
        image={
          <Dialog.Image size="lg">
            <Image src={unionKeychainImage} />
          </Dialog.Image>
        }
      />
      <Text variant="S / Regular" color="primary" align="center">
        {t("accounts.mt-details.mt5-intro.description")}
      </Text>
      <Dialog.Buttons direction="column">
        <Button onClick={onClose} asChild>
          <Link to={getTerminalRoute(accountId)}>{t("accounts.buttons.trade")}</Link>
        </Button>
        <Button variant="secondary" onClick={onDetailsSelect}>
          {t("accounts.common.mt-login-details", { version: "5" })}
        </Button>
      </Dialog.Buttons>
    </>
  );
};

export { AccountMT5Intro };
