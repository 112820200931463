import type { IPositionLineAdapter } from "public/charting_library/charting_library";
import type { FC } from "react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { numericFormatter } from "react-number-format";

import { useDisclosure } from "@/hooks/disclosure.hook";

import type { OpenOrder } from "../../orders/extended-orders.context";
import { getOrderTypeText, getTypeColor } from "../../orders/helpers";
import type { ChartColors } from "../config/colors";
import { chartLineFont } from "../config/fonts";
import { setLineLength } from "../config/lines";
import { ChartCloseOpenOrderDialog } from "./close-open-order-dialog";

const formatPnl = ({
  value,
  currency,
  decimalScale,
  lang,
}: {
  value: number;
  currency: string;
  decimalScale: number;
  lang: string;
}) => {
  const prefix = value > 0 ? "+" : "";
  const thousandSeparator = ",";

  const currencyBefore = lang === "pt" || lang === "ms";

  if (currency === "IDR") {
    const shouldTruncateValue = Math.abs(value) > 1000;
    const reductionSymbol = shouldTruncateValue ? "k" : "";

    return numericFormatter(String(shouldTruncateValue ? Math.trunc(value / 1000) : value), {
      prefix: currencyBefore ? `${currency} ${prefix ? prefix : ""}` : prefix,
      suffix: !currencyBefore ? `${reductionSymbol} ${currency}` : reductionSymbol,
      thousandSeparator,
      decimalScale: 0,
    });
  }

  return numericFormatter(String(value), {
    prefix: currencyBefore ? `${currency} ${prefix ? prefix : ""}` : prefix,
    suffix: !currencyBefore ? ` ${currency}` : void 0,
    fixedDecimalScale: true,
    thousandSeparator,
    decimalScale,
  });
};

type Props = {
  order: OpenOrder;
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  chartColors: ChartColors;
};

const ChartOpenOrder: FC<Props> = ({ order, accountId, accountCurrency, chartColors, currencyDecimalScale }) => {
  const { t, i18n } = useTranslation();

  const ref = useRef<IPositionLineAdapter | null>(null);

  const [dialogOpen, { open, close }] = useDisclosure();

  useEffect(() => {
    window.tvWidget.activeChart().dataReady(() => {
      // https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartWidgetApi#createpositionline
      try {
        ref.current = window.tvWidget
          .activeChart()
          .createPositionLine()
          .setLineStyle(5)
          .setBodyFont(chartLineFont)
          .setQuantityFont(chartLineFont)
          // updatable params
          .setLineColor(
            getTypeColor({ type: order.type }) === "positive"
              ? chartColors.positivePressed
              : chartColors.negativePressed,
          )
          .setQuantityTextColor(chartColors.orderLineText)
          .setBodyBackgroundColor(chartColors.orderLineBackground)
          .setBodyBorderColor(chartColors.orderLineBackground)
          .setCloseButtonBackgroundColor(chartColors.orderLineBackground)
          .setCloseButtonBorderColor(chartColors.orderLineBackground)
          .setCloseButtonIconColor(chartColors.iconClose)
          .setQuantityBackgroundColor(chartColors.orderLineBackground)
          .setQuantityBorderColor(chartColors.orderLineBackground)
          .setQuantity(`${getOrderTypeText({ t, type: order.type })}: ${String(order.volume)}`)
          .setBodyTextColor(order.pnl >= 0 ? chartColors.positive : chartColors.negative)
          .setPrice(order.price)
          .setText(
            formatPnl({
              currency: accountCurrency,
              value: order.pnl,
              decimalScale: currencyDecimalScale,
              lang: i18n.resolvedLanguage,
            }),
          )
          .onClose(() => {
            open();
          });
        setLineLength(ref.current);
      } catch (e) {
        console.log(e, "error chart open order");
      }
    });

    return () => {
      ref.current && ref.current.remove();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      window.tvWidget.activeChart().dataReady(() => {
        ref
          .current!.setLineColor(
            getTypeColor({ type: order.type }) === "positive"
              ? chartColors.positivePressed
              : chartColors.negativePressed,
          )
          .setQuantityTextColor(chartColors.orderLineText)
          .setBodyBackgroundColor(chartColors.orderLineBackground)
          .setBodyBorderColor(chartColors.orderLineBackground)
          .setCloseButtonBackgroundColor(chartColors.orderLineBackground)
          .setCloseButtonBorderColor(chartColors.orderLineBackground)
          .setCloseButtonIconColor(chartColors.iconClose)
          .setQuantityBackgroundColor(chartColors.orderLineBackground)
          .setQuantityBorderColor(chartColors.orderLineBackground)
          .setBodyTextColor(order.pnl >= 0 ? chartColors.positive : chartColors.negative);
      });
    }
  }, [chartColors]);

  useEffect(() => {
    if (ref.current) {
      window.tvWidget.activeChart().dataReady(() => {
        ref
          .current!.setQuantity(`${getOrderTypeText({ t, type: order.type })}: ${String(order.volume)}`)
          .setLineColor(
            getTypeColor({ type: order.type }) === "positive"
              ? chartColors.positivePressed
              : chartColors.negativePressed,
          )
          .setBodyTextColor(order.pnl >= 0 ? chartColors.positive : chartColors.negative)
          .setPrice(order.price)
          .setText(
            formatPnl({
              currency: accountCurrency,
              value: order.pnl,
              decimalScale: currencyDecimalScale,
              lang: i18n.resolvedLanguage,
            }),
          )
          .onClose(() => {
            open();
          });
      });
    }
  }, [order]);

  return (
    <ChartCloseOpenOrderDialog
      accountId={accountId}
      dialogOpen={dialogOpen}
      onClose={close}
      order={order}
      accountCurrency={accountCurrency}
      currencyDecimalScale={currencyDecimalScale}
    />
  );
};

ChartOpenOrder.displayName = "ChartOpenOrder";

export { ChartOpenOrder };
