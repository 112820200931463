import dayjs from "dayjs";
import { type FC } from "react";
import { Link } from "react-router-dom";

import { type BackendButton, backendButtonStyles } from "@/app/components/backend-button";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { openLiveChat } from "@/app/libs/intercom";
import { createImagePathUrl } from "@/app/utils/images/helpers";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import { TerminalTableState } from "@/features/terminal/layout/context";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { getTerminalRoute } from "@/routes/terminal.routes";
import type { HomeBlockNewsItem } from "@/services/openapi";
import { Button, Dialog, ExternalLink, Image, Text } from "@/shared/ui";
import { useProfileData } from "@/state/server/profile/profile.hooks";

type Props = {
  newsItem: HomeBlockNewsItem;
};

const HomeNewsDialogContent: FC<Props> = ({ newsItem }) => {
  const { date, imageUrlPathsContent, title, descriptionContent, buttons } = newsItem;

  const { options } = useProfileData();

  return (
    <>
      <Dialog.Header title={title} />
      <div className="flex flex-col gap-4">
        {!!date && (
          <Text color="secondary" variant="S / Regular">
            {dayjs(date).format("MMMM DD, YYYY, HH:mm")}
          </Text>
        )}
        {imageUrlPathsContent![0] && (
          <Image
            src={createImagePathUrl(imageUrlPathsContent![0])}
            className="aspect-[3/1] w-full rounded-xl object-cover"
          />
        )}
        {!!descriptionContent && (
          <Text color="primary" variant="S / Regular" className="whitespace-pre-line">
            {descriptionContent}
          </Text>
        )}
      </div>
      {buttons && buttons.length > 0 && (
        <Dialog.Buttons direction="column">
          {buttons.map((b, i) => {
            const button = b as BackendButton;

            const style = backendButtonStyles[button.color];

            switch (button.action) {
              case "url":
                return (
                  <Button
                    key={i}
                    onClick={() => {
                      track(amplitudeEvents.home.news.click, {
                        switch_name: button.action,
                        news_name: title,
                      });
                    }}
                    color={style.color}
                    variant={style.variant}
                    asChild
                  >
                    <ExternalLink href={button.url}>{button.text}</ExternalLink>
                  </Button>
                );
              case "make-deposit":
                return (
                  <Dialog.Close key={i} asChild>
                    <Button
                      onClick={() => {
                        track(amplitudeEvents.home.news.click, {
                          switch_name: button.action,
                          news_name: title,
                        });
                      }}
                      color={style.color}
                      variant={style.variant}
                      asChild
                    >
                      <Link to={cabinetRoutes.deposit}>{button.text}</Link>
                    </Button>
                  </Dialog.Close>
                );
              case "contact-support":
                return (
                  <Button
                    key={i}
                    onClick={() => {
                      track(amplitudeEvents.home.news.click, {
                        switch_name: button.action,
                        news_name: title,
                      });
                      openLiveChat();
                    }}
                    color={style.color}
                    variant={style.variant}
                  >
                    {button.text}
                  </Button>
                );
              case "terminal":
                return (
                  <Dialog.Close key={i} asChild>
                    <Button
                      onClick={() => {
                        track(amplitudeEvents.home.news.click, {
                          switch_name: button.action,
                          news_name: title,
                        });
                      }}
                      color={style.color}
                      variant={style.variant}
                      asChild
                    >
                      <Link
                        to={getTerminalRoute(button.id ? button.id : null, {
                          symbol: button.symbol ? button.symbol : void 0,
                        })}
                      >
                        {button.text}
                      </Link>
                    </Button>
                  </Dialog.Close>
                );
              case "terminal-open":
                return (
                  <Dialog.Close key={i} asChild>
                    <Button
                      onClick={() => {
                        track(amplitudeEvents.home.news.click, {
                          switch_name: button.action,
                          news_name: title,
                        });
                      }}
                      color={style.color}
                      variant={style.variant}
                      asChild
                    >
                      <Link
                        to={getTerminalRoute(button.id ? button.id : null, {
                          symbol: button.symbol ? button.symbol : void 0,
                          tab: TerminalTableState.OPEN,
                        })}
                      >
                        {button.text}
                      </Link>
                    </Button>
                  </Dialog.Close>
                );
              case "terminal-closed":
                return (
                  <Dialog.Close key={i} asChild>
                    <Button
                      onClick={() => {
                        track(amplitudeEvents.home.news.click, {
                          switch_name: button.action,
                          news_name: title,
                        });
                      }}
                      color={style.color}
                      variant={style.variant}
                      asChild
                    >
                      <Link
                        to={getTerminalRoute(button.id ? button.id : null, {
                          symbol: button.symbol ? button.symbol : void 0,
                          tab: TerminalTableState.CLOSED_POSITIONS,
                        })}
                      >
                        {button.text}
                      </Link>
                    </Button>
                  </Dialog.Close>
                );
              case "profile":
                return (
                  <Dialog.Close key={i} asChild>
                    <Button
                      onClick={() => {
                        track(amplitudeEvents.home.news.click, {
                          switch_name: button.action,
                          news_name: title,
                        });
                      }}
                      color={style.color}
                      variant={style.variant}
                      asChild
                    >
                      <Link to={cabinetRoutes.profileSettings}>{button.text}</Link>
                    </Button>
                  </Dialog.Close>
                );
              case "onboarding-sumsub":
                return (
                  <Dialog.Close key={i} asChild>
                    <Button
                      onClick={() => {
                        track(amplitudeEvents.home.news.click, {
                          switch_name: button.action,
                          news_name: title,
                        });
                      }}
                      color={style.color}
                      variant={style.variant}
                      asChild
                    >
                      <Link to={getVerificationRoute(options!.isSurveyCompleted!)}>{button.text}</Link>
                    </Button>
                  </Dialog.Close>
                );
              case "transactions":
                return (
                  <Dialog.Close key={i} asChild>
                    <Button
                      onClick={() => {
                        track(amplitudeEvents.home.news.click, {
                          switch_name: button.action,
                          news_name: title,
                        });
                      }}
                      color={style.color}
                      variant={style.variant}
                      asChild
                    >
                      <Link to={cabinetRoutes.transactionHistory}>{button.text}</Link>
                    </Button>
                  </Dialog.Close>
                );
              case "my-accounts":
                return (
                  <Dialog.Close key={i} asChild>
                    <Button
                      onClick={() => {
                        track(amplitudeEvents.home.news.click, {
                          switch_name: button.action,
                          news_name: title,
                        });
                      }}
                      color={style.color}
                      variant={style.variant}
                      asChild
                    >
                      <Link to={cabinetRoutes.myAccounts}>{button.text}</Link>
                    </Button>
                  </Dialog.Close>
                );
              default:
                return null;
            }
          })}
        </Dialog.Buttons>
      )}
    </>
  );
};

export { HomeNewsDialogContent };
