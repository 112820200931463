import type { FC } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { createImagePathUrl } from "@/app/utils/images/helpers";
import { TradingAccountType, type TradingServer, TradingServerPlatform } from "@/services/openapi";
import { Image, Label, Select, ToggleGroup } from "@/shared/ui";

enum Fields {
  TYPE = "type",
  PLATFORM = "platform",
  CURRENCY = "currency",
}

type FormValues = {
  [Fields.TYPE]: TradingAccountType;
  [Fields.PLATFORM]: TradingServerPlatform;
  [Fields.CURRENCY]: string;
};

type Props = {
  type: TradingAccountType;
  servers: TradingServer[];
  onSubmit: (values: FormValues) => void;
};

const CreateAccountForm: FC<Props> = ({ onSubmit, type, servers }) => {
  const { t } = useTranslation();

  const [defaultCurrency] = useState(() => {
    return servers
      .find(({ platformType }) => platformType === TradingServerPlatform.MetaTrader5)
      ?.accounts?.find(({ type }) => type === type)?.defaultCurrency;
  });

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.TYPE]: type,
      [Fields.PLATFORM]: TradingServerPlatform.MetaTrader5,
      [Fields.CURRENCY]: defaultCurrency!,
    },
  });

  const { control } = form;

  const [typeField] = useControlledField({ name: Fields.TYPE, control });
  const [platformField] = useControlledField({ name: Fields.PLATFORM, control });
  const [platformCurrency] = useControlledField({ name: Fields.CURRENCY, control });

  const currencies = useMemo(
    () =>
      servers
        .find(
          platform =>
            platform.platformType === platformField.value &&
            platform.accounts?.some(account => account.type === typeField.value),
        )
        ?.accounts?.find(({ type }) => type === typeField.value)
        ?.currenciesInfo?.map(({ currency, currencyImageUrlPath }) => ({
          value: currency,
          label: currency,
          icon: createImagePathUrl(currencyImageUrlPath!),
        })),
    [platformField.value, servers, typeField.value],
  );

  const currencyInfo = useMemo(
    () => currencies?.find(({ value }) => value === platformCurrency.value),
    [currencies, platformCurrency.value],
  );

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <div className="flex flex-col gap-4">
        <ToggleGroup
          value={typeField.value}
          onValueChange={typeField.onChange}
          data-test="create-account-type-switcher"
        >
          <ToggleGroup.Item value={TradingAccountType.Real}>{t("common.real")}</ToggleGroup.Item>
          <ToggleGroup.Item value={TradingAccountType.Demo}>{t("common.demo")}</ToggleGroup.Item>
        </ToggleGroup>
        <Select
          value={platformCurrency.value}
          onValueChange={platformCurrency.onChange}
          label={t("accounts.common.currency")}
          startSection={<Image width={24} height={24} src={currencyInfo?.icon} />}
        >
          {currencies?.map(({ label, value, icon }) => (
            <Select.Item key={value} value={value!} asChild>
              <div className="flex items-center gap-3">
                <Select.ItemHiddenValue>
                  <Image width={24} height={24} src={icon} />
                </Select.ItemHiddenValue>
                {label}
              </div>
            </Select.Item>
          ))}
        </Select>
        <Select
          value={platformField.value}
          onValueChange={platformField.onChange}
          label={t("accounts.common.platform")}
          data-test="create-account-platform-switcher"
        >
          <Select.Item value={TradingServerPlatform.MetaTrader5} asChild>
            <div className="flex w-full items-center justify-between">
              {t("accounts.common.mt5-label")}
              <Select.ItemHiddenValue>
                <Label variant="accent">{t("common.recommended")}</Label>
              </Select.ItemHiddenValue>
            </div>
          </Select.Item>
          <Select.Item value={TradingServerPlatform.MetaTrader4}>{t("accounts.common.mt4-label")}</Select.Item>
        </Select>
      </div>
      <SubmitButton data-test="create-account-btn-action" className="mt-8" fullWidth>
        {typeField.value === TradingAccountType.Real
          ? t("accounts.buttons.create-real")
          : t("accounts.buttons.create-demo")}
      </SubmitButton>
    </HookForm>
  );
};

export { CreateAccountForm };
