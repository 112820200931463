import { cva, type VariantProps } from "class-variance-authority";
import { Slot } from "radix-ui";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, memo, type ReactNode, useMemo } from "react";

import { cn } from "@/shared/styles";

import { Spinner } from "..";

const _SectionWrapper = ({
  size,
  children,
  position,
  isInvisible,
}: {
  children: ReactNode;
  position: "start" | "end";
  size: VariantProps<typeof buttonStyles>["size"];
  isInvisible?: boolean;
}) => {
  return (
    <div
      className={cn("flex grow", {
        "size-6 *:size-6": size === "md",
        "size-4 *:size-4": size === "sm",
        "justify-start": position === "start",
        "justify-end": position === "end",
        invisible: isInvisible,
      })}
    >
      {children}
    </div>
  );
};

const SectionWrapper = memo(_SectionWrapper);

const buttonStyles = cva(
  "relative inline-flex items-center justify-center gap-3 whitespace-nowrap rounded-full text-center outline-none transition-colors focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none",
  {
    variants: {
      variant: {
        primary: "",
        secondary: "",
        flat: "text-contrast-primary hover:bg-control-bg-hover focus-visible:outline-accent-text active:bg-control-bg-active disabled:text-contrast-tertiary",
      },
      color: {
        accent: "focus-visible:outline-accent-text",
        positive: "focus-visible:outline-positive-bg-active",
        warning: "focus-visible:outline-warning-bg-active",
        negative: "focus-visible:outline-negative-bg-active",
        staticBlack: "",
        staticWhite: "",
      },
      size: {
        sm: "typography-S-Compact-Medium h-10 px-4",
        md: "typography-M-Compact-Medium h-14 px-6",
      },
      pending: {
        true: "",
      },
      fullWidth: {
        true: "w-full",
      },
    },
    compoundVariants: [
      {
        variant: "flat",
        className: "disabled:text-contrast-primary",
      },
      {
        variant: "primary",
        color: "accent",
        className:
          "bg-accent-bg text-accent-over hover:bg-accent-bg-hover active:bg-accent-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "primary",
        color: "accent",
        pending: true,
        className: "disabled:bg-accent-bg disabled:text-accent-over",
      },
      {
        variant: "primary",
        color: "positive",
        className:
          "bg-positive-bg text-positive-over hover:bg-positive-bg-hover active:bg-positive-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "primary",
        color: "positive",
        pending: true,
        className: "disabled:bg-positive-bg disabled:text-positive-over",
      },
      {
        variant: "primary",
        color: "warning",
        className:
          "bg-warning-bg text-warning-over hover:bg-warning-bg-hover active:bg-warning-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "primary",
        color: "warning",
        pending: true,
        className: "disabled:bg-warning-bg disabled:text-warning-over",
      },
      {
        variant: "primary",
        color: "negative",
        className:
          "bg-negative-bg text-negative-over hover:bg-negative-bg-hover active:bg-negative-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "primary",
        color: "negative",
        pending: true,
        className: "disabled:bg-negative-bg disabled:text-negative-over",
      },
      {
        variant: "primary",
        color: "staticBlack",
        className:
          "bg-static-black text-static-white hover:bg-static-black/85 active:bg-static-black/75 disabled:bg-static-black/10 disabled:text-static-black/45",
      },
      {
        variant: "primary",
        color: "staticBlack",
        pending: true,
        className: "disabled:bg-static-black disabled:text-static-white",
      },
      {
        variant: "primary",
        color: "staticWhite",
        className:
          "bg-static-white text-static-black hover:bg-static-white/80 active:bg-static-white/60 disabled:bg-static-white/10 disabled:text-static-black/35",
      },
      {
        variant: "primary",
        color: "staticWhite",
        pending: true,
        className: "disabled:bg-static-white disabled:text-static-black",
      },
      {
        variant: "secondary",
        color: "accent",
        className:
          "bg-secondary-button-bg text-contrast-primary hover:bg-secondary-button-bg-hover active:bg-secondary-button-bg-active disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "secondary",
        color: "accent",
        pending: true,
        className: "disabled:bg-secondary-button-bg disabled:text-contrast-primary",
      },
      {
        variant: "secondary",
        color: "positive",
        className:
          "bg-positive-bg/15 text-positive-text hover:bg-positive-bg/20 active:bg-positive-bg/10 disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "secondary",
        color: "positive",
        pending: true,
        className: "disabled:bg-positive-bg/15 disabled:text-positive-text",
      },
      {
        variant: "secondary",
        color: "warning",
        className:
          "bg-warning-bg/15 text-warning-text hover:bg-warning-bg/20 active:bg-warning-bg/10 disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "secondary",
        color: "warning",
        pending: true,
        className: "disabled:bg-warning-bg/15 disabled:text-warning-text",
      },
      {
        variant: "secondary",
        color: "negative",
        className:
          "bg-negative-bg/15 text-negative-text hover:bg-negative-bg/20 active:bg-negative-bg/10 disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
      },
      {
        variant: "secondary",
        color: "negative",
        pending: true,
        className: "disabled:bg-negative-bg/15 disabled:text-negative-text",
      },
      // TODO: tokens
      {
        variant: "secondary",
        color: "staticBlack",
        className:
          "bg-[#E3E3E7] text-static-black hover:bg-[#E0E0E5] active:bg-[#D2D2DA] disabled:bg-[#E9E9EE] disabled:text-static-black/45",
      },
      {
        variant: "secondary",
        color: "staticBlack",
        pending: true,
        className: "disabled:bg-[#E3E3E7] disabled:text-static-black",
      },
      // FIXME: style is not the same as in the design
      {
        variant: "secondary",
        color: "staticWhite",
        className:
          "bg-static-white/10 text-static-black hover:bg-static-white/20 active:bg-static-white/30 disabled:bg-static-white/10 disabled:text-static-black/45",
      },
      {
        variant: "secondary",
        color: "staticWhite",
        pending: true,
        className: "disabled:bg-static-white/10 disabled:text-static-black",
      },
    ],
  },
);

type ButtonProps = ComponentPropsWithoutRef<"button"> &
  VariantProps<typeof buttonStyles> & {
    asChild?: boolean;
    description?: ReactNode | null;
    startSection?: ReactNode | null;
    endSection?: ReactNode | null;
    pending?: boolean;
  };

const Button = forwardRef<ElementRef<"button">, ButtonProps>(
  (
    {
      className,
      variant = "primary",
      size = "md",
      asChild = false,
      pending,
      disabled: disabledProp,
      type = "button",
      startSection,
      endSection,
      children,
      color = "accent",
      fullWidth,
      description,
      ...props
    },
    ref,
  ) => {
    const disabled = disabledProp || pending;
    const Comp = asChild && !pending ? Slot.Root : "button";

    const hasSections = !!startSection || !!endSection;
    const hasDescription = !!description;

    return (
      <Comp
        className={useMemo(
          () =>
            cn(
              buttonStyles({ variant, size, pending, color, fullWidth }),
              hasDescription && "typography-S-Medium flex-col gap-0.5",
              className,
            ),
          [variant, size, pending, color, className, fullWidth, hasDescription],
        )}
        disabled={disabled}
        type={type}
        {...props}
        ref={ref}
      >
        {!pending && hasSections && startSection !== null && (
          <SectionWrapper size={size} position="start">
            {startSection}
          </SectionWrapper>
        )}
        {!pending && <Slot.Slottable>{children}</Slot.Slottable>}
        {!pending && hasDescription && <div className="typography-S-Compact-Regular">{description}</div>}
        {!pending && hasSections && endSection !== null && (
          <SectionWrapper size={size} position="end">
            {endSection}
          </SectionWrapper>
        )}
        {pending && (
          <>
            {hasSections && startSection !== null && (
              <SectionWrapper size={size} position="start" isInvisible>
                {startSection}
              </SectionWrapper>
            )}
            <span className="invisible">{children}</span>
            {hasDescription && <div className="typography-S-Compact-Regular invisible">{description}</div>}
            {hasSections && endSection !== null && (
              <SectionWrapper size={size} position="end" isInvisible>
                {endSection}
              </SectionWrapper>
            )}
            <div
              className={cn("absolute", {
                "*:size-4": size === "sm",
                "*:size-6": size === "md",
              })}
            >
              <Spinner />
            </div>
          </>
        )}
      </Comp>
    );
  },
);

const Component = memo(Button);

Component.displayName = "Button";

export { Component as Button, buttonStyles };
