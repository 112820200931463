import { cva } from "class-variance-authority";
import { type FC } from "react";

import { IconArrowRight, IconDrawLine } from "@/domains/icons";
import { type TerminalSymbolWidgetSummaryScoreItem, TradingCentralSignalTrend } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Text, Tooltip } from "@/shared/ui";

const barStyles = cva(
  "relative h-[16px] w-[26px] border-y border-s after:absolute after:-end-px after:block after:h-3.5 after:w-px after:bg-surface-elevation-1 first:rounded-s-lg last:rounded-e-lg last:border-e last:after:hidden",
  {
    variants: {
      filled: {
        true: "after:z-10",
      },
      last: {
        true: "after:hidden",
      },
      variant: {
        [TradingCentralSignalTrend.Bearish]: "border-negative-bg",
        [TradingCentralSignalTrend.Bullish]: "border-positive-bg",
        [TradingCentralSignalTrend.Neutral]: "border-secondary-button-bg",
      },
    },
    compoundVariants: [
      {
        variant: TradingCentralSignalTrend.Bearish,
        filled: true,
        className: "bg-negative-bg",
      },
      {
        variant: TradingCentralSignalTrend.Bullish,
        filled: true,
        className: "bg-positive-bg",
      },
      {
        variant: TradingCentralSignalTrend.Neutral,
        filled: true,
        className: "bg-secondary-button-bg",
      },
    ],
  },
);

type Props = {
  label: string;
  description: string;
  summary: TerminalSymbolWidgetSummaryScoreItem;
};

const InfoRow: FC<Props> = ({ description, label, summary }) => {
  const { score, trend } = summary;

  return (
    <Tooltip content={description}>
      <div className="flex items-center justify-between gap-2">
        <div
          className={cn("flex items-center gap-2", {
            "text-negative-text": trend === TradingCentralSignalTrend.Bearish,
            "text-positive-text": trend === TradingCentralSignalTrend.Bullish,
            "text-contrast-tertiary": trend === TradingCentralSignalTrend.Neutral,
          })}
        >
          {trend === TradingCentralSignalTrend.Bullish && <IconArrowRight className="-rotate-90" />}
          {trend === TradingCentralSignalTrend.Neutral && <IconDrawLine />}
          {trend === TradingCentralSignalTrend.Bearish && <IconArrowRight className="rotate-90" />}
          <Text variant="S / Regular" color="secondary">
            {label}
          </Text>
        </div>
        <div className="flex">
          {[...new Array(4)].map((_, i) => (
            <div
              key={i}
              className={barStyles({ filled: i + 1 <= score!, variant: trend as any, last: i + 1 === score })}
            />
          ))}
        </div>
      </div>
    </Tooltip>
  );
};

export { InfoRow };
