import { cva, type VariantProps } from "class-variance-authority";
import { Dialog as DialogPrimitive } from "radix-ui";
import type { ComponentPropsWithoutRef, ElementRef, FC, ReactNode } from "react";
import { forwardRef } from "react";

import { TooltipQuestionIcon } from "@/components/icons";
import { IconClose } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { IconButton, mainDialogContentStyles, overlayStyles, Text, Tooltip } from "..";

const Separator: FC = () => {
  return <div className="my-6 min-h-px w-full bg-contrast-quaternary md:my-8" />;
};

type HeaderProps = {
  title: ReactNode;
  image?: ReactNode;
  centered?: boolean;
  tooltip?: ReactNode;
};

const Header: FC<HeaderProps> = ({ title, centered, tooltip, image }) => {
  return (
    <div
      className={cn(
        "mb-3 items-start gap-2 py-2",
        image && "pb-0",
        centered ? "grid grid-cols-[1fr_auto_1fr]" : "flex justify-between",
      )}
    >
      {centered && <div className={cn("-ms-2 size-10", tooltip && "size-20")}></div>}
      <div className="flex flex-col items-center gap-4 py-1">
        {image}
        <DialogPrimitive.Title asChild>
          <Text as="h2" variant="XL / Medium" color="primary" align={centered ? "center" : "start"}>
            {title}
          </Text>
        </DialogPrimitive.Title>
      </div>
      <div className="-me-2 flex items-center justify-end">
        {tooltip && (
          <div className="grid size-10 place-items-center">
            <Tooltip content={tooltip}>
              <TooltipQuestionIcon />
            </Tooltip>
          </div>
        )}
        <DialogPrimitive.Close asChild>
          <IconButton size="sm" variant="flat">
            <IconClose />
          </IconButton>
        </DialogPrimitive.Close>
      </div>
    </div>
  );
};

const imageStyles = cva("mt-1 grid place-items-center *:size-full", {
  variants: {
    size: {
      md: "size-[72px]",
      lg: "size-24",
    },
  },
});

type ImageProps = {
  children?: ReactNode;
} & VariantProps<typeof imageStyles>;

const Image: FC<ImageProps> = ({ children, size = "md" }) => {
  return <div className={imageStyles({ size })}>{children}</div>;
};

const Description: FC<ComponentPropsWithoutRef<typeof Text>> = ({
  align = "center",
  color = "primary",
  variant = "S / Regular",
  ...props
}) => {
  return (
    <DialogPrimitive.Description asChild>
      <Text align={align} color={color} variant={variant} {...props} />
    </DialogPrimitive.Description>
  );
};

const Buttons: FC<{ children?: ReactNode; direction?: "column" | "row" }> = ({ children, direction = "row" }) => {
  return (
    <div
      className={cn("mt-8 flex flex-wrap gap-3", {
        "flex-row-reverse *:flex-1": direction === "row",
        "flex-col": direction === "column",
      })}
    >
      {children}
    </div>
  );
};

const contentStyles = cva("rounded-xl bg-surface-elevation-2 p-8 pt-7 shadow-modal outline-none", {
  variants: {
    size: {
      md: "w-[min(480px,_90vw)]",
      lg: "w-[min(560px,_90vw)]",
    },
  },
});

const Content = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  Omit<ComponentPropsWithoutRef<typeof DialogPrimitive.Content>, "className"> & VariantProps<typeof contentStyles>
>(({ size = "md", ...props }, ref) => (
  <DialogPrimitive.Portal>
    <DialogPrimitive.Overlay
      className={cn(overlayStyles(), "grid place-items-center overflow-y-auto overflow-x-hidden")}
    >
      <DialogPrimitive.Content
        className={cn(mainDialogContentStyles(), contentStyles({ size }))}
        ref={ref}
        {...props}
      />
    </DialogPrimitive.Overlay>
  </DialogPrimitive.Portal>
));
Content.displayName = DialogPrimitive.Content.displayName;

const Component = Object.assign(DialogPrimitive.Root, {
  Trigger: DialogPrimitive.Trigger,
  Portal: DialogPrimitive.Portal,
  Overlay: DialogPrimitive.Overlay,
  PrimitiveContent: DialogPrimitive.Content,
  Content,
  Title: DialogPrimitive.Title,
  PrimitiveDescription: DialogPrimitive.Description,
  Description,
  Close: DialogPrimitive.Close,
  Separator: Separator,
  Header,
  Buttons,
  Image,
});

export { Component as Dialog };
