import type { Config } from "tailwindcss";
import plugin from "tailwindcss/plugin";
import tailwindAnimate from "tailwindcss-animate";

const systemFallbackFonts =
  "system-ui, -apple-system,	'Segoe UI', Roboto,	Helvetica,Arial,	sans-serif,'Apple Color Emoji', 'Segoe UI Emoji'";
const fontFamily = `AeonikPro, NotoSans, ${systemFallbackFonts}`;
const gilroyFont = `Gilroy, NotoSans, ${systemFallbackFonts}`;
const robotoFont = `Roboto, sans-serif, ${systemFallbackFonts}`;

export { fontFamily, gilroyFont, robotoFont };

export default {
  content: ["./src/**/*.{js,ts,jsx,tsx}"],
  future: {
    // https://github.com/tailwindlabs/tailwindcss/discussions/1739#discussioncomment-3630717
    // disables hover state on touch devices
    hoverOnlyWhenSupported: true,
  },
  theme: {
    fontFamily: {
      gilroy: gilroyFont,
      roboto: robotoFont,
    },
    boxShadow: {
      none: "none",
      // TODO: remove default
      DEFAULT: "0px 10px 40px -10px rgba(21, 38, 149, 0.07)",
      modal: "0px 10px 40px -8px rgba(0, 0, 0, 0.10)",
      switch: "0px 4px 8px -2px rgba(0, 0, 0, 0.32)",
      "signal-form": "0px -6px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    colors: {
      inherit: "inherit",
      current: "currentColor",
      transparent: "transparent",

      /* doesn't exist in ui kit */
      black: "rgb(var(--black) / <alpha-value>)",

      dark: "rgb(var(--dark) / <alpha-value>)",
      white: "rgb(var(--white) / <alpha-value>)",
      gray: "rgb(var(--gray) / <alpha-value>)",
      real: "rgb(var(--real) / <alpha-value>)",
      // warning: {
      //   hover: "rgb(var(--warning-hover) / <alpha-value>)",
      //   DEFAULT: "rgb(var(--warning) / <alpha-value>)",
      //   press: "rgb(var(--warning-press) / <alpha-value>)",
      // },
      action: {
        hover: "rgb(var(--action-hover) / <alpha-value>)",
        DEFAULT: "rgb(var(--action) / <alpha-value>)",
        press: "rgb(var(--action-press) / <alpha-value>)",
      },
      attention: {
        hover: "rgb(var(--attention-hover) / <alpha-value>)",
        DEFAULT: "rgb(var(--attention) / <alpha-value>)",
        press: "rgb(var(--attention-press) / <alpha-value>)",
      },
      text: {
        DEFAULT: "rgb(var(--text-main) / <alpha-value>)",
        secondary: "rgb(var(--text-secondary) / <alpha-value>)",
        placeholder: "rgb(var(--text-placeholder) / <alpha-value>)",
        inverse: "rgb(var(--text-inverse) / <alpha-value>)",
      },
      bg: {
        DEFAULT: "rgb(var(--bg-primary) / <alpha-value>)",
        deep: "rgb(var(--bg-deep) / <alpha-value>)",
        selected: "rgb(var(--bg-selected) / <alpha-value>)",
        back: "rgb(var(--bg-back) / <alpha-value>)",
        additional: "rgb(var(--bg-additional) / <alpha-value>)",
        border: "rgb(var(--bg-border) / <alpha-value>)",
      },

      /* otp new */

      surface: {
        canvas: "var(--surface-canvas)",
        ["under-canvas"]: "var(--surface-under-canvas)",
        overlay: "var(--surface-overlay)",
        elevation: {
          1: "var(--elevation-1)",
          2: "var(--elevation-2)",
          3: "var(--elevation-3)",
        },
      },

      contrast: {
        primary: "var(--contrast-primary)",
        secondary: "var(--contrast-secondary)",
        tertiary: "var(--contrast-tertiary)",
        quaternary: "var(--contrast-quaternary)",
        quinary: "var(--contrast-quinary)",
      },

      control: {
        bg: {
          DEFAULT: "var(--control-bg-default)",
          hover: "var(--control-bg-hover)",
          active: "var(--control-bg-active)",
          inactive: "var(--control-bg-inactive)",
        },
        border: "var(--control-border)",
      },

      card: {
        bg: {
          DEFAULT: "var(--card-bg-default)",
          accent: "var(--card-bg-accent)",
          hover: "var(--card-bg-hover)",
          active: "var(--card-bg-active)",
          inactive: "var(--card-bg-inactive)",
        },
        border: "var(--card-border)",
      },

      input: {
        bg: {
          DEFAULT: "var(--input-bg-default)",
          hover: "var(--input-bg-hover)",
          active: "var(--input-bg-active)",
          inactive: "var(--input-bg-inactive)",
        },
        border: "var(--input-border)",
      },

      accent: {
        text: "var(--accent-text)",
        over: "var(--accent-over)",
        bg: {
          DEFAULT: "var(--accent-bg-default)",
          hover: "var(--accent-bg-hover)",
          active: "var(--accent-bg-active)",
        },
      },

      "secondary-button": {
        text: "var(--secondary-button-text)",
        over: "var(--secondary-button-over)",
        bg: {
          DEFAULT: "var(--secondary-button-bg-default)",
          hover: "var(--secondary-button-bg-hover)",
          active: "var(--secondary-button-bg-active)",
        },
      },

      positive: {
        text: "var(--positive-text)",
        over: "var(--positive-over)",
        bg: {
          DEFAULT: "rgb(var(--positive-bg-default) / <alpha-value>)",
          hover: "var(--positive-bg-hover)",
          active: "var(--positive-bg-active)",
        },
      },

      warning: {
        text: "var(--warning-text)",
        over: "var(--warning-over)",
        bg: {
          DEFAULT: "rgb(var(--warning-bg-default) / <alpha-value>)",
          hover: "var(--warning-bg-hover)",
          active: "var(--warning-bg-active)",
        },
      },

      negative: {
        text: "var(--negative-text)",
        over: "var(--negative-over)",
        bg: {
          DEFAULT: "rgb(var(--negative-bg-default) / <alpha-value>)",
          hover: "var(--negative-bg-hover)",
          active: "var(--negative-bg-active)",
        },
      },

      constant: {
        label: "rgb(var(--constant-label) / <alpha-value>)",
        lemon: "rgb(var(--constant-lemon) / <alpha-value>)",
        "light-green": "rgb(var(--constant-light-green) / <alpha-value>)",
      },

      static: {
        black: "rgb(var(--static-black) / <alpha-value>)",
        white: "rgb(var(--static-white) / <alpha-value>)",
      },

      gradient: {
        canvas: {
          start: "var(--canvas-gradient-start)",
          end: "var(--canvas-gradient-end)",
        },
      },
    },
    extend: {
      spacing: {
        "expanded-sidebar": "var(--sidebar-expanded)",
        "collapsed-sidebar": "var(--sidebar-collapsed)",
        "header-height": "var(--header-height)",
      },
      borderRadius: {
        inherit: "inherit",
        none: "0px",
        "2xs": "2px",
        xs: "4px",
        sm: "8px",
        md: "12px",
        lg: "16px",
        xl: "20px",
        full: "9999px",
      },
      keyframes: {
        "collapsible-down": {
          from: { height: "0" },
          to: { height: "var(--radix-collapsible-content-height)" },
        },
        "collapsible-up": {
          from: { height: "var(--radix-collapsible-content-height)" },
          to: { height: "0" },
        },
        "loader-dots": {
          "0%, 100%": {
            transform: "scale(1)",
            opacity: "1",
          },
          "50%": {
            transform: "scale(0.6)",
            opacity: "0.5",
          },
        },
        "caret-blink": {
          "0%,70%,100%": { opacity: "1" },
          "20%,50%": { opacity: "0" },
        },
      },
      animation: ({ theme }) => ({
        "collapsible-down": `collapsible-down ${theme("transitionDuration.DEFAULT")} ease-out`,
        "collapsible-up": `collapsible-up ${theme("transitionDuration.DEFAULT")} ease-out`,
        "loader-dots": "loader-dots 0.8s infinite linear",
        "caret-blink": "caret-blink 1s ease-out infinite",
      }),
      borderColor: {
        DEFAULT: "rgb(var(--bg-border) / <alpha-value>)",
      },
      transitionDuration: {
        DEFAULT: "300ms",
      },
      zIndex: {
        0: "0",
        10: "10",
        20: "20",
        30: "30",
        40: "40",
        50: "50", // tooltips, dialogs, popovers ...
        60: "60",
        70: "70",
        80: "80",
        90: "90",
        100: "100", // toast
      },
    },
  },
  plugins: [
    plugin(({ addUtilities, matchUtilities, theme, addComponents }) => {
      addComponents({
        ".typography-inherit": {
          fontFamily: "inherit",
          fontSize: "inherit",
          fontStyle: "inherit",
          fontWeight: "inherit",
          lineHeight: "inherit",
          letterSpacing: "inherit",
        },
        ".typography-3XL-Regular": {
          fontFamily,
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "48px",
        },
        ".typography-3XL-Medium": {
          fontFamily,
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "48px",
        },
        ".typography-2XL-Regular": {
          fontFamily,
          fontSize: "32px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "40px",
        },
        ".typography-2XL-Medium": {
          fontFamily,
          fontSize: "32px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "40px",
        },
        ".typography-XL-Regular": {
          fontFamily,
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "32px",
        },
        ".typography-XL-Medium": {
          fontFamily,
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "32px",
        },
        ".typography-L-Regular": {
          fontFamily,
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "28px",
        },
        ".typography-L-Medium": {
          fontFamily,
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "28px",
        },
        ".typography-M-Regular": {
          fontFamily,
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
        },
        ".typography-M-Medium": {
          fontFamily,
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",
        },
        ".typography-M-Compact-Regular": {
          fontFamily,
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "20px",
        },
        ".typography-M-Compact-Medium": {
          fontFamily,
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "20px",
        },
        ".typography-S-Regular": {
          fontFamily,
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "20px",
        },
        ".typography-S-Medium": {
          fontFamily,
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "20px",
        },
        ".typography-S-Compact-Regular": {
          fontFamily,
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "18px",
          letterSpacing: "0.14px",
        },
        ".typography-S-Compact-Medium": {
          fontFamily,
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "18px",
          letterSpacing: "0.14px",
        },
        ".typography-XS-Regular": {
          fontFamily,
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.12px",
        },
        ".typography-XS-Medium": {
          fontFamily,
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "16px",
          letterSpacing: "0.24px",
        },
      });
      addUtilities({
        ".collapsible": {
          "@apply overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down":
            {},
        },

        ".scrollbar-hide": {
          /* IE and Edge */
          "-ms-overflow-style": "none",

          /* Firefox */
          "scrollbar-width": "none",

          /* Safari and Chrome */
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },

        ".scrollbar-default": {
          /* IE and Edge */
          "-ms-overflow-style": "auto",

          /* Firefox */
          "scrollbar-width": "auto",

          /* Safari and Chrome */
          "&::-webkit-scrollbar": {
            display: "block",
          },
        },
      });
      matchUtilities(
        {
          "grid-auto-fill": value => ({
            gridTemplateColumns: `repeat(auto-fill, minmax(min(${value}, 100%), 1fr))`,
          }),
          "grid-auto-fit": value => ({
            gridTemplateColumns: `repeat(auto-fit, minmax(min(${value}, 100%), 1fr))`,
          }),
        },
        {
          values: theme("width"),
        },
      );
    }),
    tailwindAnimate,
  ],
} satisfies Config;
