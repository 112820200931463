import type { FC } from "react";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { type TerminalSymbolSignal, TradingCentralSignalType } from "@/services/openapi";
import { Button, Dialog, Text } from "@/shared/ui";

import { useSignalContext } from "../signal/context";
import { SignalTextFormat } from "../signal/text-format";
import { ChartDialog } from "./ui/chart-dialog";

type Props = {
  signal: TerminalSymbolSignal;
  symbol: string;
  isSignalChartDialogShown: ReturnType<typeof useSignalContext>["isSignalChartDialogShown"];
  closeSignalChartDialog: ReturnType<typeof useSignalContext>["closeSignalChartDialog"];
  openSignalForm: ReturnType<typeof useSignalContext>["openSignalForm"];
};

const _ChartSignalDialog: FC<Props> = ({
  signal,
  symbol,
  closeSignalChartDialog,
  isSignalChartDialogShown,
  openSignalForm,
}) => {
  const { t } = useTranslation();

  const description = useMemo(() => {
    if (signal.type === TradingCentralSignalType.StrongUp) {
      return t("terminal.chart.signal-popover.strong-up-description", { symbol });
    }
    if (signal.type === TradingCentralSignalType.Up) {
      return t("terminal.chart.signal-popover.up-description", { symbol });
    }
    if (signal.type === TradingCentralSignalType.StrongDown) {
      return t("terminal.chart.signal-popover.strong-down-description", { symbol });
    }
    if (signal.type === TradingCentralSignalType.Down) {
      return t("terminal.chart.signal-popover.down-description", { symbol });
    }
  }, [t, signal.type, symbol]);

  return (
    <Dialog open={isSignalChartDialogShown} onOpenChange={closeSignalChartDialog}>
      <ChartDialog.Content>
        <ChartDialog.Header title={<SignalTextFormat hasAccentText type={signal.type!} />} />
        <Text color="primary" variant="S / Regular">
          {description}
        </Text>
        <ChartDialog.Buttons>
          <Dialog.Close asChild>
            <Button size="sm" onClick={openSignalForm}>
              {t("terminal.signal.trade-button")}
            </Button>
          </Dialog.Close>
        </ChartDialog.Buttons>
      </ChartDialog.Content>
    </Dialog>
  );
};

const ChartSignalDialog = memo(_ChartSignalDialog);

export { ChartSignalDialog };
