import type { FC } from "react";
import { memo, useMemo } from "react";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { getDecimalScaleFromTickSize } from "@/features/terminal/helpers/formatting";
import { useAddChartSymbol } from "@/features/terminal/hooks/add-chart-symbol.hook";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";
import type { TerminalPosition } from "@/services/openapi";

import { ClosedPositionVolume } from "../../closed-positions/volume";
import { OrderTypeFormat } from "../../ui/order-type-format";
import { TradingTable } from "../table";

type Props = {
  position: TerminalPosition;
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
};

const ClosedPositionsBodyRow: FC<Props> = ({
  position,
  setSymbol,
  accountId,
  accountCurrency,
  currencyDecimalScale,
}) => {
  const {
    symbol,
    type,
    stopLoss,
    takeProfit,
    swap,
    profit,
    dateOpen,
    volumeSell,
    volumeBuy,
    priceOpen,
    dateClose,
    priceClose,
    position: positionNumber,
    symbolDigits,
    symbolVolumeStep,
    icon,
  } = position;

  const volumeDecimalScale = useMemo(() => getDecimalScaleFromTickSize(symbolVolumeStep!), [symbolVolumeStep]);

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, accountId });

  return (
    <TradingTable.Row>
      <TradingTable.Cell>{terminalFormatDate(dateOpen!)}</TradingTable.Cell>
      <TradingTable.Cell>
        <TradingTable.Symbol
          symbol={symbol!}
          symbolUrlPath={icon!.webUrlPath!}
          onClick={() => addChartSymbol(symbol!)}
        />
      </TradingTable.Cell>
      <TradingTable.Cell>
        <OrderTypeFormat type={type!} />
      </TradingTable.Cell>
      <TradingTable.Cell>
        <ClosedPositionVolume
          decimalScale={volumeDecimalScale}
          type={type!}
          volumeBuy={volumeBuy}
          volumeSell={volumeSell}
        />
      </TradingTable.Cell>
      <TradingTable.Cell>
        <NumberFormat value={priceOpen} decimalScale={symbolDigits} />
      </TradingTable.Cell>
      <TradingTable.Cell>{positionNumber}</TradingTable.Cell>
      <TradingTable.Cell>
        {takeProfit ? <NumberFormat value={takeProfit} decimalScale={symbolDigits} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell>
        {stopLoss ? <NumberFormat value={stopLoss} decimalScale={symbolDigits} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell>{terminalFormatDate(dateClose!)}</TradingTable.Cell>
      <TradingTable.Cell>
        <NumberFormat value={priceClose} decimalScale={symbolDigits} />
      </TradingTable.Cell>
      <TradingTable.Cell align="end">
        <NumberFormat value={swap} decimalScale={currencyDecimalScale} currency={accountCurrency} hideCurrency />
      </TradingTable.Cell>
      <TradingTable.Cell color={getNumberTextColor(profit!)} align="end">
        <PnlFormat value={profit} decimalScale={currencyDecimalScale} currency={accountCurrency} hideCurrency />
      </TradingTable.Cell>
    </TradingTable.Row>
  );
};

const Component = memo(ClosedPositionsBodyRow);

export { Component as ClosedPositionsBodyRow };
