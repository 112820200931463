import { type FC } from "react";
import { useTranslation } from "react-i18next";

import positiveImg from "@/assets/images/positive.png";
import { Button, Dialog, Image } from "@/shared/ui";

const ResetPasswordSuccessDialog: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        title={t("auth.reset-password.dialog.title")}
        centered
        image={
          <Dialog.Image size="lg">
            <Image src={positiveImg} />
          </Dialog.Image>
        }
      />
      <Dialog.Buttons>
        <Dialog.Close asChild>
          <Button>{t("auth.reset-password.dialog.action")}</Button>
        </Dialog.Close>
      </Dialog.Buttons>
    </>
  );
};

export { ResetPasswordSuccessDialog };
