import { createSearchParams, useNavigate } from "react-router-dom";

import { useLocationPath } from "@/app/hooks";
import { DialogName, useDialog } from "@/hooks/dialog.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Dialog } from "@/shared/ui";

import { CongratulationsDialogContent } from "./congratulations-dialog-content";

const CongratulationsDialog = () => {
  const { onClose, open } = useDialog(DialogName.CONGRATULATIONS);
  const navigate = useNavigate();
  const path = useLocationPath();

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <Dialog.Content>
        <CongratulationsDialogContent
          onDeposit={() => {
            onClose();
            navigate({
              pathname: cabinetRoutes.deposit,
              search: createSearchParams({ from: path }).toString(),
            });
          }}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export { CongratulationsDialog };
