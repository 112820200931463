import { Outlet } from "react-router-dom";

import { GlobalLoader } from "@/components/loader";
import { useProfileHeaderQuery } from "@/state/server/profile";

export const ProfileDataProvider = ({ children }: { children?: React.ReactElement }) => {
  const { data } = useProfileHeaderQuery();

  if (!data) {
    return <GlobalLoader />;
  }

  return children ? children : <Outlet />;
};
