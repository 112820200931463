import type { ComponentProps, FC } from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ChangePasswordVerify2FAForm } from "@/features/profile/change-password/verify/two-factor/form";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Dialog, toast, ToastIcons } from "@/shared/ui";
import { useSignInMutation } from "@/state/server/auth";
import { usePlatformData } from "@/state/server/platform/platform.hooks";
import { userPlatform } from "@/utils/helpers";

import { AuthWrapperQR } from "../../wrapper-qr";
import { SignInForm } from "./signin.form";

const showDownloadButtons = userPlatform.os == "IOS" || userPlatform.os === "Android";

const SignInContainer: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [opened, { open, close }] = useDisclosure();

  const [formValues, setFormValues] = useState<Parameters<ComponentProps<typeof SignInForm>["onSubmit"]>[0] | null>(
    null,
  );

  const platformInfo = usePlatformData();

  const { mutateAsync: signIn } = useSignInMutation({
    showErrorMessage: false,
  });

  const { mutateAsync: signIn2FA } = useSignInMutation();

  const handleSubmit: ComponentProps<typeof SignInForm>["onSubmit"] = useCallback(
    values => {
      return signIn(
        { logInRequest: values },
        {
          onSuccess: () => {
            navigate(cabinetRoutes.home);
          },
          onError: error => {
            if (error.codeMessage === "Auth2FaRequired") {
              setFormValues(values);
              open();
              return;
            }
            error.errorMessage && toast({ title: error.errorMessage, icon: ToastIcons.ERROR });
          },
        },
      );
    },
    [signIn, navigate, open],
  );

  const handle2FASubmit: ComponentProps<typeof ChangePasswordVerify2FAForm>["onSubmit"] = useCallback(
    ({ code }) => {
      return signIn2FA(
        { logInRequest: { ...formValues, twoFactorCode: code } },
        {
          onSuccess: () => {
            navigate(cabinetRoutes.home);
          },
        },
      );
    },
    [signIn2FA, navigate, formValues],
  );

  return (
    <>
      <AuthWrapperQR title={t("auth.sign-in.title")} type="sign-in" qrLink={platformInfo.app!.appUniversalUrl!}>
        <SignInForm
          platformInfo={platformInfo}
          onSubmit={handleSubmit}
          showDownloadButtons={showDownloadButtons}
          downloadLink={platformInfo.app!.appUniversalUrl!}
        />
      </AuthWrapperQR>
      <Dialog open={opened} onOpenChange={close}>
        <Dialog.Content>
          <ChangePasswordVerify2FAForm onSubmit={handle2FASubmit} />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export { SignInContainer };
