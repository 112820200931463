import { useStore } from "@nanostores/react";
import { produce } from "immer";
import type { ReactNode } from "react";
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useImmer } from "use-immer";

import { logError } from "@/app/libs/sentry";
import type {
  TerminalAccountSymbols,
  TerminalEventSymbolSessionStateType,
  TerminalGroup,
  TerminalSymbols,
  TerminalSymbolSignal,
  TerminalSymbolsLiveData,
} from "@/services/openapi";
import { TradingCentralSignalType } from "@/services/openapi";
import {
  $marketWatchMessage,
  $symbolsHistoryPricesMessage,
  $symbolsSessionsMessage,
  $symbolsSignalsMessage,
  $symbolsWidgetsMessage,
  socketClient,
} from "@/services/websocket";
import { terminalQueryKeys, useUpdateFavoriteSymbolsGroupMutation } from "@/state/server/terminal";

import type { MergedTerminalSymbol, SymbolsListType } from "../helpers/symbols";
import { convertSocketWidget, normalizeSymbolsList, StaticSymbolGroup } from "../helpers/symbols";

type ContextProps = {
  symbols: MergedTerminalSymbol[];
  symbolsList: SymbolsListType;
  symbolGroups: TerminalGroup[];
  group: string;
  changeGroup: (group: string) => void;
  favorites: TerminalAccountSymbols;
};

const Context = createContext<ContextProps | undefined>(undefined);

type Props = {
  symbols: TerminalSymbols;
  symbolsUpdates: TerminalSymbolsLiveData;
  favorites: TerminalAccountSymbols;
  accountId: string;
  children: ReactNode;
};

const Provider = memo(({ children, accountId, symbols: symbolsProps, favorites, symbolsUpdates }: Props) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const symbolsWidgetsMessage = useStore($symbolsWidgetsMessage);
  const symbolsSignalsMessage = useStore($symbolsSignalsMessage);
  const symbolsSessionsMessage = useStore($symbolsSessionsMessage);
  const symbolsHistoryPricesMessage = useStore($symbolsHistoryPricesMessage);
  const marketWatchMessage = useStore($marketWatchMessage);

  const { mutate } = useUpdateFavoriteSymbolsGroupMutation();

  const [list, setList] = useImmer<SymbolsListType>(() => {
    const normalizedSymbols = normalizeSymbolsList(symbolsProps.symbols!);
    const { charts, excluded } = favorites;

    symbolsUpdates.symbols!.forEach(
      ({
        symbol,
        priceAsk,
        priceBid,
        priceLast24H,
        signal,
        widget,
        state,
        periodDateEnd,
        periodDuration,
        priceHigh24H,
        priceLow24H,
      }) => {
        normalizedSymbols[symbol!]!.priceAsk = priceAsk;
        normalizedSymbols[symbol!]!.priceBid = priceBid;
        normalizedSymbols[symbol!]!.signal = signal;
        normalizedSymbols[symbol!]!.widget = widget;
        normalizedSymbols[symbol!]!.state = state;
        normalizedSymbols[symbol!]!.periodDuration = periodDuration;
        normalizedSymbols[symbol!]!.periodDateEnd = periodDateEnd;
        normalizedSymbols[symbol!]!.priceLast24H = priceLast24H;
        normalizedSymbols[symbol!]!.priceHigh24H = priceHigh24H;
        normalizedSymbols[symbol!]!.priceLow24H = priceLow24H;
      },
    );

    for (const symbol in normalizedSymbols) {
      normalizedSymbols[symbol]!.isFavorite = favorites.favorites!.includes(symbol);
      normalizedSymbols[symbol]!.isChartFavorite = charts!.includes(symbol);
      normalizedSymbols[symbol]!.isExcluded = excluded!.includes(symbol);
    }
    return normalizedSymbols;
  });

  const symbolGroups: ContextProps["symbolGroups"] = useMemo(
    () => [
      {
        group: StaticSymbolGroup.POPULAR,
        title: t("terminal.groups.popular")!,
      },
      {
        group: StaticSymbolGroup.FAVORITES,
        title: t("terminal.groups.favorites")!,
      },
      ...symbolsProps.groups!,
    ],
    [symbolsProps, t],
  );

  const [group, setGroup] = useState<ContextProps["group"]>(() => favorites.group || symbolGroups[0]!.group!);

  const changeGroup: ContextProps["changeGroup"] = useCallback(
    group => {
      setGroup(group);
      mutate({ group, tradingAccountId: accountId });
      queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
        return produce(oldData!, draft => {
          draft.group = group;
        })!;
      });
    },
    [accountId, mutate, setGroup, queryClient],
  );

  useEffect(() => {
    socketClient.subscribeMarketWatch();
    socketClient.subscribeSymbolsUpdates();

    return () => {
      socketClient.unsubscribeMarketWatch();
      socketClient.unsubscribeSymbolsUpdates();
    };
  }, []);

  useEffect(() => {
    try {
      const { charts, excluded } = favorites;
      setList(draftList => {
        for (const symbol in draftList) {
          draftList[symbol]!.isFavorite = favorites.favorites!.includes(symbol);
          draftList[symbol]!.isChartFavorite = charts!.includes(symbol);
          draftList[symbol]!.isExcluded = excluded!.includes(symbol);
        }
      });
    } catch (error) {
      logError(error);
    }
  }, [favorites]);

  useEffect(() => {
    try {
      if (!marketWatchMessage) return;

      setList(draftList => {
        marketWatchMessage.s!.forEach(({ s, a, b }) => {
          if (draftList[s!]) {
            draftList[s!]!.priceAsk = a;
            draftList[s!]!.priceBid = b;
          }
        });
      });
    } catch (error) {
      logError(error);
    }
  }, [marketWatchMessage]);

  useEffect(() => {
    try {
      if (!symbolsHistoryPricesMessage || symbolsHistoryPricesMessage.h !== 24) return;

      setList(draftList => {
        symbolsHistoryPricesMessage.s!.forEach(({ s, p, h, l }) => {
          if (draftList[s!]) {
            draftList[s!]!.priceLast24H = p;
            draftList[s!]!.priceHigh24H = h;
            draftList[s!]!.priceLow24H = l;
          }
        });
      });
    } catch (error) {
      logError(error);
    }
  }, [symbolsHistoryPricesMessage]);

  useEffect(() => {
    try {
      if (!symbolsSignalsMessage) return;

      setList(draftList => {
        symbolsSignalsMessage.s!.forEach(({ s, pr1, pr2, ps1, ps2, pt, t }) => {
          if (draftList[s!]) {
            const newSignal: TerminalSymbolSignal = {
              type: t as any as TradingCentralSignalType,
              priceTarget: pt,
              priceResistance1: pr1,
              priceResistance2: pr2,
              priceSupport1: ps1,
              priceSupport2: ps2,
            };
            draftList[s!]!.signal = newSignal;
          }
        });
      });
    } catch (error) {
      logError(error);
    }
  }, [symbolsSignalsMessage]);

  useEffect(() => {
    try {
      if (!symbolsWidgetsMessage) return;

      setList(draftList => {
        symbolsWidgetsMessage.s!.forEach(({ s, b, sr, ss, t }) => {
          if (draftList[s!]) {
            draftList[s!]!.widget = convertSocketWidget({ b, sr, ss, t });
          }
        });
      });
    } catch (error) {
      logError(error);
    }
  }, [symbolsWidgetsMessage]);

  useEffect(() => {
    try {
      if (!symbolsSessionsMessage) return;

      setList(draftList => {
        symbolsSessionsMessage.s!.forEach(({ t, d, e, s }) => {
          if (draftList[s!]) {
            draftList[s!]!.state = t as any as TerminalEventSymbolSessionStateType;
            draftList[s!]!.periodDateEnd = e;
            draftList[s!]!.periodDuration = d;
          }
        });
      });
    } catch (error) {
      logError(error);
    }
  }, [symbolsSessionsMessage]);

  const symbols = useMemo(() => Object.values(list), [list]);

  const value: ContextProps = useMemo(
    () => ({
      symbols,
      symbolGroups,
      group,
      changeGroup,
      symbolsList: list,
      favorites,
    }),
    [symbols, symbolGroups, group, changeGroup, list, favorites],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
});

Provider.displayName = "TerminalSymbolsContextProvider";

const useSymbolsContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useSymbolsContext must be used within a TerminalSymbolsContextProvider");
  }

  return context;
};

export { Provider as TerminalSymbolsContextProvider, useSymbolsContext };
