import type { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { IconHistory } from "@/domains/icons";
import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { EmptyState } from "@/shared/ui";
import { useAllAccountsQuery } from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";

import { TransferContainer } from "./transfer.container";

const TransferPage: FC = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const accountId = searchParams.get("accountId");

  const { data: accounts } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    sorting: TradingAccountSorting.Newest,
    status: AvailabilityStatus.Active,
  });
  const { data: bonusData } = useBonusesQuery();

  if (!accounts || !bonusData) {
    return <CabinetPage.Loader />;
  }
  const selectedAccount = accounts.find(account => account.id === accountId);
  const accountsWithSameCurrency = accounts.filter(account => account.currency === selectedAccount?.currency);

  return (
    <CabinetPage
      size="sm"
      header={
        <CabinetHeader backButton={from && <CabinetHeader.BackLink to={from} />}>
          <CabinetHeader.Title>{t("cabinet.navigation.transfer")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      {accountsWithSameCurrency.length < 2 ? (
        <EmptyState title={t("transfer.no-items.title")} icon={<IconHistory />} />
      ) : (
        <TransferContainer
          accounts={accountsWithSameCurrency}
          bonuses={bonusData.items!}
          accountId={accountId}
          from={from}
        />
      )}
    </CabinetPage>
  );
};

export { TransferPage };
