import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { IconBill, IconChevronRight } from "@/domains/icons";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { InfoBlock, TextButton } from "@/shared/ui";

type Props = {
  className?: string;
  amount: number;
  currency: string;
  decimalScale: number;
};

const SourceOfFundsBanner: FC<Props> = ({ className, amount, currency, decimalScale }) => {
  const { t } = useTranslation();

  return (
    <InfoBlock
      className={className}
      icon={<IconBill />}
      variant="warning"
      text={
        <Trans
          i18nKey="cabinet.source-of-funds.banner-text"
          components={{
            amount: <NumberFormat value={amount} currency={currency} decimalScale={decimalScale} />,
          }}
        />
      }
    >
      <TextButton icon={<IconChevronRight />} asChild>
        <Link to={onboardingRoutes.kyc}>{t("cabinet.source-of-funds.banner-button")}</Link>
      </TextButton>
    </InfoBlock>
  );
};

export { SourceOfFundsBanner };
