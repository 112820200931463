import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { DataList, Text } from "@/shared/ui";

const WithdrawalInfo: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <Text align="center" variant="S / Regular" color="secondary">
        {t("bonus.dialog.terms.withdrawal.description")}
      </Text>
      <div className="flex flex-col gap-6">
        <div className="rounded-xl border border-card-border bg-card-bg-accent px-6 py-5">
          <DataList>
            <DataList.Item
              label={<DataList.Label>{t("bonus.dialog.terms.withdrawal.available-balance")}</DataList.Label>}
              value={<DataList.Value>$1000</DataList.Value>}
            />
            <DataList.Item
              label={<DataList.Label>{t("bonus.dialog.terms.withdrawal.bonus-balance")}</DataList.Label>}
              value={<DataList.Value>$500</DataList.Value>}
            />
            <DataList.Item
              label={<DataList.Label>{t("bonus.dialog.terms.withdrawal.withdrawal-amount")}</DataList.Label>}
              value={
                <DataList.Value>
                  <Trans
                    i18nKey="bonus.dialog.terms.withdrawal.withdrawal-amount-value"
                    values={{ symbol: "$", amount: 200, percentage: 20 }}
                    components={{ colorText: <span className="text-positive-text" /> }}
                  />
                </DataList.Value>
              }
            />
            <DataList.Item
              label={<DataList.Label>{t("bonus.dialog.terms.withdrawal.bonus-reduced")}</DataList.Label>}
              value={
                <DataList.Value>
                  <Trans
                    i18nKey="bonus.dialog.terms.withdrawal.bonus-reduced-value"
                    values={{ symbol: "$", amount: 100, percentage: 20 }}
                    components={{ colorText: <span className="text-positive-text" /> }}
                  />
                </DataList.Value>
              }
            />
          </DataList>
        </div>
        <div className="flex flex-col gap-3 rounded-xl border border-card-border bg-card-bg-accent px-6 py-5">
          <Text color="primary" variant="S / Regular">
            {t("bonus.dialog.terms.withdrawal.reduced-info.title")}
          </Text>
          <ul className="flex list-disc flex-col gap-1 ps-4">
            <Text as="li" variant="S / Regular" color="secondary" className="list-item">
              {t("bonus.dialog.terms.withdrawal.reduced-info.info-1")}
            </Text>
            <Text as="li" variant="S / Regular" color="secondary" className="list-item">
              {t("bonus.dialog.terms.withdrawal.reduced-info.info-2")}
            </Text>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { WithdrawalInfo };
