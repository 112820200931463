import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { type ElementRef, forwardRef, useCallback, useRef } from "react";
import { PatternFormat, type PatternFormatProps } from "react-number-format";

import { createImagePathUrl } from "@/app/utils/images/helpers";
import { IconChevronDown } from "@/domains/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { PlatformCountry } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Image, Popover, popoverContentStyles } from "@/shared/ui";
import { _Button, type _ExternalWrapperProps, _Input, _Wrapper } from "@/shared/ui/inputs/_base";

import { PhoneCombobox } from "./combobox";

type Props = Pick<_ExternalWrapperProps, "label" | "descriptor" | "invalid" | "pending" | "disabled"> &
  OverrideProps<
    Pick<PatternFormatProps, "onBlur" | "name">,
    {
      value: string;
      onChange: (value: string) => void;
      codeValue: string;
      onChangeCode: (value: string) => void;
      countryValue: string;
      onChangeCountry: (value: string) => void;
      countries: PlatformCountry[];
      selectedCountry: PlatformCountry | undefined;
    }
  >;

const PhoneInput = forwardRef<ElementRef<"input">, Props>(
  (
    {
      value,
      onChange,
      label,
      disabled,
      invalid,
      pending,
      descriptor,
      countries,
      countryValue,
      codeValue,
      onChangeCode,
      onChangeCountry,
      selectedCountry,
      ...props
    },
    forwardedRef,
  ) => {
    const [opened, { onOpenChange, close }] = useDisclosure();

    const ref = useRef<HTMLInputElement>(null);
    const composedRefs = useComposedRefs(ref, forwardedRef);

    const clearPhoneNumber = useCallback(() => {
      onChange("");
      ref.current?.focus();
    }, [onChange]);

    return (
      <Popover open={opened} onOpenChange={onOpenChange}>
        <Popover.Anchor asChild>
          <_Wrapper
            type="input"
            startSection={
              selectedCountry && <Image src={createImagePathUrl(selectedCountry.flagUrlPath!)} width={24} height={24} />
            }
            endSection={
              <Popover.Trigger asChild>
                <_Button>
                  <IconChevronDown />
                </_Button>
              </Popover.Trigger>
            }
            invalid={invalid}
            pending={pending}
            label={label}
            disabled={disabled}
            descriptor={descriptor}
          >
            <PatternFormat
              value={value}
              onValueChange={({ value }) => {
                onChange(value);
              }}
              customInput={_Input}
              format={selectedCountry ? `${codeValue} ${selectedCountry.mask}` : ""}
              allowEmptyFormatting
              disabled={disabled!}
              getInputRef={composedRefs}
              {...props}
            />
          </_Wrapper>
        </Popover.Anchor>
        <Popover.Portal>
          <Popover.PrimitiveContent
            sideOffset={9}
            className={cn(
              popoverContentStyles(),
              "flex max-h-[360px] w-[var(--radix-popover-trigger-width)] flex-col gap-4 overflow-hidden rounded-xl bg-surface-elevation-2 shadow-modal outline-none",
            )}
          >
            <PhoneCombobox
              onClose={close}
              countryValue={countryValue}
              onChangeCode={onChangeCode}
              onChangeCountry={onChangeCountry}
              clearPhoneNumber={clearPhoneNumber}
              countries={countries}
              selectedCountry={selectedCountry}
            />
          </Popover.PrimitiveContent>
        </Popover.Portal>
      </Popover>
    );
  },
);

export { PhoneInput };
