import Big from "big.js";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { IconArrowSort } from "@/domains/icons";
import { type BonusUserPlatform, type TradingAccount } from "@/services/openapi";
import { IconButton, NumberInput } from "@/shared/ui";

import { getInputNumberValue } from "../terminal/helpers/formatting";
import { TransferBonusBlock } from "./bonus-block";
import { TransferSelect } from "./select";

enum Fields {
  SOURCE_ID = "sourceId",
  DESTINATION_ID = "destinationId",
  AMOUNT = "amount",
}

type FormValues = {
  [Fields.SOURCE_ID]: string;
  [Fields.DESTINATION_ID]: string;
  [Fields.AMOUNT]: string;
};

type Props = {
  accounts: TradingAccount[];
  bonus: BonusUserPlatform[];
  accountId: string | null;
  onSubmit: (values: FormValues) => void;
};

const TransferForm: FC<Props> = ({ accounts, bonus, accountId, onSubmit }) => {
  const { t } = useTranslation();

  const [{ defaultSourceId, defaultDestinationId }] = useState<{
    defaultSourceId: string;
    defaultDestinationId: string;
  }>(() => {
    const sourceAccount = accounts.find(({ id }) => id === accountId);

    if (sourceAccount) {
      const restAccounts = accounts.filter(({ id }) => id !== sourceAccount.id);

      return { defaultSourceId: sourceAccount.id!, defaultDestinationId: restAccounts[0]!.id! };
    }

    // could not be less than two accounts
    return { defaultSourceId: accounts[0]!.id!, defaultDestinationId: accounts[1]!.id! };
  });

  const form = useHookForm<FormValues>({
    mode: "all",
    defaultValues: {
      [Fields.SOURCE_ID]: defaultSourceId,
      [Fields.DESTINATION_ID]: defaultDestinationId,
      [Fields.AMOUNT]: "",
    },
  });
  const { control, resetField } = form;

  const [sourceIdField] = useControlledField({
    name: Fields.SOURCE_ID,
    control,
  });

  const [destinationIdField] = useControlledField({
    name: Fields.DESTINATION_ID,
    control,
  });

  const sourceAccount = useMemo(
    () => accounts.find(({ id }) => id === sourceIdField.value),
    [accounts, sourceIdField.value],
  );
  const destinationAccount = useMemo(
    () => accounts.find(({ id }) => id === destinationIdField.value),
    [accounts, destinationIdField.value],
  );

  const [amountField, { invalid: amountInvalid, errorMessage: amountErrorMessage, pending: amountPending }] =
    useControlledField<FormValues>({
      name: Fields.AMOUNT,
      control,
      rules: {
        required: t("form-errors.required-error")!,
        validate: (value: string) => getInputNumberValue(value) !== 0 || t("form-errors.greater-than", { value: 0 })!,
        max: {
          value: sourceAccount?.balance || 0,
          message: t("form-errors.low-funds")!,
        },
      },
    });

  const amountValue = useMemo(() => getInputNumberValue(amountField.value), [amountField.value]);

  const bonusBalance = useMemo(() => {
    if (!sourceAccount || !sourceAccount.availableToWithdraw) {
      return 0;
    }

    const credit = new Big(sourceAccount.credit || 0);
    const amount = new Big(amountValue || 0);
    const availableToWithdraw = new Big(sourceAccount.availableToWithdraw);

    const result = credit
      .minus(amount.div(availableToWithdraw).times(credit))
      .round(sourceAccount.digits, 0)
      .toNumber();

    return Math.max(result, 0);
  }, [amountValue, sourceAccount]);

  const sourceIdOnChange = useCallback(
    (newValue: string) => {
      if (newValue === destinationAccount?.id) {
        destinationIdField.onChange(sourceAccount?.id);
      }
      sourceIdField.onChange(newValue);
      resetField(Fields.AMOUNT);
    },
    [sourceIdField, destinationAccount, destinationIdField, sourceAccount, resetField],
  );
  const destinationIdOnChange = useCallback(
    (newValue: string) => {
      if (newValue === sourceAccount?.id) {
        sourceIdField.onChange(destinationAccount?.id);
      }
      destinationIdField.onChange(newValue);
    },
    [destinationIdField, sourceIdField, sourceAccount, destinationAccount],
  );

  const onSwapSides = useCallback(() => {
    sourceIdField.onChange(destinationAccount?.id);
    destinationIdField.onChange(sourceAccount?.id);

    resetField(Fields.AMOUNT);
  }, [destinationAccount?.id, destinationIdField, resetField, sourceAccount?.id, sourceIdField]);

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col rounded-lg">
          <TransferSelect
            accounts={accounts}
            value={sourceIdField.value}
            onValueChange={sourceIdOnChange}
            label={t("transfer.from-account")}
            position="top"
          />

          <div className="relative h-px w-full bg-surface-canvas">
            <div className="absolute start-1/2 -translate-x-1/2 -translate-y-1/2">
              <IconButton size="sm" variant="secondary" onClick={onSwapSides}>
                <IconArrowSort />
              </IconButton>
            </div>
          </div>

          <TransferSelect
            accounts={accounts}
            value={destinationIdField.value}
            onValueChange={destinationIdOnChange}
            label={t("transfer.to-account")}
            position="bottom"
          />
        </div>

        {sourceAccount && (
          <NumberInput
            decimalScale={sourceAccount.digits}
            pending={amountPending}
            invalid={amountInvalid}
            currency={sourceAccount.currency!}
            label={t("transfer.to-be-transfered")}
            descriptor={amountErrorMessage}
            {...amountField}
          />
        )}

        {sourceAccount && !!sourceAccount.credit && bonus[0] && (
          <TransferBonusBlock account={sourceAccount} bonus={bonus[0]} bonusBalance={bonusBalance} />
        )}

        <SubmitButton fullWidth>{t("button.transfer")}</SubmitButton>
      </div>
    </HookForm>
  );
};

export { TransferForm };
