import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { IconMinus, IconPlus } from "@/domains/icons";

import {
  _Button,
  _DescriptorButton,
  type _ExternalNumericInputProps,
  type _ExternalWrapperProps,
  _NumericInput,
  _Wrapper,
} from "../_base";

type Props = Omit<_ExternalWrapperProps, "endSection" | "startSection" | "label" | "floatingLabel" | "hasInputValue"> &
  _ExternalNumericInputProps & {
    value: string;
    onChange: (value: string) => void;
    onIncrement?: ComponentPropsWithoutRef<typeof _Button>["onClick"];
    onDecrement?: ComponentPropsWithoutRef<typeof _Button>["onClick"];
    plusDisabled?: boolean;
    minusDisabled?: boolean;
  };

const StepperInput = forwardRef<ElementRef<"input">, Props>(
  (
    {
      className,
      onIncrement,
      onDecrement,
      descriptor,
      invalid,
      plusDisabled,
      minusDisabled,
      disabled,
      pending,
      onChange,
      autoFocus,
      size,
      ...props
    },
    ref,
  ) => {
    return (
      <_Wrapper
        type="input"
        className={className}
        descriptor={descriptor}
        invalid={invalid}
        disabled={disabled}
        pending={pending}
        size={size}
        startSection={
          <_Button disabled={minusDisabled || disabled} onClick={onDecrement}>
            <IconMinus />
          </_Button>
        }
        endSection={
          <_Button disabled={plusDisabled || disabled} onClick={onIncrement}>
            <IconPlus />
          </_Button>
        }
      >
        <_NumericInput
          className="typography-S-Regular h-full w-full bg-transparent text-center text-contrast-primary outline-none placeholder:text-contrast-tertiary group-data-[disabled=true]/input-wrapper:text-contrast-tertiary"
          disabled={disabled}
          onValueChange={({ value }) => onChange(value)}
          autoFocus={autoFocus}
          data-autofocus={autoFocus}
          ref={ref}
          {...props}
        />
      </_Wrapper>
    );
  },
);

const Component = Object.assign(StepperInput, {
  DescriptorButton: _DescriptorButton,
});

export { Component as StepperInput };
