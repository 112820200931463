import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Dialog } from "@/shared/ui";

type Props = {
  openBonusDialog: () => void;
};

const BonusDialogContent: FC<Props> = ({ openBonusDialog }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header title={t("transfer.bonus.text")} />
      <Dialog.Description align="start">{t("transfer.bonus.description")}</Dialog.Description>
      <Dialog.Buttons>
        <Button onClick={openBonusDialog}>{t("transfer.bonus.button")}</Button>
      </Dialog.Buttons>
    </>
  );
};

export { BonusDialogContent };
