import type { ComponentProps, FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import type { AddressInfo, ProfileInfo } from "@/services/openapi";
import { useUpdateProfileMutation } from "@/state/server/kyc";
import { usePlatformCountriesQuery } from "@/state/server/platform";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

import { StepWrapper } from "../step-wrapper";
import { ProfileForm } from "./profile.form";

type Props = {
  profile: ProfileInfo;
  address: AddressInfo;
  onNext: () => void;
};

const ProfileStep: FC<Props> = ({ profile, address, onNext }) => {
  const { t } = useTranslation();

  const platformInfo = usePlatformData();
  const { data: countriesInfo } = usePlatformCountriesQuery();

  const { mutateAsync: updateProfile } = useUpdateProfileMutation();

  const onSubmit: ComponentProps<typeof ProfileForm>["onSubmit"] = useCallback(
    ({ country, dateOfBirth, firstName, isNotUSAResident, lastName, phoneCode, phoneCountry, phoneNumber }) => {
      return updateProfile(
        {
          profileUpdate: {
            country,
            dateOfBirth: dateOfBirth ? dateOfBirth.toDate("Etc/UTC").toISOString() : void 0,
            firstName,
            isNotUSAResident,
            lastName,
            phoneCode,
            phoneCountry,
            phoneNumber,
          },
        },
        {
          onSuccess: () => {
            onNext();
          },
        },
      );
    },
    [onNext, updateProfile],
  );

  if (!countriesInfo) {
    return null;
  }

  return (
    <StepWrapper title={t("onboarding.profile.title")} subtitle={t("onboarding.profile.subtitle")!}>
      <ProfileForm
        profile={profile}
        address={address}
        countriesInfo={countriesInfo}
        onSubmit={onSubmit}
        platformInfo={platformInfo}
      />
    </StepWrapper>
  );
};

export { ProfileStep };
