import { createSearchParams, type To } from "react-router-dom";

import { TerminalTableState } from "@/features/terminal/layout/context";

const terminalRoutes = {
  terminal: "/web-trading/doto",
  terminalMobile: "/web-trading/doto-mobile",
  terminalMobileChart: "/web-trading/doto-mobile-chart",
};

const terminalRoutesArray = Object.values(terminalRoutes);
const isCurrentPageTerminal = (pathname: string): boolean => terminalRoutesArray.includes(pathname);

const terminalAccountParamsName = "accountId";

type TerminalParams = {
  [terminalAccountParamsName]?: string;
  tab?: TerminalTableState;
  symbol?: string;
};

const getTerminalRoute = (
  accountId: string | null,
  { tab, symbol }: { tab?: TerminalTableState; symbol?: string } = {},
): To => {
  const params: TerminalParams = {
    tab: tab ?? TerminalTableState.NONE,
  };

  if (accountId) {
    params[terminalAccountParamsName] = accountId;
  }

  if (symbol) {
    params.symbol = symbol;
  }

  return {
    pathname: terminalRoutes.terminal,
    search: createSearchParams(params).toString(),
  };
};

export { getTerminalRoute, terminalAccountParamsName, terminalRoutes, isCurrentPageTerminal };
