import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { authRoutes } from "@/routes/auth.routes";
import { Button, EmailInput, Text } from "@/shared/ui";

enum Fields {
  EMAIL = "email",
}

type FormValues = {
  [Fields.EMAIL]: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
};

const ForgotPasswordForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.EMAIL]: "",
    },
  });

  const { control } = form;

  const [emailField, { invalid: emailInvalid, pending: pendingEmail, errorMessage: errorMessageEmail }] =
    useControlledField({
      name: Fields.EMAIL,
      control,
      rules: {
        required: t("auth.errors.email-required")!,
      },
    });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Text color="primary" variant="S / Regular" align="center" className="mb-6">
        {t("auth.forgot-password.description")}
      </Text>
      <EmailInput
        label={t("auth.common.email")}
        pending={pendingEmail}
        invalid={emailInvalid}
        descriptor={errorMessageEmail}
        {...emailField}
      />
      <div className="mt-8 flex flex-col gap-2">
        <SubmitButton fullWidth>{t("auth.forgot-password.action")}</SubmitButton>
        <Button variant="flat" fullWidth asChild>
          <Link to={authRoutes.signIn}>{t("auth.forgot-password.login-action")}</Link>
        </Button>
      </div>
    </HookForm>
  );
};

export { ForgotPasswordForm };
