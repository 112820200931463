import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { appLinks } from "@/app/config/links";
import type { BonusUserPlatform } from "@/services/openapi";
import { ExternalLink, Text, ToggleGroup } from "@/shared/ui";

import { DepositInfo } from "./deposit-info";
import { WithdrawalInfo } from "./withdrawal-info";

enum Terms {
  WITHDRAWAL = "withdrawal",
  DEPOSIT = "deposit",
}

type Props = {
  bonus: BonusUserPlatform;
};

const TermsBlock: FC<Props> = ({ bonus }) => {
  const { t } = useTranslation();

  const [term, setTerm] = useState<Terms>(Terms.DEPOSIT);

  return (
    <div className="flex flex-col gap-4">
      <Text as="h3" color="primary" variant="L / Medium" align="center">
        {t("bonus.dialog.terms.title")}
      </Text>
      <div className="flex flex-col gap-4">
        <ToggleGroup value={term} onValueChange={setTerm}>
          <ToggleGroup.Item value={Terms.DEPOSIT}>{t("cabinet.navigation.deposit")}</ToggleGroup.Item>
          <ToggleGroup.Item value={Terms.WITHDRAWAL}>{t("cabinet.navigation.withdrawal")}</ToggleGroup.Item>
        </ToggleGroup>
        {term === Terms.DEPOSIT && <DepositInfo bonus={bonus} />}
        {term === Terms.WITHDRAWAL && <WithdrawalInfo />}
      </div>

      <Text asChild align="center" color="primary" variant="S / Medium" className="underline">
        <ExternalLink href={appLinks.bonusesTerms}>{t("bonus.dialog.terms.link")}</ExternalLink>
      </Text>
    </div>
  );
};

export { TermsBlock };
