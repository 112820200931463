import type { FC } from "react";
import { memo } from "react";
import { createSearchParams, Link } from "react-router-dom";

import { useLocationPath } from "@/app/hooks";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { useTranslation } from "@/hooks/translator.hook";
import { getDepositRoute } from "@/routes/cabinet.routes";
import { Button } from "@/shared/ui";

type Props = {
  accountId: string;
};

const DepositButton: FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();
  const path = useLocationPath();

  return (
    <Button onClick={() => track(amplitudeEvents.deposit.fromTerminal)} size="sm" asChild>
      <Link to={{ pathname: getDepositRoute(accountId), search: createSearchParams({ from: path }).toString() }}>
        {t("button.deposit")}
      </Link>
    </Button>
  );
};

const Component = memo(DepositButton);

export { Component as DepositButton };
