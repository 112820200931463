import type { FC } from "react";
import { useTranslation } from "react-i18next";

import img from "@/assets/images/verify-white.png";
import { Button, Dialog, Image, Text } from "@/shared/ui";

type Props = {
  onContinue: () => void;
  onExplore: () => void;
};

const DialogContainer: FC<Props> = ({ onExplore, onContinue }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        centered
        title={t("onboarding.dialog.title")}
        image={
          <Dialog.Image size="lg">
            <Image src={img} />
          </Dialog.Image>
        }
      />

      <Dialog.Description asChild>
        <Text variant="S / Regular" color="primary" align="center">
          {t("onboarding.dialog.description")}
        </Text>
      </Dialog.Description>

      <Dialog.Buttons direction="column">
        <Button variant="primary" onClick={onContinue}>
          {t("onboarding.dialog.continue-button")}
        </Button>
        <Button variant="secondary" onClick={onExplore}>
          {t("onboarding.dialog.explore-button")}
        </Button>
      </Dialog.Buttons>
    </>
  );
};

export default DialogContainer;
