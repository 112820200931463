import type { FC } from "react";
import { useTranslation } from "react-i18next";

import negativeImg from "@/assets/images/negative.png";
import { Button, Dialog, Image } from "@/shared/ui";

const Component: FC = () => {
  const { t } = useTranslation();

  return (
    <Dialog.Content>
      <Dialog.Header
        title={t("onboarding.country-dialog.title")}
        centered
        image={
          <Dialog.Image size="md">
            <Image src={negativeImg} alt="" />
          </Dialog.Image>
        }
      />
      <Dialog.Description>{t("onboarding.country-dialog.text")}</Dialog.Description>
      <Dialog.Buttons>
        <Dialog.Close asChild>
          <Button>{t("button.got-it")}</Button>
        </Dialog.Close>
      </Dialog.Buttons>
    </Dialog.Content>
  );
};

export { Component as InvalidCountryDialog };
