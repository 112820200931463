import type { FC } from "react";
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import { createImagePathUrl } from "@/app/utils/images/helpers";
import { useAuth } from "@/contexts/auth.context";
import {
  IconChevronRight,
  IconKey,
  IconMoreVertical,
  IconNotification,
  IconShield,
  IconUser,
  IconVerified,
} from "@/domains/icons";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { ExternalKycStatus, type KycInfo, type ProfileHeader } from "@/services/openapi";
import { ActionList, Button, Dialog, IconButton, Image, InfoBlock, Label } from "@/shared/ui";

import { CompleteVerificationBanner } from "../../cabinet/components/banners/CompleteVerificationBanner";
import { LanguageSwitcherContainer as LanguageSwitcherDialogContainer } from "../../language-switcher";
import { LanguageSwitcherContainer } from "../../language-switcher/container";
import { ChangePasswordVerifyCodeContainer } from "../change-password/verify/code/container";
import { ChangePasswordVerify2FAContainer } from "../change-password/verify/two-factor/container";
import type { ChangePasswordInfo, ProfileStep } from "../page";
import { ProfileInfoBlock } from "./info-block";
import { VerifyEmailButton } from "./verify-email/button";

type Props = {
  kyc: KycInfo;
  profile: ProfileHeader;
  setStep: (step: ProfileStep) => void;
  setChangePasswordData: (data: ChangePasswordInfo) => void;
};

const ProfileSettingsContainer: FC<Props> = ({ kyc, profile: profileProp, setStep, setChangePasswordData }) => {
  const { t } = useTranslation();

  const { options, email, kycStatus } = profileProp;

  const verificationRoute = getVerificationRoute(options!.isSurveyCompleted!);

  const [languageSwitcherOpened, { open: openLanguageSwitcher, onOpenChange: onOpenChangeLanguageSwitcher }] =
    useDisclosure();

  const navigate = useNavigate();
  const { logout } = useAuth();

  const signOut = useCallback(() => {
    logout(navigate);
  }, [navigate, logout]);

  const { profile } = kyc;

  return (
    <CabinetPage
      header={
        <CabinetHeader>
          <div className="flex items-center gap-3">
            <CabinetHeader.Title>
              {profile!.firstName && profile!.lastName
                ? `${profile!.firstName} ${profile!.lastName}`
                : t("profile-settings.title")}
            </CabinetHeader.Title>
            {options!.isKycCompleted && <IconVerified className="size-6 text-positive-text" />}
            {kycStatus === ExternalKycStatus.Pending && (
              <Label variant="warning">{t("profile-settings.verification.in-progress")}</Label>
            )}
          </div>
          <IconButton size="sm" variant="flat" asChild>
            <Link to={cabinetRoutes.notifications}>
              <IconNotification />
            </Link>
          </IconButton>
        </CabinetHeader>
      }
      banner={
        !options!.isKycCompleted && (
          <CabinetPage.Banner>
            <CompleteVerificationBanner kycStatus={kycStatus!} isSurveyCompleted={options!.isSurveyCompleted!} />
          </CabinetPage.Banner>
        )
      }
    >
      <div className="flex flex-col gap-4">
        {kycStatus === ExternalKycStatus.Rejected && (
          <InfoBlock variant="warning" icon={<IconUser />} text={t("profile-settings.verification.info-block.text")}>
            <InfoBlock.Action icon={<IconChevronRight />} asChild>
              <Link to={verificationRoute}>{t("profile-settings.verification.info-block.button")}</Link>
            </InfoBlock.Action>
          </InfoBlock>
        )}

        <ProfileInfoBlock kyc={kyc} profile={profileProp} />

        <ActionList>
          {!options!.isEmailConfirmed && <VerifyEmailButton email={email!} />}
          <Dialog>
            <Dialog.Trigger asChild>
              <ActionList.Item
                title={t("profile-settings.details.change-password")!}
                icon={<IconKey />}
                actionIcon={<IconChevronRight />}
              />
            </Dialog.Trigger>
            {options!.is2FaEnabled ? (
              <Dialog.Content>
                <ChangePasswordVerify2FAContainer setStep={setStep} setChangePasswordData={setChangePasswordData} />
              </Dialog.Content>
            ) : (
              <ChangePasswordVerifyCodeContainer
                email={email!}
                setStep={setStep}
                setChangePasswordData={setChangePasswordData}
              />
            )}
          </Dialog>
          <ActionList.Item
            title={t("profile-settings.details.two-factor")!}
            icon={<IconShield />}
            actionIcon={<IconChevronRight />}
            asChild
          >
            <Link to={cabinetRoutes.twoFactor} />
          </ActionList.Item>
        </ActionList>

        <LanguageSwitcherContainer>
          {({ iconUrlPath, name }) => (
            <ActionList>
              <ActionList.Item
                onClick={openLanguageSwitcher}
                title={t("profile-settings.details.language")!}
                description={name}
                icon={<Image src={createImagePathUrl(iconUrlPath!)} alt={name!} />}
                actionIcon={<IconMoreVertical />}
              />
            </ActionList>
          )}
        </LanguageSwitcherContainer>

        <LanguageSwitcherDialogContainer onOpenChange={onOpenChangeLanguageSwitcher} open={languageSwitcherOpened} />
      </div>
      <Button className="mt-6" color="negative" size="sm" onClick={signOut}>
        {t("profile-settings.log-out-button")}
      </Button>
    </CabinetPage>
  );
};

export { ProfileSettingsContainer };
