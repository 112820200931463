import { type ElementRef, forwardRef } from "react";
import type { DateFieldProps, DateValue } from "react-aria-components";
import { DateField, DateInput as LibDateInput, DateSegment } from "react-aria-components";

import { type _ExternalWrapperProps, _Wrapper } from "../_base";

type Props = OverrideProps<
  Omit<DateFieldProps<DateValue>, "isDisabled" | "isInvalid">,
  Omit<_ExternalWrapperProps, "hasInputValue" | "floatingLabel">
>;

const DateInput = forwardRef<ElementRef<typeof DateSegment>, Props>(
  ({ className, label, startSection, disabled, invalid, pending, descriptor, endSection, size, ...props }, ref) => {
    return (
      <DateField
        className={className}
        isDisabled={disabled!}
        isInvalid={invalid!}
        granularity="day"
        validationBehavior="aria"
        hideTimeZone
        {...props}
      >
        <_Wrapper
          type="date"
          startSection={startSection}
          invalid={invalid}
          pending={pending}
          label={label}
          disabled={disabled}
          descriptor={descriptor}
          endSection={endSection}
          size={size}
          floatingLabel={false}
        >
          <LibDateInput className="flex items-center uppercase">
            {segment => (
              <DateSegment
                className="typography-S-Regular rounded-2xs p-px tabular-nums text-contrast-primary focus:bg-accent-bg focus:!text-accent-over focus:caret-transparent focus:outline-none data-[disabled]:text-contrast-tertiary data-[placeholder]:text-contrast-tertiary"
                data-disabled={disabled}
                segment={segment}
                // FIXME: it is the only way to pass ref to the component for correct focusing
                ref={segment.type === "day" ? ref : void 0}
              />
            )}
          </LibDateInput>
        </_Wrapper>
      </DateField>
    );
  },
);

export { DateInput };
