import { type FC } from "react";
import { Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { createImagePathUrl } from "@/app/utils/images/helpers";
import { usePriceChange } from "@/features/terminal/symbols/helpers";
import { SymbolIcon } from "@/features/terminal/symbols/icon";
import { SymbolPriceChange } from "@/features/terminal/symbols/price-change";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { Image, Text } from "@/shared/ui";

type Props = {
  symbol: string;
  symbolUrlPath: string;
  name: string;
  priceBid: number;
  priceLast: number | null;
  priceDecimalScale: number;
  chartUrlPath: string | null;
};

const TomMoversItem: FC<Props> = ({
  symbol,
  symbolUrlPath,
  priceLast,
  name,
  priceBid,
  priceDecimalScale,
  chartUrlPath,
}) => {
  const priceChange = usePriceChange({ bid: priceBid, priceLast });

  return (
    <Link
      to={getTerminalRoute(null, { symbol })}
      onClick={() => {
        track(amplitudeEvents.home.topMovers.open, {
          asset: symbol,
        });
      }}
      className="group flex w-full items-center bg-card-bg outline-none transition-colors first:rounded-t-lg last:rounded-b-lg hover:bg-card-bg-hover focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-accent-text active:bg-card-bg-active"
    >
      <div className="pe-3 ps-4">
        <SymbolIcon symbol={symbol} symbolUrlPath={symbolUrlPath} size="xl" />
      </div>
      <div className="relative flex grow items-center justify-between gap-2 pb-4 pe-4 pt-4">
        <div className="flex flex-col gap-0.5">
          <Text variant="M / Medium" color="primary">
            {name}
          </Text>
          <Text variant="S / Regular" color="secondary">
            {symbol}
          </Text>
        </div>
        <div className="flex items-center gap-1.5">
          {chartUrlPath && <Image src={createImagePathUrl(chartUrlPath)} className="hidden h-9 w-[160px] md:block" />}

          <div className="flex min-w-[84px] flex-col items-end gap-0.5">
            <Text variant="M / Medium" color="primary">
              <NumberFormat value={priceBid} decimalScale={priceDecimalScale} />
            </Text>
            {priceChange && (
              <SymbolPriceChange priceChange={priceChange}>
                {({ value, icon }) => (
                  <div className="flex items-center">
                    {icon}
                    <Text color={priceChange.color || "secondary"} variant="S / Regular">
                      {value}
                    </Text>
                  </div>
                )}
              </SymbolPriceChange>
            )}
          </div>
        </div>

        <div className="absolute bottom-0 h-px w-full bg-contrast-quinary group-last:hidden group-hover:hidden group-focus-visible:hidden group-active:hidden" />
      </div>
    </Link>
  );
};

export { TomMoversItem };
