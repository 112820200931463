import type { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, useHookForm } from "@/app/form";
import { SourceOfFundsBanner } from "@/features/terminal/place-order/source-of-funds-banner";
import { cn } from "@/shared/styles";

import { usePlaceOrderContext } from "./context";
import { PlaceOrderDetails } from "./details";
import { DirectionButtons } from "./direction-buttons";
import { OpenPriceField } from "./open-price/field";
import { PlaceOrderSignalsBlock } from "./signal/block";
import { PlaceOrderSlider } from "./slider";
import { StopLossField } from "./stop-loss/field";
import { PlaceOrderSignalSubmitButton, PlaceOrderSubmitButton } from "./submit-button";
import { TakeProfitField } from "./take-profit/field";
import { PlaceOrderFieldBlock } from "./ui/field-block/block";
import { VolumeFieldsBlock } from "./volume/fields-block";

type Props = Omit<
  ComponentProps<typeof PlaceOrderSubmitButton> &
    ComponentProps<typeof OpenPriceField> &
    ComponentProps<typeof TakeProfitField> &
    ComponentProps<typeof StopLossField> &
    ComponentProps<typeof PlaceOrderDetails> &
    ComponentProps<typeof VolumeFieldsBlock> &
    ComponentProps<typeof PlaceOrderSlider> &
    Pick<ComponentProps<typeof DirectionButtons>, "ask" | "bid" | "direction" | "changeDirection"> & {
      changeIsPendingOrder: ReturnType<typeof usePlaceOrderContext>["changeIsPendingOrder"];
      takeProfitEnabled: ReturnType<typeof usePlaceOrderContext>["takeProfitEnabled"];
      changeTakeProfitEnabled: ReturnType<typeof usePlaceOrderContext>["changeTakeProfitEnabled"];
      stopLossEnabled: ReturnType<typeof usePlaceOrderContext>["stopLossEnabled"];
      changeStopLossEnabled: ReturnType<typeof usePlaceOrderContext>["changeStopLossEnabled"];
      isSignal: ReturnType<typeof usePlaceOrderContext>["isSignal"];
      onSubmit: () => Promise<unknown>;
      onDeposit: () => void;
      isMobile: boolean;
    },
  "isSubmitting" | "className"
>;

const PlaceOrderForm: FC<Props> = ({
  isPendingOrder,
  changeIsPendingOrder,
  direction,
  changeDirection,
  openPriceOnBlur,
  changeOpenPrice,
  openPriceFormValue,
  marginRateInitialMarketBuy,
  maxBalanceVolumeLots,
  minSystemVolumeLots,
  maxSystemVolumeLots,
  changeLots,
  changeMargin,
  volumeMode,
  volumeLots,
  volumeMargin,
  onSubmit,
  leverage,
  ask,
  bid,
  priceDecimalScale,
  baseCurrency,
  contractSize,
  maxBalanceVolumeMargin,
  maxSystemVolumeMargin,
  minSystemVolumeMargin,
  hasNoFreeMargin,
  volumeLotsError,
  volumeMarginDecimalScale,
  volumeLotsDecimalScale,
  instrumentType,
  quoteCurrency,
  symbols,
  changeVolumeMode,
  volumeLotsFormValue,
  volumeMarginFormValue,
  isDemoAccount,
  currentPrice,
  realTimeMargin,
  isBuyOrder,
  onDeposit,
  changeStopLossEnabled,
  changeTakeProfitEnabled,
  stopLossEnabled,
  takeProfitEnabled,
  changeStopLoss,
  changeTakeProfit,
  stopLossFormValue,
  takeProfitFormValue,
  currency,
  takeProfitError,
  stopLossError,
  takeProfit,
  takeProfitThresholdValue,
  stopLoss,
  stopLossThresholdValue,
  changeSliderValue,
  sliderValue,
  onSliderCommit,
  sliderDisabled,
  maxAvailableVolumeLots,
  maxAvailableVolumeMargin,
  volumeOnBlur,
  isMobile,
  isSignal,
  blockedFunds,
}) => {
  const { t } = useTranslation();

  const form = useHookForm();

  const { formState } = form;
  const { isSubmitting } = formState;

  const isCardDimmed = isMobile && isSignal;

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <div className="px-4">
        {hasNoFreeMargin && !!blockedFunds && (
          <div className="mb-2">
            <SourceOfFundsBanner />
          </div>
        )}

        {!isSignal && (
          <DirectionButtons
            className="mb-2"
            ask={ask}
            bid={bid}
            priceDecimalScale={priceDecimalScale}
            direction={direction}
            changeDirection={changeDirection}
          />
        )}
        <VolumeFieldsBlock
          blockedFunds={blockedFunds}
          volumeOnBlur={volumeOnBlur}
          changeVolumeMode={changeVolumeMode}
          volumeMode={volumeMode}
          currency={currency}
          isSubmitting={isSubmitting}
          volumeLots={volumeLots}
          changeLots={changeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          hasNoFreeMargin={hasNoFreeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          volumeLotsError={volumeLotsError}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
          maxBalanceVolumeMargin={maxBalanceVolumeMargin}
          maxSystemVolumeMargin={maxSystemVolumeMargin}
          minSystemVolumeMargin={minSystemVolumeMargin}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          changeMargin={changeMargin}
          volumeLotsFormValue={volumeLotsFormValue}
          volumeMarginFormValue={volumeMarginFormValue}
          volumeMargin={volumeMargin}
          onDeposit={onDeposit}
        />
        <PlaceOrderSlider
          className="mt-2"
          currency={currency}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          minSystemVolumeLots={minSystemVolumeLots}
          minSystemVolumeMargin={minSystemVolumeMargin}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          maxAvailableVolumeMargin={maxAvailableVolumeMargin}
          volumeMode={volumeMode}
          sliderValue={sliderValue}
          changeSliderValue={changeSliderValue}
          onSliderCommit={onSliderCommit}
          sliderDisabled={sliderDisabled}
        />
        <PlaceOrderDetails
          className="mb-5 mt-3"
          currency={currency}
          baseCurrency={baseCurrency}
          contractSize={contractSize}
          currentPrice={currentPrice}
          instrumentType={instrumentType}
          leverage={leverage}
          marginRateInitialMarketBuy={marginRateInitialMarketBuy}
          quoteCurrency={quoteCurrency}
          symbols={symbols}
          realTimeMargin={realTimeMargin}
          volumeLots={volumeLots}
          volumeLotsError={volumeLotsError}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          isBuyOrder={isBuyOrder}
        />
        <div className="flex flex-col gap-2">
          <PlaceOrderFieldBlock
            label={t("terminal.place-order.take-profit.label")}
            checked={takeProfitEnabled}
            onCheckedChange={changeTakeProfitEnabled}
            dimmed={isCardDimmed}
          >
            <TakeProfitField
              takeProfitThresholdValue={takeProfitThresholdValue}
              baseCurrency={baseCurrency}
              contractSize={contractSize}
              currency={currency}
              currentPrice={currentPrice}
              quoteCurrency={quoteCurrency}
              volumeLots={volumeLots}
              symbols={symbols}
              takeProfit={takeProfit}
              takeProfitError={takeProfitError}
              changeTakeProfit={changeTakeProfit}
              isSubmitting={isSubmitting}
              priceDecimalScale={priceDecimalScale}
              takeProfitFormValue={takeProfitFormValue}
              isBuyOrder={isBuyOrder}
              volumeMarginDecimalScale={volumeMarginDecimalScale}
            />
          </PlaceOrderFieldBlock>
          <PlaceOrderFieldBlock
            label={t("terminal.place-order.stop-loss.label")}
            checked={stopLossEnabled}
            onCheckedChange={changeStopLossEnabled}
            dimmed={isCardDimmed}
          >
            <StopLossField
              stopLossError={stopLossError}
              changeStopLoss={changeStopLoss}
              isSubmitting={isSubmitting}
              priceDecimalScale={priceDecimalScale}
              stopLossFormValue={stopLossFormValue}
              baseCurrency={baseCurrency}
              contractSize={contractSize}
              currency={currency}
              currentPrice={currentPrice}
              isBuyOrder={isBuyOrder}
              quoteCurrency={quoteCurrency}
              symbols={symbols}
              volumeLots={volumeLots}
              volumeMarginDecimalScale={volumeMarginDecimalScale}
              stopLoss={stopLoss}
              stopLossThresholdValue={stopLossThresholdValue}
            />
          </PlaceOrderFieldBlock>
          {!isSignal && (
            <PlaceOrderFieldBlock
              label={t("terminal.place-order.pending.label")}
              checked={isPendingOrder}
              onCheckedChange={changeIsPendingOrder}
              dimmed={isCardDimmed}
            >
              <OpenPriceField
                openPriceFormValue={openPriceFormValue}
                isSubmitting={isSubmitting}
                changeOpenPrice={changeOpenPrice}
                openPriceOnBlur={openPriceOnBlur}
                priceDecimalScale={priceDecimalScale}
              />
            </PlaceOrderFieldBlock>
          )}
        </div>
        {isSignal && <PlaceOrderSignalsBlock className="mt-4" dimmed={isCardDimmed} />}
      </div>
      <div className={cn("sticky bottom-0 mt-4 bg-surface-canvas px-4 pb-4", isCardDimmed && "bg-surface-elevation-1")}>
        {isSignal ? (
          <PlaceOrderSignalSubmitButton
            ask={ask}
            bid={bid}
            priceDecimalScale={priceDecimalScale}
            hasNoFreeMargin={hasNoFreeMargin}
            isBuyOrder={isBuyOrder}
            isDemoAccount={isDemoAccount}
          />
        ) : (
          <PlaceOrderSubmitButton
            hasNoFreeMargin={hasNoFreeMargin}
            isBuyOrder={isBuyOrder}
            isDemoAccount={isDemoAccount}
            isPendingOrder={isPendingOrder}
            blockedFunds={blockedFunds}
          />
        )}
      </div>
    </HookForm>
  );
};

export { PlaceOrderForm };
