import type { FC, ReactNode } from "react";

import { Text } from "@/shared/ui";

type Props = {
  title: ReactNode;
  children: ReactNode;
  image?: ReactNode;
};

const AuthWrapper: FC<Props> = ({ children, title, image }) => {
  return (
    <div className="h-full place-items-center bg-inherit lg:grid lg:py-20">
      <main className="mx-auto w-full max-w-[512px] rounded-xl bg-surface-elevation-1">
        <div className="w-full px-4 py-8 lg:p-14">
          {image && <div className="mx-auto mb-4 grid size-[96px] place-items-center *:size-full">{image}</div>}
          <Text as="h1" align="center" color="primary" variant="XL / Medium" className="mb-6">
            {title}
          </Text>
          {children}
        </div>
      </main>
    </div>
  );
};

export { AuthWrapper };
