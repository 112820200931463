import { createSearchParams, type To } from "react-router-dom";

export const cabinetRoutes = {
  home: "/home",
  myAccounts: "/my-accounts",
  account: "/account/:id",
  createAccount: "/my-accounts/create-account",
  deposit: "/deposit",
  depositOptions: "/deposit/:id",
  withdrawal: "/withdrawal",
  withdrawalOptions: "/withdrawal/:id",
  transfer: "/transfer",
  transactionHistory: "/transaction-history",
  mt4Terminal: "/web-trading/mt4",
  mt5Terminal: "/web-trading/mt5",
  notifications: "/notifications",
  profileSettings: "/profile",
  twoFactor: "/two-factor-settings",
};

const getDepositRoute = (accountId: string): string => `${cabinetRoutes.deposit}/${accountId}`;

const getWithdrawalRoute = (accountId: string): string => `${cabinetRoutes.withdrawal}/${accountId}`;

const getAccountRoute = (accountId: string): string => `/account/${accountId}`;

const getTransferRoute = (accountId: string, from: string): To => ({
  pathname: cabinetRoutes.transfer,
  search: createSearchParams({ accountId, from }).toString(),
});

export { getDepositRoute, getWithdrawalRoute, getAccountRoute, getTransferRoute };
