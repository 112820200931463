import type { FC, ReactNode } from "react";
import { memo, useMemo } from "react";
import { useFormContext, type UseFormTrigger } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import { createImagePathUrl } from "@/app/utils/images/helpers";
import type { CountriesInfo, PlatformCountry } from "@/services/openapi";
import { Dialog, Image, Select } from "@/shared/ui";

import { InvalidCountryDialog } from "./invalid-country-dialog/dialog";
import { ProfileFields } from "./profile.form";

const TransButton: FC<any> = props => (
  <Dialog.Trigger asChild>
    <Select.DescriptorButton {...props} />
  </Dialog.Trigger>
);

// ref breaks memoization
// We need to memoize components because radix sucks https://github.com/radix-ui/primitives/issues/2602
const _SelectField = ({
  onChange,
  countries,
  selectedCountry,
  errorMessage,
  trigger,
  ...props
}: {
  trigger: UseFormTrigger<any>;
  onChange: (value: string) => void;
  onBlur: () => void;
  value: string;
  name: string;
  invalid: boolean;
  errorMessage: ReactNode | undefined;
  countries: PlatformCountry[];
  selectedCountry: PlatformCountry | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <Select
      label={t("onboarding.address.country")}
      onValueChange={value => {
        onChange(value);
        trigger(ProfileFields.COUNTRY);
      }}
      startSection={
        selectedCountry ? <Image className="size-6" src={createImagePathUrl(selectedCountry.flagUrlPath!)} /> : void 0
      }
      descriptor={errorMessage}
      {...props}
    >
      {countries.map(country => (
        <Select.Item key={country.code} value={country.country!}>
          <div className="flex items-center gap-3">
            <Select.ItemHiddenValue>
              <Image src={createImagePathUrl(country.flagUrlPath!)} className="size-6" />
            </Select.ItemHiddenValue>
            {country.country}
          </div>
        </Select.Item>
      ))}
    </Select>
  );
};

const SelectField = memo(_SelectField);

type Props = {
  countriesInfo: CountriesInfo;
};

const CountrySelect: FC<Props> = ({ countriesInfo }) => {
  const { control, trigger } = useFormContext();

  const { unsupportedCountries, countries } = countriesInfo;

  const [countryField, { invalid, errorMessage }] = useControlledField({
    name: ProfileFields.COUNTRY,
    control,
    rules: {
      required: true,
      validate: (value: string) => {
        const isInvalidCountry = unsupportedCountries!.find(({ country }) => country === value);

        if (isInvalidCountry) {
          return "onboarding.address.country-error";
        }

        return true;
      },
    },
  });

  const allSortedCountries = useMemo(
    () => [...countries!, ...unsupportedCountries!].sort((a, b) => a.country!.localeCompare(b.country!)),
    [countries, unsupportedCountries],
  );

  const selectedCountry = useMemo(
    () => allSortedCountries.find(({ country }) => country === countryField.value),
    [allSortedCountries, countryField.value],
  );

  return (
    <Dialog>
      <SelectField
        trigger={trigger}
        errorMessage={
          errorMessage && (
            <Trans
              i18nKey={errorMessage}
              components={{
                button: <TransButton />,
              }}
            />
          )
        }
        invalid={invalid}
        name={countryField.name}
        onBlur={countryField.onBlur}
        onChange={countryField.onChange}
        value={countryField.value}
        countries={allSortedCountries}
        selectedCountry={selectedCountry}
      />
      <InvalidCountryDialog />
    </Dialog>
  );
};

export { CountrySelect };
