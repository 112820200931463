import Big from "big.js";
import { createContext, memo, type ReactNode, useContext, useMemo } from "react";

import { TerminalDealType, TerminalEventSymbolSessionStateType, TradingServerSymbolType } from "@/services/openapi";

import { useSymbolsContext } from "../contexts/symbols.context";
import {
  calculateMargin,
  calculateMarginWithRate,
  calculateProfitAndLoss,
  getMarginMaintenanceCoeff,
} from "../helpers/formulas";
import type { LiveOrder } from "../helpers/orders";
import { isSymbolAvailable } from "../helpers/symbols";
import { useCurrentSymbolContext } from "../symbol-info/current-symbol-context";
import { useOrdersContext } from "./context";
import { getBidAsk, getOrderCurrentPrice } from "./helpers";

export interface OpenOrder extends LiveOrder {
  ask: number;
  bid: number;
  pnl: number;
  currentPrice: number | undefined;
  contractSize: number;
  volumeStep: number;
  volumeMin: number;
  priceDecimalScale: number;
  volumeDecimalScale: number;
  baseCurrency: string;
  quoteCurrency: string;
  margin: number;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
  instrumentType: TradingServerSymbolType;
  state: TerminalEventSymbolSessionStateType;
  calculatedProfitRate: number | undefined;
  symbolUrlPath: string;
}

export interface PendingOrder extends LiveOrder {
  ask: number;
  bid: number;
  currentPrice: number | undefined;
  priceDecimalScale: number;
  volumeDecimalScale: number;
  margin: number;
  baseCurrency: string;
  quoteCurrency: string;
  contractSize: number;
  symbolUrlPath: string;
}

type ExtendedOrdersContextState = {
  extendedOpenOrders: OpenOrder[];
  extendedPendingOrders: PendingOrder[];
};

const ExtendedOrdersContext = createContext<ExtendedOrdersContextState>({} as ExtendedOrdersContextState);

export const TerminalExtendedOrdersContextProvider = memo(
  ({
    children,
    accountCurrency,
    leverage,
    currencyDecimalScale,
  }: {
    children: ReactNode;
    accountCurrency: string;
    currencyDecimalScale: number;
    leverage: number;
  }) => {
    const { openOrders, pendingOrders } = useOrdersContext();

    const { symbolInfo } = useCurrentSymbolContext();

    const { symbolsList, symbols } = useSymbolsContext();

    const extendedOpenOrders: OpenOrder[] = useMemo(
      () =>
        openOrders.map(order => {
          const { symbol, type, volume, price, marginRate, profitRate } = order;

          const {
            priceAsk: a,
            priceBid: b,
            contractSize,
            volumeStep,
            volumeMin,
            volumeDecimalScale,
            priceDecimalScale,
            baseCurrency,
            quoteCurrency,
            marginRateMaintenanceMarketBuy,
            marginRateMaintenanceMarketSell,
            type: instrumentType,
            state,
            icon,
          } = symbolsList[symbol]!;

          const { ask, bid } = getBidAsk({ ask: a, bid: b, symbol, symbolInfo });

          const currentPrice = getOrderCurrentPrice({ type, ask, bid })!;

          const calculatedProfitRate = !isSymbolAvailable(state!) ? profitRate : undefined;

          const pnl = calculateProfitAndLoss({
            type,
            volume,
            openPrice: price,
            accountCurrency,
            currentPrice,
            baseCurrency: baseCurrency!,
            quoteCurrency: quoteCurrency!,
            contractSize: contractSize!,
            symbols,
            profitRate: calculatedProfitRate,
          });

          return {
            ...order,
            symbolUrlPath: icon!.webUrlPath!,
            ask,
            bid,
            currentPrice,
            calculatedProfitRate,
            pnl: new Big(pnl).round(currencyDecimalScale, 2).toNumber(),
            priceDecimalScale,
            contractSize: contractSize!,
            state: state!,
            volumeStep: volumeStep!,
            volumeMin: volumeMin!,
            volumeDecimalScale,
            baseCurrency: baseCurrency!,
            quoteCurrency: quoteCurrency!,
            marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
            marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
            instrumentType: instrumentType!,
            margin: calculateMarginWithRate({
              contractSize: contractSize!,
              leverage,
              volume,
              openPrice: price,
              instrumentType: instrumentType!,
              marginRate,
              marginCoeff: getMarginMaintenanceCoeff({
                type,
                marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
                marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
              }),
            }),
          };
        }),
      [openOrders, symbols, symbolInfo, accountCurrency, symbolsList, currencyDecimalScale, leverage],
    );

    const extendedPendingOrders: PendingOrder[] = useMemo(
      () =>
        pendingOrders.map(order => {
          const { symbol, type, volume, price } = order;

          const {
            priceAsk: a,
            priceBid: b,
            priceDecimalScale,
            volumeDecimalScale,
            baseCurrency,
            quoteCurrency,
            contractSize,
            marginRateInitialMarketBuy,
            marginRateInitialMarketSell,
            marginRateMaintenanceMarketBuy,
            marginRateMaintenanceMarketSell,
            type: instrumentType,
            icon,
          } = symbolsList[symbol]!;

          const { ask, bid } = getBidAsk({ ask: a, bid: b, symbol, symbolInfo });

          const currentPrice = symbolsList[symbol] ? getOrderCurrentPrice({ type, ask, bid }) : undefined;

          return {
            ...order,
            symbolUrlPath: icon!.webUrlPath!,
            ask,
            bid,
            currentPrice,
            priceDecimalScale,
            volumeDecimalScale,
            baseCurrency: baseCurrency!,
            quoteCurrency: quoteCurrency!,
            contractSize: contractSize!,
            margin: calculateMargin({
              accountCurrency,
              baseCurrency: baseCurrency!,
              contractSize: contractSize!,
              leverage,
              quoteCurrency: quoteCurrency!,
              volume,
              type:
                type === TerminalDealType.BuyLimit || type === TerminalDealType.BuyStop
                  ? TerminalDealType.Buy
                  : TerminalDealType.Sell,
              symbols,
              openPrice: price,
              instrumentType: instrumentType!,
              marginRate: "maintenance",
              marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
              marginRateInitialMarketSell: marginRateInitialMarketSell!,
              marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
              marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
            }),
          };
        }),
      [pendingOrders, symbolInfo, symbolsList, accountCurrency, leverage, symbols],
    );

    const value: ExtendedOrdersContextState = useMemo(
      () => ({ extendedOpenOrders, extendedPendingOrders }),
      [extendedOpenOrders, extendedPendingOrders],
    );

    return <ExtendedOrdersContext.Provider value={value}>{children}</ExtendedOrdersContext.Provider>;
  },
);
TerminalExtendedOrdersContextProvider.displayName = "TerminalExtendedOrdersContextProvider";

export const useExtendedOrdersContext = () => {
  return useContext(ExtendedOrdersContext);
};
