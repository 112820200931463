import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, ToggleGroup } from "@/shared/ui";

import { useTerminalAccountSummary } from "../../account-summary/context";
import { TradingUnavailableAlert } from "../../place-order/trading-unavailable/alert";
import { useCurrentSymbolContext } from "../current-symbol-context";
import { SymbolInfoDetailsBlock } from "./details-block";
import { SymbolInfoPriceChangeBlock } from "./price-change";
import { SymbolInfoScheduleBlock } from "./schedule/schedule-block";
import { SymbolInfoIconBlock } from "./symbol-icon-block";

const SymbolInfoDialogContent: FC = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState<"details" | "schedule">("details");

  const { leverage, currencyDecimalScale } = useTerminalAccountSummary();

  const { symbolInfo, isTradingAvailable } = useCurrentSymbolContext();
  const {
    symbol,
    name,
    contractSize,
    priceHigh24H,
    priceLast24H,
    priceLow24H,
    priceDecimalScale,
    priceAsk,
    priceBid,
    unit,
    type,
    marginRateInitialMarketBuy,
    volumeMin,
    volumeMax,
    sessions,
    swapTriple,
    swapLong,
    swapShort,
    volumeDecimalScale,
    quoteCurrency,
    icon,
  } = symbolInfo;

  return (
    <>
      <Dialog.Header title={t("terminal.symbol-info.title")} centered />
      <div className="flex flex-col gap-6">
        <SymbolInfoIconBlock name={name!} symbol={symbol!} symbolUrlPath={icon!.webUrlPath!} />
        <SymbolInfoPriceChangeBlock
          priceHigh24H={priceHigh24H!}
          priceLast24H={priceLast24H!}
          priceLow24H={priceLow24H!}
          priceDecimalScale={priceDecimalScale}
          priceBid={priceBid!}
        />
        <div>
          <ToggleGroup value={tab} onValueChange={setTab} className="mb-4">
            <ToggleGroup.Item value="details">{t("terminal.symbol-info.details")}</ToggleGroup.Item>
            <ToggleGroup.Item value="schedule">{t("terminal.symbol-info.schedule")}</ToggleGroup.Item>
          </ToggleGroup>
          {tab === "details" && (
            <SymbolInfoDetailsBlock
              contractSize={contractSize!}
              unit={unit!}
              instrumentType={type!}
              marginRateInitialMarketBuy={marginRateInitialMarketBuy!}
              leverage={leverage}
              volumeMin={volumeMin!}
              volumeMax={volumeMax!}
              swapTriple={swapTriple!}
              swapLong={swapLong!}
              swapShort={swapShort!}
              priceAsk={priceAsk!}
              priceBid={priceBid!}
              priceDecimalScale={priceDecimalScale}
              volumeDecimalScale={volumeDecimalScale}
              currencyDecimalScale={currencyDecimalScale}
              quoteCurrency={quoteCurrency!}
            />
          )}
          {tab === "schedule" && (
            <div className="flex flex-col gap-2">
              {!isTradingAvailable && <TradingUnavailableAlert />}
              <SymbolInfoScheduleBlock sessions={sessions!} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { SymbolInfoDialogContent };
