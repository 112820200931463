import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { ExternalLink, Link as UILink, Text } from "@/shared/ui";
import { usePlatformTranslationsQuery } from "@/state/server/platform";

import { cabinetContainerStyles } from "./page";

const ExternalAnchor: FC<any> = props => (
  <UILink color="regular" variant="inherit" asChild>
    <ExternalLink {...props} />
  </UILink>
);

const CabinetFooter: FC = () => {
  const { i18n } = useTranslation();
  const { data } = usePlatformTranslationsQuery({ locale: i18n.resolvedLanguage });

  if (!data) {
    return null;
  }

  return (
    <footer className="border-t border-surface-under-canvas py-6">
      <div className={cabinetContainerStyles({ size: "md" })}>
        <div className="typography-S-Compact-Medium flex flex-wrap items-center justify-between gap-2">
          <ExternalAnchor href={data["Footer::about-us::doc"]}>{data["Footer::about-us::word"]}</ExternalAnchor>
          <ExternalAnchor href={data["Footer::legal-documents::doc"]}>
            {data["Footer::legal-documents::word"]}
          </ExternalAnchor>
          <ExternalAnchor href={data["Footer::affiliate-program::doc"]}>
            {data["Footer::affiliate-program::word"]}
          </ExternalAnchor>
          <ExternalAnchor href={data["Footer::help-center::doc"]}>{data["Footer::help-center::word"]}</ExternalAnchor>
        </div>
        <div className="my-5 h-px bg-contrast-quaternary" />
        <Text variant="XS / Regular" align="center" color="tertiary">
          © {new Date().getFullYear()}, {data["Footer::Copyright"]}
        </Text>
      </div>
    </footer>
  );
};

export { CabinetFooter };
