import { type FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import type { CountriesInfo } from "@/services/openapi";

import { ProfileFields } from "../profile.form";
import { PhoneInput } from "./phone-input";

type Props = {
  countriesInfo: CountriesInfo;
};

const PhoneField: FC<Props> = ({ countriesInfo }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const [{ value: codeValue, onChange: onChangeCode }] = useControlledField({
    name: ProfileFields.PHONE_CODE,
    control,
  });

  const [{ value: countryValue, onChange: onChangeCountry }] = useControlledField({
    name: ProfileFields.PHONE_COUNTRY,
    control,
  });

  const allCountries = useMemo(
    () => [...countriesInfo.countries!, ...countriesInfo.unsupportedCountries!],
    [countriesInfo],
  );

  const selectedCountry = useMemo(
    () => allCountries.find(({ code }) => code === countryValue),
    [allCountries, countryValue],
  );

  const [field, { invalid, errorMessage, pending }] = useControlledField({
    name: ProfileFields.PHONE_NUMBER,
    control,
    rules: {
      required: t("fields.phone-number.required-error")!,
      minLength: selectedCountry
        ? {
            message: t("fields.phone-number.valid-error")!,
            value: selectedCountry.minLength || 0,
          }
        : void 0,
    },
  });

  return (
    <PhoneInput
      label={t("fields.phone-number.placeholder")}
      descriptor={errorMessage}
      invalid={invalid}
      pending={pending}
      codeValue={codeValue}
      countryValue={countryValue}
      countries={allCountries}
      onChangeCode={onChangeCode}
      onChangeCountry={onChangeCountry}
      selectedCountry={selectedCountry}
      {...field}
    />
  );
};

export { PhoneField };
