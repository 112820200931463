import { type FC } from "react";
import { Link } from "react-router-dom";

import { BonusBanner } from "@/entities/bonuses/banner";
import { getShowBonusBanner } from "@/entities/bonuses/helpers";
import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Button } from "@/shared/ui";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { CompleteVerificationBanner } from "../cabinet/components/banners/CompleteVerificationBanner";
import { AccountsCardsContainer } from "./accounts/cards.container";

const DashboardPage: FC = () => {
  const { t } = useTranslation();
  const { options, kycStatus, sourceOfFundsStatus } = useProfileData();
  const { data } = useBonusesQuery();

  return (
    <CabinetPage
      header={
        <CabinetHeader>
          <CabinetHeader.Title>{t("cabinet.navigation.accounts")}</CabinetHeader.Title>
          <Button variant="secondary" size="sm" asChild>
            <Link to={cabinetRoutes.transactionHistory}>{t("cabinet.navigation.transaction-history")}</Link>
          </Button>
        </CabinetHeader>
      }
      banner={
        data && (
          <>
            {data.items![0] && getShowBonusBanner(data.items![0]) ? (
              <CabinetPage.Banner>
                <BonusBanner bonus={data.items![0]} />
              </CabinetPage.Banner>
            ) : (
              !options!.isKycCompleted && (
                <CabinetPage.Banner>
                  <CompleteVerificationBanner kycStatus={kycStatus!} isSurveyCompleted={options!.isSurveyCompleted!} />
                </CabinetPage.Banner>
              )
            )}
          </>
        )
      }
    >
      <AccountsCardsContainer sourceOfFundsStatus={sourceOfFundsStatus!} />
    </CabinetPage>
  );
};

export { DashboardPage };
