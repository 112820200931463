import type { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";

import { TransactionsTableContainer } from "./components/transactions-table/transactions-table.container";

const TransactionsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <CabinetPage
      header={
        <CabinetHeader backButton={<CabinetHeader.BackLink to={cabinetRoutes.myAccounts} />}>
          <CabinetHeader.Title>{t("cabinet.navigation.transaction-history")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <TransactionsTableContainer />
    </CabinetPage>
  );
};

export { TransactionsPage };
