import {
  getLastIndicator,
  getSymbolsUpdates,
  getTerminalAccountSymbols,
  getTerminalPositionsHistory,
  getTerminalSymbols,
  getTradingCentralCalendarCredentials,
  getTradingCentralNews,
} from "@/services/terminal";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseInfiniteQuery, useBaseQuery } from "..";
import type { InfiniteQueryOptionsType, QueryOptionsType, RequestInfiniteParams } from "../types";
import { terminalQueryKeys } from "./terminal.keys";

export const useTerminalSymbolsQuery = (
  accountId: string,
  options?: QueryOptionsType<ApiResponseType<typeof getTerminalSymbols>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getTerminalSymbols>>({
    queryKey: terminalQueryKeys.symbols(accountId),
    queryFn: () => getTerminalSymbols({ tradingAccountId: accountId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useSymbolsUpdatesQuery = (
  filters: ApiRequestParamsType<typeof getSymbolsUpdates>,
  options?: QueryOptionsType<ApiResponseType<typeof getSymbolsUpdates>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getSymbolsUpdates>>({
    queryKey: terminalQueryKeys.symbolsUpdates(filters),
    queryFn: () => getSymbolsUpdates(filters),
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useInfiniteTerminalPositionsQuery = (
  filters: RequestInfiniteParams<ApiRequestParamsType<typeof getTerminalPositionsHistory>>,
  options?: InfiniteQueryOptionsType<ApiResponseType<typeof getTerminalPositionsHistory>>,
) => {
  const { pageSize, ...restFilters } = filters;
  return useBaseInfiniteQuery<
    ApiResponseType<typeof getTerminalPositionsHistory>,
    ApiRequestParamsType<typeof getTerminalPositionsHistory>
  >(terminalQueryKeys.positionsInfinite(restFilters), getTerminalPositionsHistory, filters, {
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useInfiniteTradingCentralNewsQuery = (
  filters: RequestInfiniteParams<ApiRequestParamsType<typeof getTradingCentralNews>>,
  options?: InfiniteQueryOptionsType<ApiResponseType<typeof getTradingCentralNews>>,
) => {
  const { pageSize, ...restFilters } = filters;
  return useBaseInfiniteQuery<
    ApiResponseType<typeof getTradingCentralNews>,
    ApiRequestParamsType<typeof getTradingCentralNews>
  >(terminalQueryKeys.newsInfinite(restFilters), getTradingCentralNews, filters, {
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useTerminalSymbolsFavoritesQuery = (
  accountId: string,
  options?: QueryOptionsType<ApiResponseType<typeof getTerminalAccountSymbols>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getTerminalAccountSymbols>>({
    queryKey: terminalQueryKeys.symbolsFavorites(accountId),
    queryFn: () => getTerminalAccountSymbols({ tradingAccountId: accountId }),
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useTerminalLastIndicatorQuery = (
  accountId: string,
  options?: QueryOptionsType<ApiResponseType<typeof getLastIndicator>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getLastIndicator>>({
    queryKey: terminalQueryKeys.lastIndicator(accountId),
    queryFn: () => getLastIndicator({ tradingAccountId: accountId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

const tenMinutes = 1000 * 60 * 10;
export const useTradingCentralCalendarCredentialsQuery = (
  filters: ApiRequestParamsType<typeof getTradingCentralCalendarCredentials>,
  options?: QueryOptionsType<ApiResponseType<typeof getTradingCentralCalendarCredentials>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getTradingCentralCalendarCredentials>>({
    queryKey: terminalQueryKeys.tradingCentralCalendar(filters),
    queryFn: () => getTradingCentralCalendarCredentials(filters),
    staleTime: tenMinutes,
    cacheTime: tenMinutes,
    ...options,
  });
};
