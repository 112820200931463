import { cva, type VariantProps } from "class-variance-authority";
import { Slot } from "radix-ui";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

import { UnstyledButton } from "..";

const buttonStyles = cva("typography-S-Medium inline-flex items-center gap-1 transition-colors", {
  variants: {
    variant: {
      primary: "text-contrast-primary hover:text-contrast-secondary",
      positive: "text-positive-text hover:text-positive-bg-hover",
    },
    underline: {
      true: "underline",
    },
  },
});

type Props = ComponentPropsWithoutRef<typeof UnstyledButton> &
  VariantProps<typeof buttonStyles> & {
    asChild?: boolean;
    icon?: ReactNode;
  };

const TextButton = forwardRef<ElementRef<typeof UnstyledButton>, Props>(
  ({ children, asChild, className, underline, variant = "primary", icon, ...props }, ref) => {
    const Comp = asChild ? Slot.Root : UnstyledButton;

    return (
      <Comp className={cn(buttonStyles({ underline, variant }), className)} {...props} ref={ref}>
        <Slot.Slottable>{children}</Slot.Slottable>
        {icon && <div className="*:size-4">{icon}</div>}
      </Comp>
    );
  },
);
TextButton.displayName = "TextButton";

export { TextButton };
