import type { FC } from "react";
import { Trans } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { TradingAccountTradeMode } from "@/services/openapi";
import { Text } from "@/shared/ui";

import { usePlaceOrderContext } from "../context";

type Props = {
  sliderDisabled: ReturnType<typeof usePlaceOrderContext>["sliderDisabled"];
  minSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeMargin"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  maxAvailableVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeLots"];
  maxAvailableVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeMargin"];
  volumeLotsDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeLotsDecimalScale"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  currency: string;
};

const _MinMaxValues: FC<Props> = ({
  sliderDisabled,
  maxAvailableVolumeLots,
  maxAvailableVolumeMargin,
  minSystemVolumeLots,
  minSystemVolumeMargin,
  volumeLotsDecimalScale,
  volumeMarginDecimalScale,
  volumeMode,
  currency,
}) => {
  const isMargin = volumeMode === TradingAccountTradeMode.Margin;

  return (
    <div className="mt-2 flex items-center justify-between gap-2">
      <Text variant="XS / Regular" color="secondary">
        {isMargin ? (
          <Trans
            i18nKey="terminal.place-order.slider.min-margin"
            components={{
              value: (
                <NumberFormat
                  value={minSystemVolumeMargin}
                  decimalScale={volumeMarginDecimalScale}
                  currency={currency}
                />
              ),
            }}
          />
        ) : (
          <Trans
            i18nKey="terminal.place-order.slider.min-lots"
            components={{
              value: <NumberFormat value={minSystemVolumeLots} decimalScale={volumeLotsDecimalScale} />,
            }}
          />
        )}
      </Text>
      {!sliderDisabled && (
        <Text variant="XS / Regular" color="secondary">
          {isMargin ? (
            <Trans
              i18nKey="terminal.place-order.slider.max-margin"
              components={{
                value: (
                  <NumberFormat
                    value={maxAvailableVolumeMargin}
                    decimalScale={volumeMarginDecimalScale}
                    currency={currency}
                  />
                ),
              }}
            />
          ) : (
            <Trans
              i18nKey="terminal.place-order.slider.max-lots"
              components={{
                value: <NumberFormat value={maxAvailableVolumeLots} decimalScale={volumeLotsDecimalScale} />,
              }}
            />
          )}
        </Text>
      )}
    </div>
  );
};

export { _MinMaxValues };
