import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { TerminalCloseOrderType } from "@/services/openapi";
import { Button, DataList, Dialog } from "@/shared/ui";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import type { OpenOrder } from "../../orders/extended-orders.context";
import { OrderTypeFormat } from "../../trading-tables/ui/order-type-format";
import { ChartDialog } from "../ui/chart-dialog";

const Content: FC<Omit<Props, "dialogOpen">> = ({
  order,
  accountCurrency,
  currencyDecimalScale,
  accountId,
  onClose,
}) => {
  const { t } = useTranslation();

  const { pnl, volumeDecimalScale, volume, symbol, type } = order;

  const { mutate: closeOrder, isLoading } = useCloseTerminalOrdersMutation();

  return (
    <>
      <ChartDialog.Header title={t("terminal.chart.close-order-dialog.title")} hideClose />
      <DataList>
        <DataList.Item
          label={<DataList.Label>{t("terminal.chart.close-order-dialog.instrument")}</DataList.Label>}
          value={<DataList.Value>{symbol}</DataList.Value>}
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.chart.close-order-dialog.type")}</DataList.Label>}
          value={
            <DataList.Value>
              <OrderTypeFormat type={type} />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.chart.close-order-dialog.lots")}</DataList.Label>}
          value={
            <DataList.Value>
              <NumberFormat value={volume} decimalScale={volumeDecimalScale} />
            </DataList.Value>
          }
        />
        <DataList.Item
          label={<DataList.Label>{t("terminal.chart.close-order-dialog.pnl")}</DataList.Label>}
          value={
            <DataList.Value color={getNumberTextColor(pnl)}>
              <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={accountCurrency} />
            </DataList.Value>
          }
        />
      </DataList>
      <ChartDialog.Buttons>
        <Button
          size="sm"
          pending={isLoading}
          onClick={() => {
            closeOrder(
              {
                tradingAccountId: accountId,
                terminalCloseOrderRequest: {
                  id: order.id,
                  type: TerminalCloseOrderType.Market,
                },
              },
              {
                onSuccess: () => {
                  onClose();
                },
              },
            );
          }}
        >
          {t("terminal.chart.close-order-dialog.action")}
        </Button>
        <Dialog.Close asChild>
          <Button size="sm" variant="secondary">
            {t("terminal.chart.close-order-dialog.cancel-button")}
          </Button>
        </Dialog.Close>
      </ChartDialog.Buttons>
    </>
  );
};

type Props = {
  dialogOpen: boolean;
  order: OpenOrder;
  accountCurrency: string;
  currencyDecimalScale: number;
  accountId: string;
  onClose: () => void;
};

const ChartCloseOpenOrderDialog: FC<Props> = ({ dialogOpen, onClose, ...props }) => {
  return (
    <Dialog open={dialogOpen} onOpenChange={onClose}>
      <ChartDialog.Content>
        <Content onClose={onClose} {...props} />
      </ChartDialog.Content>
    </Dialog>
  );
};

export { ChartCloseOpenOrderDialog };
