import dayjs from "dayjs";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import type { Notification } from "@/services/openapi";
import { Text } from "@/shared/ui";

type Props = {
  notification: Notification;
};

const NotificationItem: FC<Props> = ({ notification }) => {
  const { t } = useTranslation();

  const { date, description } = notification;

  const dateFormatted = dayjs(date).calendar(undefined, {
    sameDay: `[${t("common.today")}], DD MMMM HH:mm`,
    lastDay: `[${t("common.yesterday")}], DD MMMM HH:mm`,
    lastWeek: "dddd, DD MMMM HH:mm",
    sameElse: "dddd, DD MMMM HH:mm",
  });

  return (
    <div className="flex flex-col gap-3 border-t border-contrast-quinary pt-6 first:border-none first:pt-0">
      <Text as="h3" color="primary" variant="L / Medium" className="first-letter:capitalize">
        {dateFormatted}
      </Text>
      <Text color="primary" variant="S / Regular" className="whitespace-pre-wrap">
        {description}
      </Text>
    </div>
  );
};

export { NotificationItem };
