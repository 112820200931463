import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { QRCode } from "@/app/components";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cn } from "@/shared/styles";
import { Text } from "@/shared/ui";

type Props = {
  title: ReactNode;
  children: ReactNode;
  type: "sign-in" | "sign-up";
  qrLink: string;
  titleClassname?: ReactNode;
};

const AuthWrapperQR: FC<Props> = ({ children, title, type, qrLink, titleClassname }) => {
  const { t } = useTranslation();
  const { isDesktop } = useScreenSize();

  return (
    <div className="h-full place-items-center bg-inherit lg:grid lg:py-20">
      <main className="mx-auto flex w-full max-w-[852px] overflow-hidden rounded-xl bg-surface-elevation-1">
        <div className="w-full px-4 py-8 lg:px-[72px] lg:py-[52px]">
          <Text as="h1" align="center" color="primary" variant="2XL / Medium" className={cn("mb-8", titleClassname)}>
            {title}
          </Text>
          {children}
        </div>
        {isDesktop && (
          <div
            className="grid place-items-center px-[60px]"
            style={{
              backgroundColor: "hsla(69, 100%, 50%, 1)",
              backgroundImage:
                "radial-gradient(at 5% 92%, hsla(89, 93%, 65%, 1) 0px, transparent 50%), radial-gradient(at 92% 11%, hsla(132, 59%, 53%, 1) 0px, transparent 50%), radial-gradient(at 61% 54%, hsla(63, 88%, 51%, 1) 0px, transparent 50%), radial-gradient(at 48% 97%, hsla(82, 73%, 56%, 1) 0px, transparent 50%), radial-gradient(at 1% 47%, hsla(73, 82%, 55%, 1) 0px, transparent 50%), radial-gradient(at 80% 65%, hsla(63, 89%, 51%, 1) 0px, transparent 50%), radial-gradient(at 25% 2%, hsla(62, 95%, 81%, 1) 0px, transparent 50%), radial-gradient(at 96% 100%, hsla(97, 100%, 70%, 1) 0px, transparent 50%), radial-gradient(at 57% 0%, hsla(62, 95%, 81%, 1) 0px, transparent 50%)",
            }}
          >
            <div className="flex flex-col justify-center gap-4">
              <div className="rounded-xl bg-surface-elevation-1 p-5 text-contrast-primary">
                <QRCode value={qrLink} size={182} />
              </div>
              <Text variant="M Compact / Medium" color="staticBlack" align="center">
                {type === "sign-up" ? t("auth.sign-up.qr-text") : t("auth.sign-in.qr-text")}
              </Text>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export { AuthWrapperQR };
