import { Slot } from "radix-ui";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import educationFallback from "@/assets/images/education-fallback.avif";
import homeNewsFallback from "@/assets/images/home-news-fallback.avif";
import { Image, Label, Text, UnstyledButton } from "@/shared/ui";

type Props = OverrideProps<
  ComponentPropsWithoutRef<typeof UnstyledButton>,
  {
    title: ReactNode;
    imageUrl: string | null;
    type: "education" | "news";
    tags?: string[];
    asChild?: boolean;
    children?: ReactNode;
  }
>;

const HomeCard = forwardRef<ElementRef<typeof UnstyledButton>, Props>(
  ({ asChild = false, children, title, tags, imageUrl, type, ...props }, ref) => {
    const Comp = asChild ? Slot.Root : UnstyledButton;

    const fallback = type === "education" ? educationFallback : homeNewsFallback;

    return (
      <Comp
        className="relative inline-block h-full w-full overflow-hidden rounded-lg bg-card-bg outline-none transition-colors hover:bg-card-bg-hover active:bg-card-bg-active"
        ref={ref}
        {...props}
      >
        <Slot.Slottable>{children}</Slot.Slottable>
        {tags && tags.length > 0 && (
          <div className="absolute end-3 top-3 flex items-center gap-2">
            {tags.map(tag => (
              <Label key={tag} variant="card">
                {tag}
              </Label>
            ))}
          </div>
        )}
        <div className="aspect-[3/2] w-full bg-contrast-quaternary">
          <Image src={imageUrl ? imageUrl : fallback} className="h-full w-full object-cover" />
        </div>
        <div className="h-full p-4">
          <Text as="h4" variant="M / Medium" color="primary" className="line-clamp-2">
            {title}
          </Text>
        </div>
      </Comp>
    );
  },
);

export { HomeCard };
