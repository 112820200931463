import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { IconArrowSort } from "@/domains/icons";
import { TradingAccountTradeMode } from "@/services/openapi";
import { NumberInput } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../context";
import { VolumeMarginFieldDescriptor } from "./descriptor";

type Props = ComponentProps<typeof VolumeMarginFieldDescriptor> & {
  volumeMarginFormValue: ReturnType<typeof usePlaceOrderContext>["volumeMarginFormValue"];
  changeMargin: ReturnType<typeof usePlaceOrderContext>["changeMargin"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  changeVolumeMode: ReturnType<typeof usePlaceOrderContext>["changeVolumeMode"];
  volumeLotsDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeLotsDecimalScale"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  volumeOnBlur: ReturnType<typeof usePlaceOrderContext>["volumeOnBlur"];
  isSubmitting: boolean;
};

const VolumeMarginField: FC<Props> = ({
  volumeMarginDecimalScale,
  maxBalanceVolumeMargin,
  maxSystemVolumeMargin,
  minSystemVolumeMargin,
  changeMargin,
  volumeLots,
  changeLots,
  currency,
  volumeMarginFormValue,
  minSystemVolumeLots,
  hasNoFreeMargin,
  volumeLotsError,
  maxBalanceVolumeLots,
  maxSystemVolumeLots,
  isSubmitting,
  onDeposit,
  changeVolumeMode,
  volumeLotsDecimalScale,
  volumeOnBlur,
}) => {
  const { t } = useTranslation();

  const invalid = volumeLotsError !== VolumeLotsError.NONE && !hasNoFreeMargin;

  return (
    <NumberInput
      maxLength={20}
      floatingLabel={false}
      placeholder={t("terminal.place-order.margin.placeholder", { currency })!}
      label={
        volumeLots || volumeMarginFormValue ? (
          <NumberFormat value={volumeLots} decimalScale={volumeLotsDecimalScale} suffix={` ${t("terminal.lots")}`} />
        ) : (
          "–"
        )
      }
      disabled={hasNoFreeMargin}
      pending={isSubmitting}
      value={volumeMarginFormValue}
      suffix={` ${currency}`}
      onChange={value => changeMargin(value, { format: false })}
      onBlur={volumeOnBlur}
      invalid={invalid}
      decimalScale={volumeMarginDecimalScale}
      endSection={
        <NumberInput.IconButton onClick={() => changeVolumeMode(TradingAccountTradeMode.Lots)}>
          <IconArrowSort />
        </NumberInput.IconButton>
      }
      descriptor={
        invalid ? (
          <VolumeMarginFieldDescriptor
            onDeposit={onDeposit}
            changeLots={changeLots}
            volumeLotsError={volumeLotsError}
            maxBalanceVolumeLots={maxBalanceVolumeLots}
            maxBalanceVolumeMargin={maxBalanceVolumeMargin}
            maxSystemVolumeLots={maxSystemVolumeLots}
            maxSystemVolumeMargin={maxSystemVolumeMargin}
            minSystemVolumeLots={minSystemVolumeLots}
            minSystemVolumeMargin={minSystemVolumeMargin}
            currency={currency}
            volumeMarginDecimalScale={volumeMarginDecimalScale}
          />
        ) : null
      }
    />
  );
};

export { VolumeMarginField };
