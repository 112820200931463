import { type FC, useMemo } from "react";
import { Trans } from "react-i18next";

import type { OnboardingStep } from "@/features/onboarding/helpers";
import { Text } from "@/shared/ui";

type Props = {
  steps: OnboardingStep[];
  currentStepId: string;
};

const ProgressBar: FC<Props> = ({ currentStepId, steps }) => {
  const stepsAmount = steps.length;

  const currentStepIndex = useMemo(() => steps.findIndex(step => step.id === currentStepId), [currentStepId, steps]);

  return (
    <div className="mx-auto mb-2 w-full max-w-[512px] px-4">
      <Text variant="S / Medium">
        <Trans
          i18nKey="onboarding.progress-bar"
          values={{
            stepCount: currentStepIndex + 1,
            stepsAmount,
          }}
        />
      </Text>

      <div className="py-2.5">
        <div className="relative h-1 w-full overflow-hidden bg-contrast-quaternary">
          <div
            className="absolute h-full w-full bg-surface-elevation-3 transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(${
                ((currentStepIndex + (steps[currentStepIndex]!.filled ? 1 : 0)) / stepsAmount) * 100 - 100
              }%)`,
            }}
          />

          {!steps[currentStepIndex]!.filled && (
            <div
              className="absolute h-full w-full bg-contrast-quaternary transition-transform duration-500 ease-in-out"
              style={{
                transform: `translateX(${((currentStepIndex + 1) / stepsAmount) * 100 - 100}%)`,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { ProgressBar };
