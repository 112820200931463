import { useStore } from "@nanostores/react";
import { type FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { HomeBlockTopMoversContainer } from "@/services/openapi";
import { $marketWatchMessage, $symbolsHistoryPricesMessage, socketClient } from "@/services/websocket";
import { Text, ToggleGroup } from "@/shared/ui";

import { TomMoversItem } from "./item";

type Props = {
  topMovers: HomeBlockTopMoversContainer;
};

const TopMoversContainer: FC<Props> = ({ topMovers }) => {
  const { t } = useTranslation();

  const [periods, setPeriods] = useState(topMovers.periods!);
  const [interval, setInterval] = useState<string>(() => String(periods[0]!.hoursLast));

  const moversSymbols = useMemo(() => {
    const symbols = new Set<string>();
    topMovers.periods!.forEach(({ prices }) => prices!.forEach(({ symbol }) => symbols.add(symbol!)));
    return [...symbols];
  }, [topMovers.periods]);

  const currentPeriod = useMemo(
    () => periods.find(({ hoursLast }) => hoursLast === Number(interval)),
    [interval, periods],
  );

  const marketWatchMessage = useStore($marketWatchMessage);
  const symbolsHistoryPricesMessage = useStore($symbolsHistoryPricesMessage);

  useEffect(() => {
    setPeriods(topMovers.periods!);
  }, [topMovers.periods]);

  useEffect(() => {
    socketClient.subscribeMarketWatch(moversSymbols);
    socketClient.subscribeSymbolsUpdates();

    return () => {
      socketClient.unsubscribeMarketWatch();
      socketClient.unsubscribeSymbolsUpdates();
    };
  }, [moversSymbols]);

  useEffect(() => {
    if (!marketWatchMessage) return;

    const symbolsPrice: Record<string, number> = {};
    marketWatchMessage.s!.forEach(({ s, b }) => (symbolsPrice[s!] = b!));

    setPeriods(oldPeriods =>
      oldPeriods.map(({ hoursLast, prices }) => {
        const newPrices = prices!.map(price => ({ ...price, priceBid: symbolsPrice[price.symbol!] ?? price.priceBid }));
        return { hoursLast, prices: newPrices };
      }),
    );
  }, [marketWatchMessage]);

  useEffect(() => {
    if (!symbolsHistoryPricesMessage) return;

    setPeriods(oldPeriods =>
      oldPeriods.map(({ hoursLast, prices }) => {
        if (symbolsHistoryPricesMessage.h === hoursLast) {
          const symbolsPrice: Record<string, number> = {};
          symbolsHistoryPricesMessage.s!.forEach(({ s, p }) => (symbolsPrice[s!] = p!));

          const newPrices = prices!.map(price => ({
            ...price,
            price: symbolsPrice[price.symbol!] ?? price.price,
          }));

          return { hoursLast, prices: newPrices };
        }

        return { hoursLast, prices };
      }),
    );
  }, [symbolsHistoryPricesMessage]);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <Text as="h3" variant="L / Medium" color="primary">
          {t("home.top-movers.title")}
        </Text>
        <ToggleGroup value={interval} onValueChange={setInterval}>
          {topMovers.periods!.map(item => (
            <ToggleGroup.Item key={item.hoursLast} value={String(item.hoursLast)}>
              {t("home.top-movers.hours", { hoursCount: item.hoursLast })}
            </ToggleGroup.Item>
          ))}
        </ToggleGroup>
      </div>
      {currentPeriod && (
        <div>
          {currentPeriod.prices!.map(({ priceBid, symbol, digits, name, price, icon, chartUrlPath }) => (
            <TomMoversItem
              key={symbol}
              symbol={symbol!}
              name={name!}
              priceBid={priceBid!}
              priceLast={price!}
              priceDecimalScale={digits!}
              symbolUrlPath={icon!.webUrlPath!}
              chartUrlPath={chartUrlPath!}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export { TopMoversContainer };
