import type { FC } from "react";

import { IconGoogleColor } from "@/domains/icons";
import { IconButton } from "@/shared/ui";

import { useGoogleSignIn } from "./helpers";

const OAuthGoogleButton: FC = () => {
  const { signIn } = useGoogleSignIn();

  return (
    <IconButton variant="secondary" onClick={signIn}>
      <IconGoogleColor />
    </IconButton>
  );
};

export { OAuthGoogleButton };
