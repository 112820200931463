import Big from "big.js";

import { getNumberTextColor } from "@/app/ui/colors";
import { TerminalDealType, TradingServerSymbolType } from "@/services/openapi";
import type { TextColor } from "@/shared/ui";

import { getBaseSymbol, getQuoteSymbol } from "../helpers";
import { getOrderCurrentPrice } from "../orders/helpers";
import { isBuyOrder } from "./orders";
import { getInstrumentType, type MergedTerminalSymbol } from "./symbols";

const majorCurrencies = ["USD", "GBP", "EUR", "JPY"];

type PriceChange =
  | { priceChange: 0; direction: 0; color: undefined }
  | {
      priceChange: number;
      direction: 1 | -1;
      color: TextColor;
    };

const calculateSymbolPriceChange = ({
  bid,
  priceLast,
}: {
  bid: number | undefined | null;
  priceLast: number | null | undefined;
}): null | PriceChange => {
  if (!bid || !priceLast) {
    return null;
  }

  if (priceLast === bid) {
    return { priceChange: 0, direction: 0, color: void 0 };
  }

  const priceChange = new Big(bid).minus(priceLast).div(priceLast).mul(100).toNumber();

  return {
    priceChange: Math.abs(priceChange),
    direction: priceChange > 0 ? 1 : -1,
    color: getNumberTextColor(priceChange),
  };
};

type PipSizeParams = { priceDecimalScale: number };
const calculatePipSize = ({ priceDecimalScale }: PipSizeParams) => {
  const denominator = new Big(10).pow(priceDecimalScale - 1);
  return new Big(1).div(denominator).toNumber();
};

type PipValueParams = PipSizeParams & { volumeLots: number | string; contractSize: number };
const calculatePipValue = ({ volumeLots, contractSize, priceDecimalScale }: PipValueParams) => {
  const pipSize = calculatePipSize({ priceDecimalScale });
  return new Big(volumeLots).mul(contractSize).mul(pipSize).toNumber();
};

const calculateLeverage = ({
  instrumentType,
  leverage,
  marginRateInitialMarketBuy,
}: {
  leverage: number;
  instrumentType: TradingServerSymbolType;
  marginRateInitialMarketBuy: number;
}) => {
  const isForex = getInstrumentType(instrumentType) === "forex";

  if (isForex) {
    return new Big(leverage).div(marginRateInitialMarketBuy).toNumber();
  }

  return new Big(1).div(marginRateInitialMarketBuy).toNumber();
};

// TODO: naming
const calculateCurr = ({
  symbols,
  volumeLots,
  baseCurrency,
  type,
  contractSize,
  currency,
  isForex,
  priceMultipler,
}: {
  symbols: MergedTerminalSymbol[];
  baseCurrency: string;
  volumeLots: number | string;
  contractSize: number;
  type: TerminalDealType;
  currency: string;
  isForex: boolean;
  priceMultipler: number;
}) => {
  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: currency,
    quoteCurrencyPredicate: baseCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.priceAsk || !baseSymbol.priceBid) {
      return 0;
    }
    const baseCurrentPrice = getOrderCurrentPrice({
      type,
      ask: baseSymbol.priceAsk,
      bid: baseSymbol.priceBid,
      inverse: true,
    })!;

    return new Big(volumeLots)
      .mul(contractSize)
      .div(baseCurrentPrice)
      .mul(isForex ? 1 : priceMultipler)
      .toNumber();
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    baseCurrencyPredicate: baseCurrency,
    quoteCurrencyPredicate: currency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.priceAsk || !quoteSymbol.priceBid) {
      return 0;
    }
    const quoteCurrentPrice = getOrderCurrentPrice({
      type,
      ask: quoteSymbol.priceAsk,
      bid: quoteSymbol.priceBid,
      inverse: true,
    })!;
    return new Big(volumeLots)
      .mul(contractSize)
      .mul(quoteCurrentPrice)
      .mul(isForex ? 1 : priceMultipler)
      .toNumber();
  }

  return null;
};

export const calculateCurrency = ({
  accountCurrency,
  volumeLots,
  contractSize,
  quoteCurrency,
  baseCurrency,
  ask,
  bid,
  type: typeProps,
  symbols,
  instrumentType,
}: {
  volumeLots: number | string;
  contractSize: number;
  quoteCurrency: string;
  accountCurrency: string;
  baseCurrency: string;
  bid: number | undefined;
  ask: number | undefined;
  type: "buy" | "sell";
  symbols: MergedTerminalSymbol[];
  instrumentType: TradingServerSymbolType;
}) => {
  if (!volumeLots) {
    return 0;
  }

  const type = typeProps === "buy" ? TerminalDealType.Buy : TerminalDealType.Sell;

  const price =
    getOrderCurrentPrice({
      type,
      ask,
      bid,
      inverse: true,
    }) || 0;

  const isForex = getInstrumentType(instrumentType) === "forex";

  const priceMultipler = isForex ? 1 : price;

  if (quoteCurrency === accountCurrency) {
    return new Big(volumeLots).mul(contractSize).mul(price).toNumber();
  }

  if (baseCurrency === accountCurrency) {
    return new Big(volumeLots).mul(contractSize).mul(priceMultipler).toNumber();
  }

  const result = calculateCurr({
    baseCurrency,
    symbols,
    type,
    contractSize,
    volumeLots,
    currency: accountCurrency,
    isForex,
    priceMultipler,
  });

  if (result) {
    return result;
  }

  for (const currency of majorCurrencies) {
    const baseSymbol = getBaseSymbol({
      symbols,
      baseCurrencyPredicate: accountCurrency,
      quoteCurrencyPredicate: currency,
    });

    const quoteSymbol = getQuoteSymbol({
      symbols,
      baseCurrencyPredicate: currency,
      quoteCurrencyPredicate: accountCurrency,
    });

    if (!baseSymbol && !quoteSymbol) {
      continue;
    }

    const result = calculateCurr({
      baseCurrency,
      symbols,
      type,
      contractSize,
      volumeLots,
      currency,
      priceMultipler,
      isForex,
    });

    if (result) {
      if (baseSymbol) {
        if (!baseSymbol.priceAsk || !baseSymbol.priceBid) {
          return 0;
        }
        const baseCurrentPrice = getOrderCurrentPrice({
          type,
          ask: baseSymbol.priceAsk,
          bid: baseSymbol.priceBid,
        })!;

        return new Big(result).div(baseCurrentPrice).toNumber();
      }

      if (quoteSymbol) {
        if (!quoteSymbol.priceAsk || !quoteSymbol.priceBid) {
          return 0;
        }
        const quoteCurrentPrice = getOrderCurrentPrice({
          type,
          ask: quoteSymbol.priceAsk,
          bid: quoteSymbol.priceBid,
        })!;

        return new Big(result).mul(quoteCurrentPrice).toNumber();
      }
    }
  }

  return 0;
};

type CurrencyRateParams = {
  ask: number;
  bid: number;
  accountCurrency: string;
  baseCurrency: string;
  quoteCurrency: string;
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
};
const calculateCurrencyRate = ({
  accountCurrency,
  baseCurrency,
  ask,
  bid,
  quoteCurrency,
  symbols,
  type,
}: CurrencyRateParams) => {
  if (baseCurrency === accountCurrency) {
    return 1;
  }

  if (quoteCurrency === accountCurrency) {
    return getOrderCurrentPrice({ type, ask, bid })!;
  }

  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: accountCurrency,
    quoteCurrencyPredicate: quoteCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.priceAsk || !baseSymbol.priceBid) {
      return 1;
    }
    const baseCurrentPrice = getOrderCurrentPrice({ type, ask: baseSymbol.priceAsk, bid: baseSymbol.priceBid })!;
    return new Big(1).div(baseCurrentPrice);
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    quoteCurrencyPredicate: accountCurrency,
    baseCurrencyPredicate: quoteCurrency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.priceAsk || !quoteSymbol.priceBid) {
      return 1;
    }
    const quoteCurrentPrice = getOrderCurrentPrice({ type, ask: quoteSymbol.priceAsk, bid: quoteSymbol.priceBid })!;
    return quoteCurrentPrice;
  }

  return 1;
};

type SwapParams = Omit<CurrencyRateParams, "type"> &
  PipSizeParams & {
    volumeLots: number | string;
    type: "buy" | "sell";
    swapLong: number;
    swapShort: number;
    contractSize: number;
  };

const calculateSwap = ({
  volumeLots,
  type,
  swapLong,
  swapShort,
  priceDecimalScale,
  ask,
  bid,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  symbols,
  contractSize,
}: SwapParams) => {
  const swapRate = type === "buy" ? swapLong : swapShort;
  const pointSize = new Big(calculatePipSize({ priceDecimalScale })).div(10);

  const currencyRate = calculateCurrencyRate({
    ask,
    bid,
    accountCurrency,
    baseCurrency,
    quoteCurrency,
    symbols,
    type: type === "buy" ? TerminalDealType.Buy : TerminalDealType.Sell,
  });

  return new Big(volumeLots).mul(swapRate).mul(pointSize).mul(contractSize).mul(currencyRate).toNumber();
};

type PipsSpreadParams = PipSizeParams & {
  ask: number;
  bid: number;
};

const pipsSpreadDecimalScale = 1;
const calculatePipsSpread = ({ ask, bid, priceDecimalScale }: PipsSpreadParams) => {
  const pipSize = calculatePipSize({ priceDecimalScale });
  return new Big(ask).minus(bid).div(pipSize).toNumber();
};

type CurrencySpreadParams = Omit<CurrencyRateParams, "type"> & {
  contractSize: number;
};

const calculateCurrencySpread = ({
  ask,
  bid,
  accountCurrency,
  baseCurrency,
  symbols,
  quoteCurrency,
  contractSize,
}: CurrencySpreadParams) => {
  const currencyRate = calculateCurrencyRate({
    ask,
    bid,
    accountCurrency,
    baseCurrency,
    quoteCurrency,
    symbols,
    type: TerminalDealType.Buy,
  });

  return new Big(ask).minus(bid).mul(contractSize).mul(currencyRate).toNumber();
};

// TODO: naming
const calculateProfitRateThirdCase = ({
  symbols,
  quoteCurrency,
  type,
  currency,
}: {
  symbols: MergedTerminalSymbol[];
  quoteCurrency: string;
  type: TerminalDealType;
  currency: string;
}) => {
  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: currency,
    quoteCurrencyPredicate: quoteCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.priceAsk || !baseSymbol.priceBid) {
      return 0;
    }
    const baseCurrentPrice = getOrderCurrentPrice({ type, ask: baseSymbol.priceAsk, bid: baseSymbol.priceBid })!;
    return new Big(1).div(baseCurrentPrice).toNumber();
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    baseCurrencyPredicate: quoteCurrency,
    quoteCurrencyPredicate: currency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.priceAsk || !quoteSymbol.priceBid) {
      return 0;
    }
    const quoteCurrentPrice = getOrderCurrentPrice({
      type,
      ask: quoteSymbol.priceAsk,
      bid: quoteSymbol.priceBid,
      inverse: true,
    })!;
    return quoteCurrentPrice;
  }

  return null;
};

const calculateProfitRate = ({
  currentPrice,
  type,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  symbols,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  currentPrice: number;
  accountCurrency: string;
  baseCurrency: string;
  quoteCurrency: string;
}) => {
  // first case: Quote Currency = Account currency
  if (quoteCurrency === accountCurrency) {
    return 1;
  }

  // second case: Base Currency = Account currency
  if (baseCurrency === accountCurrency) {
    return new Big(1).div(currentPrice).toNumber();
  }

  // third case: Base Currency or Quote Currency !== Account currency
  const result = calculateProfitRateThirdCase({ currency: accountCurrency, quoteCurrency, symbols, type });

  if (result) {
    return result;
  }

  // fourth case:
  for (const currency of majorCurrencies) {
    const baseSymbol = getBaseSymbol({
      symbols,
      baseCurrencyPredicate: accountCurrency,
      quoteCurrencyPredicate: currency,
    });

    const quoteSymbol = getQuoteSymbol({
      symbols,
      baseCurrencyPredicate: currency,
      quoteCurrencyPredicate: accountCurrency,
    });

    if (!baseSymbol && !quoteSymbol) {
      continue;
    }

    const result = calculateProfitRateThirdCase({ currency, quoteCurrency, symbols, type });
    if (result) {
      if (baseSymbol) {
        if (!baseSymbol.priceAsk || !baseSymbol.priceBid) {
          return 0;
        }
        const baseCurrentPrice = getOrderCurrentPrice({
          type,
          ask: baseSymbol.priceAsk,
          bid: baseSymbol.priceBid,
          inverse: true,
        })!;

        return new Big(result).div(baseCurrentPrice).toNumber();
      }

      if (quoteSymbol) {
        if (!quoteSymbol.priceAsk || !quoteSymbol.priceBid) {
          return 0;
        }
        const quoteCurrentPrice = getOrderCurrentPrice({
          type,
          ask: quoteSymbol.priceAsk,
          bid: quoteSymbol.priceBid,
          inverse: true,
        })!;

        return new Big(result).mul(quoteCurrentPrice).toNumber();
      }
    }
  }

  return 0;
};

const calculateProfitAndLoss = ({
  contractSize,
  currentPrice,
  openPrice,
  type,
  volume,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  symbols,
  profitRate: profitRateProp,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  volume: number;
  currentPrice: number;
  openPrice: number;
  accountCurrency: string;
  contractSize: number;
  baseCurrency: string;
  quoteCurrency: string;
  // pass this param when market is closed
  profitRate?: number;
}) => {
  if (!volume || !currentPrice || !openPrice) {
    return 0;
  }

  const direction = isBuyOrder(type) ? 1 : -1;

  const profitRate = profitRateProp
    ? profitRateProp
    : calculateProfitRate({
        currentPrice,
        type,
        accountCurrency,
        baseCurrency,
        quoteCurrency,
        symbols,
      });

  return new Big(currentPrice).minus(openPrice).mul(volume).mul(contractSize).mul(direction).mul(profitRate).toNumber();
};

const calculateMarginWithRate = ({
  contractSize,
  leverage,
  openPrice,
  volume,
  instrumentType,
  marginRate,
  marginCoeff,
}: {
  openPrice: number;
  volume: number;
  contractSize: number;
  leverage: number;
  instrumentType: TradingServerSymbolType;
  marginRate: number;
  marginCoeff: number;
}) => {
  const isForex = getInstrumentType(instrumentType) === "forex";

  if (isForex) {
    return new Big(volume).mul(contractSize).div(leverage).mul(marginCoeff).mul(marginRate).toNumber();
  }

  return new Big(volume).mul(contractSize).mul(openPrice).mul(marginCoeff).mul(marginRate).toNumber();
};

const getMarginMaintenanceCoeff = ({
  marginRateMaintenanceMarketBuy,
  marginRateMaintenanceMarketSell,
  type,
}: {
  type: TerminalDealType;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
}) => {
  return type === TerminalDealType.Buy ? marginRateMaintenanceMarketBuy : marginRateMaintenanceMarketSell;
};

const getMarginCoeff = ({
  marginRate,
  marginRateInitialMarketBuy,
  marginRateInitialMarketSell,
  marginRateMaintenanceMarketBuy,
  marginRateMaintenanceMarketSell,
  type,
}: {
  type: TerminalDealType;
  marginRate: "initial" | "maintenance";
  marginRateInitialMarketBuy: number;
  marginRateInitialMarketSell: number;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
}) => {
  if (marginRate === "maintenance") {
    return getMarginMaintenanceCoeff({
      marginRateMaintenanceMarketBuy,
      marginRateMaintenanceMarketSell,
      type,
    });
  }
  return type === TerminalDealType.Buy ? marginRateInitialMarketBuy : marginRateInitialMarketSell;
};

// TODO: naming
const calculateMar = ({
  symbols,
  volume,
  baseCurrency,
  type,
  contractSize,
  leverage,
  currency,
  marginRate,
  finalMultiplier,
}: {
  symbols: MergedTerminalSymbol[];
  baseCurrency: string;
  volume: number;
  contractSize: number;
  type: TerminalDealType;
  currency: string;
  leverage: number;
  marginRate: number;
  finalMultiplier: number;
}) => {
  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: currency,
    quoteCurrencyPredicate: baseCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.priceAsk || !baseSymbol.priceBid) {
      return 0;
    }
    const baseCurrentPrice = getOrderCurrentPrice({ type, ask: baseSymbol.priceAsk, bid: baseSymbol.priceBid })!;
    return new Big(volume)
      .mul(contractSize)
      .div(baseCurrentPrice)
      .div(leverage)
      .mul(marginRate)
      .mul(finalMultiplier)
      .toNumber();
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    baseCurrencyPredicate: baseCurrency,
    quoteCurrencyPredicate: currency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.priceAsk || !quoteSymbol.priceBid) {
      return 0;
    }
    const quoteCurrentPrice = getOrderCurrentPrice({
      type,
      ask: quoteSymbol.priceAsk,
      bid: quoteSymbol.priceBid,
      inverse: true,
    })!;
    return new Big(volume)
      .mul(contractSize)
      .mul(quoteCurrentPrice)
      .div(leverage)
      .mul(marginRate)
      .mul(finalMultiplier)
      .toNumber();
  }

  return null;
};

const calculateMargin = ({
  type,
  symbols,
  contractSize,
  leverage,
  openPrice,
  volume,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  instrumentType,
  marginRate: marginRateType,
  marginRateInitialMarketBuy,
  marginRateInitialMarketSell,
  marginRateMaintenanceMarketBuy,
  marginRateMaintenanceMarketSell,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  openPrice: number;
  volume: number;
  contractSize: number;
  leverage: number;
  accountCurrency: string;
  baseCurrency: string;
  quoteCurrency: string;
  instrumentType: TradingServerSymbolType;
  marginRate: "initial" | "maintenance";
  marginRateInitialMarketBuy: number;
  marginRateInitialMarketSell: number;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
}) => {
  if (!openPrice) {
    return 0;
  }

  const isForex = getInstrumentType(instrumentType) === "forex";

  const marginCoeff = getMarginCoeff({
    marginRate: marginRateType,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
    type,
  });

  const finalMultiplier = isForex ? 1 : openPrice;

  return countBaseMargin({
    type,
    symbols,
    contractSize,
    leverage: isForex ? leverage : 1,
    openPrice,
    volume,
    accountCurrency,
    baseCurrency,
    quoteCurrency,
    marginRate: marginCoeff,
    finalMultiplier,
  });
};

const countBaseMargin = ({
  type,
  symbols,
  contractSize,
  leverage,
  openPrice,
  volume,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  marginRate,
  finalMultiplier,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  openPrice: number;
  volume: number;
  contractSize: number;
  leverage: number;
  accountCurrency: string;
  baseCurrency: string;
  quoteCurrency: string;
  marginRate: number;
  // TODO: naming
  finalMultiplier: number;
}): number => {
  if (baseCurrency === accountCurrency) {
    return new Big(volume).mul(contractSize).div(leverage).mul(marginRate).mul(finalMultiplier).toNumber();
  }

  if (quoteCurrency === accountCurrency) {
    return new Big(volume)
      .mul(contractSize)
      .mul(openPrice)
      .div(leverage)
      .mul(marginRate)
      .mul(finalMultiplier)
      .toNumber();
  }

  const result = calculateMar({
    baseCurrency,
    contractSize,
    currency: accountCurrency,
    finalMultiplier,
    leverage,
    marginRate,
    symbols,
    type,
    volume,
  });

  if (result) {
    return result;
  }

  for (const currency of majorCurrencies) {
    const baseSymbol = getBaseSymbol({
      symbols,
      baseCurrencyPredicate: accountCurrency,
      quoteCurrencyPredicate: currency,
    });

    const quoteSymbol = getQuoteSymbol({
      symbols,
      baseCurrencyPredicate: currency,
      quoteCurrencyPredicate: accountCurrency,
    });

    if (!baseSymbol && !quoteSymbol) {
      continue;
    }

    const result = calculateMar({
      baseCurrency,
      contractSize,
      currency,
      finalMultiplier,
      leverage,
      marginRate,
      symbols,
      type,
      volume,
    });
    if (result) {
      if (baseSymbol) {
        if (!baseSymbol.priceAsk || !baseSymbol.priceBid) {
          return 0;
        }
        const baseCurrentPrice = getOrderCurrentPrice({
          type,
          ask: baseSymbol.priceAsk,
          bid: baseSymbol.priceBid,
          inverse: true,
        })!;

        return new Big(result).div(baseCurrentPrice).toNumber();
      }

      if (quoteSymbol) {
        if (!quoteSymbol.priceAsk || !quoteSymbol.priceBid) {
          return 0;
        }
        const quoteCurrentPrice = getOrderCurrentPrice({
          type,
          ask: quoteSymbol.priceAsk,
          bid: quoteSymbol.priceBid,
          inverse: true,
        })!;

        return new Big(result).mul(quoteCurrentPrice).toNumber();
      }
    }
  }

  return 0;
};

export {
  calculateCurrencySpread,
  calculatePipValue,
  calculatePipsSpread,
  calculateSwap,
  calculateLeverage,
  calculateSymbolPriceChange,
  calculatePipSize,
  pipsSpreadDecimalScale,
  calculateProfitAndLoss,
  calculateMarginWithRate,
  getMarginMaintenanceCoeff,
  calculateMargin,
};
export type { PriceChange };
