import { useQueryClient } from "react-query";

import {
  changeUserSettings,
  confirmUserProfile,
  removeFcmToken,
  targetPriceDialogDisable,
  targetPriceDisable,
  targetPriceEnable,
  updateActivity,
  updateFcmToken,
  updateUserEmail,
} from "@/services/profile";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseMutation } from "..";
import type { MutationOptionsType } from "../types";
import { profileQueryKeys } from "./profile.keys";

export const useConfirmUserProfileMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof confirmUserProfile>,
    ApiRequestParamsType<typeof confirmUserProfile>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof confirmUserProfile>, ApiRequestParamsType<typeof confirmUserProfile>>(
    confirmUserProfile,
    options,
  );
};

export const useUpdateUserEmailMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof updateUserEmail>, ApiRequestParamsType<typeof updateUserEmail>>,
) => {
  return useBaseMutation<ApiResponseType<typeof updateUserEmail>, ApiRequestParamsType<typeof updateUserEmail>>(
    updateUserEmail,
    options,
  );
};

export const useUpdateUserSettingMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof changeUserSettings>,
    ApiRequestParamsType<typeof changeUserSettings>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof changeUserSettings>, ApiRequestParamsType<typeof changeUserSettings>>(
    changeUserSettings,
    options,
  );
};

export const useUpdateActivityMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof updateActivity>, ApiRequestParamsType<typeof updateActivity>>,
) => {
  const client = useQueryClient();

  return useBaseMutation<ApiResponseType<typeof updateActivity>, ApiRequestParamsType<typeof updateActivity>>(
    updateActivity,
    {
      onSuccess: () => {
        client.invalidateQueries(profileQueryKeys.header());
      },
      ...options,
    },
  );
};

export const useUpdateFcmTokenMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof updateFcmToken>, ApiRequestParamsType<typeof updateFcmToken>>,
) => {
  return useBaseMutation<ApiResponseType<typeof updateFcmToken>, ApiRequestParamsType<typeof updateFcmToken>>(
    updateFcmToken,
    options,
  );
};

export const useRemoveFcmTokenMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof removeFcmToken>, ApiRequestParamsType<typeof removeFcmToken>>,
) => {
  return useBaseMutation<ApiResponseType<typeof removeFcmToken>, ApiRequestParamsType<typeof removeFcmToken>>(
    removeFcmToken,
    options,
  );
};

export const useEnableTargetPriceMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof targetPriceEnable>,
    ApiRequestParamsType<typeof targetPriceEnable>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof targetPriceEnable>, ApiRequestParamsType<typeof targetPriceEnable>>(
    targetPriceEnable,
    options,
  );
};

export const useDisableTargetPriceMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof targetPriceDisable>,
    ApiRequestParamsType<typeof targetPriceDisable>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof targetPriceDisable>, ApiRequestParamsType<typeof targetPriceDisable>>(
    targetPriceDisable,
    options,
  );
};

export const useDisableTargetPriceDialogMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof targetPriceDialogDisable>,
    ApiRequestParamsType<typeof targetPriceDialogDisable>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof targetPriceDialogDisable>,
    ApiRequestParamsType<typeof targetPriceDialogDisable>
  >(targetPriceDialogDisable, options);
};
