import { type DateValue } from "@internationalized/date";
import dayjs from "dayjs";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import type { PlatformInfo } from "@/services/openapi";
import { DateInput } from "@/shared/ui";
import { isTooOld, isYoungerThan } from "@/utils/dates";

import { ProfileFields } from "./profile.form";

type Props = {
  platformInfo: PlatformInfo;
};

const DateField: FC<Props> = ({ platformInfo }) => {
  const { t } = useTranslation();
  const { control, trigger } = useFormContext();
  const { validation } = platformInfo;

  const [{ onBlur, onChange, ...dateField }, { errorMessage, invalid, pending, isTouched }] = useControlledField({
    name: ProfileFields.DATE_OF_BIRTH,
    control,
    rules: {
      required: t("onboarding.profile.birth-date-error")!,
      validate: (date: DateValue | null) => {
        if (!date) {
          return true;
        }
        const now = dayjs();
        const parsedDate = dayjs(date.toDate("Etc/UTC"));

        const isValidDate = isTooOld(parsedDate) && dayjs(parsedDate).isBefore(now);
        if (!isValidDate) {
          return t("onboarding.profile.birth-date-error-invalid")!;
        }

        const isYoung = isYoungerThan(parsedDate, validation!.user!.ageMin!);
        if (isYoung) {
          return t("form-errors.min-age-error")!;
        }

        return true;
      },
    },
  });

  return (
    <DateInput
      label={t("onboarding.profile.birth-date-label")}
      pending={pending}
      invalid={invalid}
      descriptor={errorMessage}
      onBlur={() => {
        trigger(ProfileFields.DATE_OF_BIRTH);
        onBlur();
      }}
      onChange={value => {
        onChange(value);
        if (isTouched) {
          trigger(ProfileFields.DATE_OF_BIRTH);
        }
      }}
      {...dateField}
    />
  );
};

export { DateField };
