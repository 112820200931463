import { Collapsible } from "radix-ui";
import type { ComponentProps, FC, ReactNode } from "react";

import { cn } from "@/shared/styles";
import { Switch, Text, type TextColor } from "@/shared/ui";

const Caption: FC<{ label: string; value: ReactNode; valueColor?: TextColor }> = ({
  label,
  value,
  valueColor = "secondary",
}) => {
  return (
    <div className="mt-2 flex items-center gap-1">
      <Text color="secondary" variant="S Compact / Regular">
        {label}
      </Text>
      <Text color={valueColor} variant="S Compact / Regular">
        {value}
      </Text>
    </div>
  );
};

type Props = Pick<ComponentProps<typeof Switch>, "checked" | "onCheckedChange"> & {
  label: string;
  children: ReactNode;
  dimmed?: boolean;
};

const PlaceOrderFieldBlock: FC<Props> = ({ children, label, checked, onCheckedChange, dimmed }) => {
  return (
    <Collapsible.Root open={checked} className={cn("rounded-lg bg-card-bg py-3", dimmed && "bg-card-bg-accent")}>
      <label className="flex items-center justify-between gap-4 px-4">
        <Text color="primary" variant="S / Medium">
          {label}
        </Text>
        <Switch size="sm" checked={checked} onCheckedChange={onCheckedChange} />
      </label>
      <Collapsible.Content className="collapsible">
        {/* fixes box-shadow overflow */}
        <div className="mb-0.5 mt-3 px-4">{children}</div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

const Component = Object.assign(PlaceOrderFieldBlock, { Caption });

export { Component as PlaceOrderFieldBlock };
