import type { FC } from "react";
import { Outlet } from "react-router-dom";

import { AuthHeader } from "./header";

const AuthLayout: FC = () => {
  return (
    <div className="grid h-full grid-rows-[auto_1fr] bg-surface-elevation-1 lg:bg-surface-canvas">
      <AuthHeader />
      <Outlet />
    </div>
  );
};

export { AuthLayout };
