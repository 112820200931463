import type { FC } from "react";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { SourceOfFundsBanner } from "@/entities/source-of-funds/banner";
import { useTranslation } from "@/hooks/translator.hook";
import { AvailabilityStatus, type SourceOfFundsStatus, TradingAccountType } from "@/services/openapi";
import { Tabs } from "@/shared/ui";

import { ArchivedCardsContainer } from "./archived/cards.container";
import { DemoCardsContainer } from "./demo/cards.container";
import { RealCardsContainer } from "./real/cards.container";

type Props = {
  sourceOfFundsStatus: SourceOfFundsStatus;
};

const AccountsCardsContainer: FC<Props> = ({ sourceOfFundsStatus }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = useMemo(() => {
    const string = searchParams.get("tab");
    if (string === AvailabilityStatus.Archived) {
      return string;
    }
    if (string === TradingAccountType.Demo) {
      return string;
    }
    return TradingAccountType.Real;
  }, [searchParams]);

  return (
    <Tabs
      value={tab}
      onValueChange={tab => {
        setSearchParams(prevParams => {
          prevParams.set("tab", tab);
          return prevParams;
        });
      }}
    >
      <Tabs.List className="mx-4 mb-4">
        <Tabs.Trigger value={TradingAccountType.Real} data-test="dashboard-btn-real">
          {t("accounts.filters.real")}
        </Tabs.Trigger>
        <Tabs.Trigger value={TradingAccountType.Demo} data-test="dashboard-btn-demo">
          {t("accounts.filters.demo")}
        </Tabs.Trigger>
        <Tabs.Trigger value={AvailabilityStatus.Archived} data-test="dashboard-btn-archived">
          {t("accounts.filters.archived")}
        </Tabs.Trigger>
      </Tabs.List>

      {sourceOfFundsStatus.requested && (
        <SourceOfFundsBanner
          className="my-4"
          amount={sourceOfFundsStatus.treshold!}
          currency={sourceOfFundsStatus.currency!}
          decimalScale={sourceOfFundsStatus.digits!}
        />
      )}

      <Tabs.Content value={TradingAccountType.Real}>
        <RealCardsContainer />
      </Tabs.Content>
      <Tabs.Content value={TradingAccountType.Demo}>
        <DemoCardsContainer />
      </Tabs.Content>
      <Tabs.Content value={AvailabilityStatus.Archived}>
        <ArchivedCardsContainer />
      </Tabs.Content>
    </Tabs>
  );
};

export { AccountsCardsContainer };
