import type { DateValue } from "@internationalized/date";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, SubmitButton, useHookForm } from "@/app/form";
import { StepWrapper } from "@/features/onboarding/components/steps/step-wrapper";
import type { AddressInfo, CountriesInfo, PlatformInfo, ProfileInfo } from "@/services/openapi";

import { CountrySelect } from "./country-field";
import { DateField } from "./date-field";
import { NameField } from "./name-field";
import { NotUSACitizenField } from "./not-usa-citizen-field";
import { PhoneField } from "./phone-field/phone-field";
import { LastnameField } from "./surname-field";

enum ProfileFields {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  DATE_OF_BIRTH = "dateOfBirth",
  PHONE_NUMBER = "phoneNumber",
  PHONE_COUNTRY = "phoneCountry",
  PHONE_CODE = "phoneCode",
  COUNTRY = "country",
  IS_NOT_USA_RESIDENT = "isNotUSAResident",
}

type FormValues = {
  [ProfileFields.FIRST_NAME]: string;
  [ProfileFields.LAST_NAME]: string;
  [ProfileFields.DATE_OF_BIRTH]: DateValue | null;
  [ProfileFields.PHONE_NUMBER]: string;
  [ProfileFields.PHONE_COUNTRY]: string;
  [ProfileFields.PHONE_CODE]: string;
  [ProfileFields.COUNTRY]: string;
  [ProfileFields.IS_NOT_USA_RESIDENT]: boolean;
};

type Props = {
  profile: ProfileInfo;
  platformInfo: PlatformInfo;
  address: AddressInfo;
  countriesInfo: CountriesInfo;
  onSubmit: (values: FormValues) => Promise<void>;
};

const ProfileForm: FC<Props> = ({ countriesInfo, profile, address, onSubmit, platformInfo }) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      [ProfileFields.FIRST_NAME]: profile.firstName || "",
      [ProfileFields.LAST_NAME]: profile.lastName || "",
      [ProfileFields.DATE_OF_BIRTH]: profile.dateOfBirth ? parseAbsoluteToLocal(profile.dateOfBirth) : null,
      [ProfileFields.PHONE_NUMBER]: address.manuallyPhoneNumber || "",
      [ProfileFields.PHONE_CODE]: address.phoneCode || "",
      [ProfileFields.PHONE_COUNTRY]: address.phoneCountry || "",
      [ProfileFields.COUNTRY]: address.country || "",
      [ProfileFields.IS_NOT_USA_RESIDENT]: address.isNotUSAResident || true,
    },
  });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <div className="flex flex-col gap-5">
        <NameField />
        <LastnameField />
        <DateField platformInfo={platformInfo} />
        <PhoneField countriesInfo={countriesInfo} />
        <CountrySelect countriesInfo={countriesInfo} />
        <NotUSACitizenField />
      </div>

      <StepWrapper.Footer isSingleButton>
        <SubmitButton>{t("button.next")}</SubmitButton>
      </StepWrapper.Footer>
    </HookForm>
  );
};

export { ProfileFields, ProfileForm };
