import { Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";

import { initializeSentry } from "@/app/libs/sentry";
import { GlobalLoader } from "@/components/loader";
import { AccountPage } from "@/features/account/page";
import { EmailConfirmPage } from "@/features/auth/components/email-confirm/email-confirm.page";
import { ForgotPasswordContainer } from "@/features/auth/components/forgot-password/forgot-password.container";
import { ResetPasswordPage } from "@/features/auth/components/reset-password/reset-password.page";
import { SignInContainer } from "@/features/auth/components/signin/signin.container";
import { SignUpContainer } from "@/features/auth/components/signup/signup.container";
import { CreateAccountPage } from "@/features/create-account/page";
import { DashboardPage } from "@/features/dashboard/dashboard.page";
import { HomePage } from "@/features/home/home.page";
import { MT4TerminalPage } from "@/features/mt-terminal/mt4-terminal.page";
import { MT5TerminalPage } from "@/features/mt-terminal/mt5-terminal.page";
import { NotificationsPage } from "@/features/notifications/notifications.page";
import { OnboardingPage } from "@/features/onboarding/onboarding.page";
import { SumsubPage } from "@/features/onboarding/sumsub.page";
import { DepositPage } from "@/features/payment/deposit/deposit.page";
import { DepositOptionsPage } from "@/features/payment/deposit/deposit-options.page";
import { IframeDepositPage } from "@/features/payment/deposit/iframe-deposit.page";
import { WithdrawalPage } from "@/features/payment/withdrawal/withdrawal.page";
import { WithdrawalOptionsPage } from "@/features/payment/withdrawal/withdrawal-options.page";
import { PrivacyPolicyPage } from "@/features/privacy-policy/privacy-policy.page";
import { ProfilePage } from "@/features/profile/page";
import TerminalPage from "@/features/terminal/terminal.page";
import TerminalChartPage from "@/features/terminal/terminal-chart.page";
import { TransactionsPage } from "@/features/transactions/transactions.page";
import { TransferPage } from "@/features/transfer/transfer.page";
import { TwoFactorPage } from "@/features/two-factor/page";
import { AuthLayout } from "@/layouts/auth/layout";
import { CabinetLayout } from "@/layouts/cabinet/cabinet.layout";
import { MetatraderLayout } from "@/layouts/metatrader/metatrader.layout";
import { OnboardingLayout } from "@/layouts/onboarding/onboarding.layout";
import { AuthDataProvider } from "@/providers/auth/auth-data.provider";
import { ProfileDataProvider } from "@/providers/profile-data.provider";
import { billingRoutes } from "@/routes/billing.routes";
import { UnauthenticatedRoute } from "@/routes/unauthenticated.route";

import { authRoutes } from "./auth.routes";
import { Cabinet } from "./cabinet";
import { cabinetRoutes } from "./cabinet.routes";
import { ErrorPage } from "./error.page";
import { onboardingRoutes } from "./onboarding.routes";
import { PrivateRoute } from "./private.route";
import { RootRoute } from "./root.route";
import { SocketRoute } from "./socket.route";
import { terminalRoutes } from "./terminal.routes";

// const TerminalPage = lazy(() => import("@/features/terminal/terminal.page"));

initializeSentry();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootRoute />} errorElement={<ErrorPage />}>
      <Route path={billingRoutes.depositIframe} element={<IframeDepositPage />} />
      <Route path={billingRoutes.privacyPolicy} element={<PrivacyPolicyPage />} />

      <Route
        element={
          <UnauthenticatedRoute>
            <AuthDataProvider>
              <AuthLayout />
            </AuthDataProvider>
          </UnauthenticatedRoute>
        }
      >
        <Route path={authRoutes.signUp} element={<SignUpContainer />} />
        <Route path={authRoutes.signIn} element={<SignInContainer />} />
        <Route path={authRoutes.forgotPassword} element={<ForgotPasswordContainer />} />
        <Route path={authRoutes.resetPassword} element={<ResetPasswordPage />} />
        <Route path={authRoutes.emailConfirm} element={<EmailConfirmPage />} />
      </Route>

      {/* routes for authenticated users: */}
      <Route
        element={
          <PrivateRoute>
            <SocketRoute />
          </PrivateRoute>
        }
      >
        <Route
          element={
            <ProfileDataProvider>
              <MetatraderLayout />
            </ProfileDataProvider>
          }
        >
          <Route path={cabinetRoutes.mt4Terminal} element={<MT4TerminalPage />} />
          <Route path={cabinetRoutes.mt5Terminal} element={<MT5TerminalPage />} />
        </Route>

        <Route
          element={
            <ProfileDataProvider>
              <OnboardingLayout />
            </ProfileDataProvider>
          }
        >
          <Route path={onboardingRoutes.onboarding} element={<OnboardingPage />} />
          <Route path={onboardingRoutes.kyc} element={<SumsubPage />} />
        </Route>
        <Route
          element={
            <Cabinet>
              <ProfileDataProvider>
                <CabinetLayout />
              </ProfileDataProvider>
            </Cabinet>
          }
        >
          <Route path={cabinetRoutes.home} element={<HomePage />} />
          <Route path={cabinetRoutes.myAccounts} element={<DashboardPage />} />
          <Route path={cabinetRoutes.createAccount} element={<CreateAccountPage />} />
          <Route path={cabinetRoutes.account} element={<AccountPage />} />

          <Route path={cabinetRoutes.deposit} element={<DepositPage />} />
          <Route path={cabinetRoutes.depositOptions} element={<DepositOptionsPage />} />

          <Route path={cabinetRoutes.withdrawal} element={<WithdrawalPage />} />
          <Route path={cabinetRoutes.withdrawalOptions} element={<WithdrawalOptionsPage />} />

          <Route path={cabinetRoutes.transfer} element={<TransferPage />} />
          <Route path={cabinetRoutes.transactionHistory} element={<TransactionsPage />} />
          <Route path={cabinetRoutes.notifications} element={<NotificationsPage />} />
          <Route path={cabinetRoutes.profileSettings} element={<ProfilePage />} />
          <Route path={cabinetRoutes.twoFactor} element={<TwoFactorPage />} />
        </Route>
        <Route
          element={
            <Suspense fallback={<GlobalLoader />}>
              <ProfileDataProvider />
            </Suspense>
          }
        >
          <Route path={terminalRoutes.terminal} element={<TerminalPage />} />
          <Route path={terminalRoutes.terminalMobile} element={<TerminalPage />} />
          <Route path={terminalRoutes.terminalMobileChart} element={<TerminalChartPage />} />
        </Route>
      </Route>

      {/* 404 */}
      <Route path="*" element={<ErrorPage notFound />} />
    </Route>,
  ),
);

export const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export { router };
