import { Command } from "cmdk";
import { type FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { createImagePathUrl } from "@/app/utils/images/helpers";
import type { PlatformCountry } from "@/services/openapi";
import { Image, Text, TextInput } from "@/shared/ui";

type ItemProps = {
  countryValue: string;
  onChangeCode: (value: string) => void;
  onChangeCountry: (value: string) => void;
  clearPhoneNumber: () => void;
  onClose: () => void;
  country: PlatformCountry;
};

const Item: FC<ItemProps> = ({
  country: { code, country, numberCode, flagUrlPath },
  clearPhoneNumber,
  countryValue,
  onChangeCode,
  onChangeCountry,
  onClose,
}) => {
  return (
    <Command.Item
      value={`${numberCode} ${country}`} // value for search
      onSelect={() => {
        if (countryValue !== code) {
          onChangeCode(numberCode!);
          onChangeCountry(code!);
          clearPhoneNumber();
        }
        onClose();
      }}
      className="flex cursor-pointer select-none items-center gap-3 p-4 outline-none transition-colors data-[active=true]:!bg-card-bg-active data-[selected=true]:bg-card-bg-hover"
      data-active={countryValue === code}
    >
      <Image src={createImagePathUrl(flagUrlPath!)} width={24} height={24} />
      <div className="flex min-w-0 grow items-center gap-1">
        <Text variant="S / Regular" color="secondary" className="min-w-[45px]">
          {numberCode}
        </Text>
        <Text variant="S / Regular" color="primary" className="truncate">
          {country}
        </Text>
      </div>
    </Command.Item>
  );
};

type Props = {
  onClose: () => void;
  countryValue: string;
  onChangeCode: (value: string) => void;
  onChangeCountry: (value: string) => void;
  clearPhoneNumber: () => void;
  countries: PlatformCountry[];
  selectedCountry: PlatformCountry | undefined;
};

const PhoneCombobox: FC<Props> = ({
  onClose,
  countryValue,
  countries,
  onChangeCode,
  onChangeCountry,
  clearPhoneNumber,
  selectedCountry,
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  const sortedCountries = useMemo(
    () => countries.toSorted((a, b) => a.country!.localeCompare(b.country!)),
    [countries],
  );

  return (
    <Command className="flex min-h-0 flex-col outline-none">
      <div className="p-4">
        <TextInput value={search} onChange={setSearch} placeholder={t("fields.phone-number.search-placeholder")!} />
        <Command.Input value={search} onValueChange={setSearch} className="hidden" />
      </div>
      <Command.List className="overflow-y-auto overscroll-contain scrollbar-hide">
        <Command.Empty className="px-4 pb-4">
          <Text color="secondary" variant="M / Regular">
            {t("fields.phone-number.search-empty-message")}
          </Text>
        </Command.Empty>
        {selectedCountry && (
          <Item
            country={selectedCountry}
            onClose={onClose}
            clearPhoneNumber={clearPhoneNumber}
            countryValue={countryValue}
            onChangeCode={onChangeCode}
            onChangeCountry={onChangeCountry}
          />
        )}
        {sortedCountries.map(country =>
          country.code === countryValue ? null : (
            <Item
              key={country.code}
              country={country}
              onClose={onClose}
              clearPhoneNumber={clearPhoneNumber}
              countryValue={countryValue}
              onChangeCode={onChangeCode}
              onChangeCountry={onChangeCountry}
            />
          ),
        )}
      </Command.List>
    </Command>
  );
};

export { PhoneCombobox };
