import type { FC } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { IconFacebookColor } from "@/domains/icons";
import type { PlatformInfo } from "@/services/openapi";
import { IconButton } from "@/shared/ui";

import { useFacebookSignIn } from "./helpers";

type Props = {
  platformInfo: PlatformInfo;
};

const OAuthFacebookButton: FC<Props> = ({ platformInfo }) => {
  const { callback } = useFacebookSignIn();

  return (
    <FacebookLogin
      appId={platformInfo.signIn!.facebook!.appId!}
      disableMobileRedirect
      callback={callback}
      render={({ onClick }) => (
        <IconButton variant="secondary" onClick={onClick}>
          <IconFacebookColor />
        </IconButton>
      )}
    />
  );
};

export { OAuthFacebookButton };
