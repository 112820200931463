import { Collapsible } from "radix-ui";
import { type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronDown } from "@/domains/icons";
import { SymbolIcon } from "@/features/terminal/symbols/icon";
import { TerminalDealType } from "@/services/openapi";
import { Text, UnstyledButton } from "@/shared/ui";

import { OrderTypeFormat } from "../../ui/order-type-format";

type Props = {
  header: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
};

const Card: FC<Props> = ({ body, footer, header }) => (
  <Collapsible.Root className="flex flex-col rounded-lg border border-card-border bg-card-bg p-4">
    <Collapsible.Trigger asChild>
      <UnstyledButton className="grid grid-cols-[auto_1fr_auto] items-start gap-3 outline-none">
        {header}
      </UnstyledButton>
    </Collapsible.Trigger>
    <Collapsible.Content className="collapsible">
      <div className="mt-3">{body}</div>
    </Collapsible.Content>
    {footer && <div className="mt-4">{footer}</div>}
  </Collapsible.Root>
);

const Header: FC<{
  symbol: string;
  symbolUrlPath: string;
  type: TerminalDealType;
  volume: ReactNode;
  endTopSection: ReactNode;
  endBottomSection: ReactNode;
}> = ({ endBottomSection, endTopSection, symbol, symbolUrlPath, type, volume }) => {
  const { t } = useTranslation();

  return (
    <>
      <SymbolIcon symbol={symbol} size="lg" symbolUrlPath={symbolUrlPath} />
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <Text as="h5" variant="M / Medium" color="primary">
            {symbol}
          </Text>
          {endTopSection}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Text variant="S / Regular" color="primary">
              <OrderTypeFormat type={type} />
            </Text>
            <Text variant="S / Regular" color="primary">
              {volume} {t("terminal.lots")}
            </Text>
          </div>
          <Text variant="S / Regular" color="primary">
            {endBottomSection}
          </Text>
        </div>
      </div>
      <IconChevronDown className="size-6" />
    </>
  );
};

const Component = Object.assign(Card, {
  Header,
});

export { Component as TradingCard };
