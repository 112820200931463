import type { FC } from "react";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { AccountSelect } from "@/entities/accounts/select";
import { PaymentFooter } from "@/features/payment/ui/footer";
import { getWithdrawalRoute } from "@/routes/cabinet.routes";
import type { TradingAccount } from "@/services/openapi";
import { Button, Text } from "@/shared/ui";

type Props = {
  accounts: TradingAccount[];
  lastAccount: TradingAccount;
  path: string;
};

const WithdrawalPageForm: FC<Props> = ({ accounts, lastAccount, path }) => {
  const { t } = useTranslation();
  const [accountId, setAccountId] = useState(lastAccount.id!);

  const account = useMemo(() => accounts.find(({ id }) => id === accountId)!, [accounts, accountId]);

  return (
    <>
      <AccountSelect accounts={accounts} accountId={accountId} setAccountId={setAccountId} />
      <Text variant="S / Regular" className="mt-2" color="secondary">
        <Trans
          i18nKey="withdrawal.form.available"
          components={{
            click: <></>,
            value: <NumberFormat value={account.balance} decimalScale={account.digits} currency={account.currency!} />,
          }}
        />
      </Text>
      <PaymentFooter>
        <Button fullWidth asChild>
          <Link to={{ pathname: getWithdrawalRoute(accountId), search: createSearchParams({ from: path }).toString() }}>
            {t("button.next")}
          </Link>
        </Button>
      </PaymentFooter>
    </>
  );
};

export { WithdrawalPageForm as WithdrawalForm };
