import { type FC, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { HomeBlocksType } from "@/services/openapi";
import { useHomeQuery } from "@/state/server/home";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { CongratulationsDialog } from "./components/dialogs/congratulations/congratulations-dialog";
import { VerificationDialog } from "./components/dialogs/verification/verification-dialog";
import { EducationContainer } from "./education/container";
import { TopMoversContainer } from "./movers/container";
import { HomeNewsContainer } from "./news/container";

const HomePage: FC = () => {
  const { t } = useTranslation();
  const { firstName, homeInfo } = useProfileData();

  const isHomeAvailable = homeInfo!.isHomeAvailable!;

  const { data: homeData } = useHomeQuery();

  useEffect(() => {
    if (isHomeAvailable) {
      track(amplitudeEvents.home.pageVisit);
    }
  }, []);

  if (!isHomeAvailable) {
    return <Navigate to={cabinetRoutes.myAccounts} replace />;
  }

  if (!homeData) {
    return <CabinetPage.Loader />;
  }

  return (
    <CabinetPage
      header={
        <CabinetHeader>
          <CabinetHeader.Title>
            {firstName ? t("home.title-name", { name: firstName }) : t("home.title")}
          </CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <div className="flex flex-col gap-8">
        {homeData.types!.map(type => {
          switch (type) {
            case HomeBlocksType.News:
              return <HomeNewsContainer key={type} news={homeData.news!.items!} />;
            case HomeBlocksType.TopMovers:
              return <TopMoversContainer key={type} topMovers={homeData.topMovers!} />;
            case HomeBlocksType.Education:
              return <EducationContainer key={type} education={homeData.education!.items!} />;
            default:
              return null;
          }
        })}
      </div>

      <CongratulationsDialog />
      <VerificationDialog />
    </CabinetPage>
  );
};

export { HomePage };
