import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import type { PasswordInfo } from "@/services/openapi";
import { PasswordInput } from "@/shared/ui";

enum Fields {
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirmPassword",
}

type FormValues = {
  [Fields.PASSWORD]: string;
  [Fields.CONFIRM_PASSWORD]: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
  passwordRules: PasswordInfo;
};

const ResetPasswordForm: FC<Props> = ({ onSubmit, passwordRules }) => {
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.PASSWORD]: "",
      [Fields.CONFIRM_PASSWORD]: "",
    },
  });

  const { watch, control } = form;

  const password = watch(Fields.PASSWORD);

  const { strong, maxLength } = passwordRules;

  const [
    confirmField,
    {
      invalid: confirmInvalid,
      pending: confirmPassword,
      errorMessage: errorMessageConfirm,
      isTouched: confirmIsTouched,
    },
  ] = useControlledField<FormValues>({
    name: Fields.CONFIRM_PASSWORD,
    control,
    rules: {
      required: t("profile-settings.change-password.required-error")!,
      validate: {
        matchesPreviousPassword: (value: string) =>
          password === value || t("profile-settings.change-password.not-match-error")!,
      },
    },
  });

  const [passwordField, { invalid: passwordInvalid, pending: pendingPassword, errorMessage: errorMessagePassword }] =
    useControlledField<FormValues>({
      name: Fields.PASSWORD,
      control,
      rules: {
        required: t("profile-settings.change-password.required-error")!,
        pattern: {
          value: new RegExp(strong!.mask!),
          message: t("profile-settings.change-password.regexp-error")!,
        },
        deps: confirmIsTouched ? [Fields.CONFIRM_PASSWORD] : void 0,
      },
    });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <div className="mb-8 flex flex-col gap-4">
        <PasswordInput
          autoComplete="new-password"
          label={t("auth.reset-password.password")}
          pending={pendingPassword}
          invalid={passwordInvalid}
          descriptor={
            focused && !passwordInvalid ? t("profile-settings.change-password.regexp-error")! : errorMessagePassword
          }
          maxLength={maxLength}
          {...passwordField}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            passwordField.onBlur();
            setFocused(false);
          }}
        />
        <PasswordInput
          autoComplete="new-password"
          label={t("auth.reset-password.confirm-password")}
          pending={confirmPassword}
          invalid={confirmInvalid}
          descriptor={errorMessageConfirm}
          maxLength={maxLength}
          {...confirmField}
        />
      </div>
      <SubmitButton fullWidth>{t("auth.reset-password.action")}</SubmitButton>
    </HookForm>
  );
};

export { ResetPasswordForm };
