import { type ComponentProps, type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { Text, type TextColor } from "@/shared/ui";

import { usePriceChange } from "../../symbols/helpers";
import { SymbolPriceChange } from "../../symbols/price-change";

const Divider: FC = () => {
  return <div className="h-10 w-px bg-contrast-quaternary" />;
};

const Item: FC<{
  label?: ReactNode;
  value?: ReactNode;
  valueColor?: TextColor;
  align?: ComponentProps<typeof Text>["align"];
}> = ({ label, value, align, valueColor = "primary" }) => {
  return (
    <div className="flex flex-col gap-1">
      <Text color="secondary" variant="S / Regular" align={align}>
        {label}
      </Text>
      <Text as="div" color={valueColor} variant="M / Medium" align={align}>
        {value}
      </Text>
    </div>
  );
};

type Props = {
  priceLow24H: number | null;
  priceLast24H: number | null;
  priceHigh24H: number | null;
  priceBid: number | null;
  priceDecimalScale: number;
};

const SymbolInfoPriceChangeBlock: FC<Props> = ({
  priceHigh24H,
  priceLast24H,
  priceLow24H,
  priceDecimalScale,
  priceBid,
}) => {
  const { t } = useTranslation();

  const priceChange = usePriceChange({ bid: priceBid, priceLast: priceLast24H });

  return (
    <div className="grid grid-cols-[1fr_auto_1fr_auto_1fr] items-center gap-2 lg:px-4">
      <Item
        align="start"
        label={t("terminal.symbol-info.24h-change")}
        value={
          priceChange ? (
            <SymbolPriceChange priceChange={priceChange}>
              {({ value, icon }) => (
                <div className="flex items-center gap-1">
                  {icon}
                  {value}
                </div>
              )}
            </SymbolPriceChange>
          ) : (
            "–"
          )
        }
        valueColor={priceChange ? priceChange.color : void 0}
      />
      <Divider />
      <Item
        align="center"
        label={t("terminal.symbol-info.24h-high")}
        value={priceHigh24H !== null ? <NumberFormat value={priceHigh24H} decimalScale={priceDecimalScale} /> : "–"}
      />
      <Divider />
      <Item
        align="end"
        label={t("terminal.symbol-info.24h-low")}
        value={priceLow24H !== null ? <NumberFormat value={priceLow24H} decimalScale={priceDecimalScale} /> : "–"}
      />
    </div>
  );
};

export { SymbolInfoPriceChangeBlock };
