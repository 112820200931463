import { type ComponentPropsWithoutRef, type FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

// TODO: There is similar logic in open chart line in formatPnl fn

type Props = OverrideProps<
  ComponentPropsWithoutRef<typeof NumericFormat>,
  {
    currency?: string;
    hideCurrency?: boolean;
    showFullValue?: boolean;
    value?: number | null;
  }
>;

const NumberFormat: FC<Props> = ({
  displayType = "text",
  thousandSeparator = ",",
  currency,
  fixedDecimalScale = true,
  showFullValue = false,
  hideCurrency = false,
  decimalScale,
  value,
  prefix,
  ...props
}) => {
  const { i18n } = useTranslation();

  const currencyBefore = i18n.resolvedLanguage === "pt" || i18n.resolvedLanguage === "ms"; // whether currency should be placed before the value

  if (!showFullValue && currency === "IDR" && typeof value === "number") {
    const shouldTruncateValue = Math.abs(value) > 1000;
    const reductionSymbol = shouldTruncateValue ? "k" : "";

    return (
      <NumericFormat
        thousandSeparator={thousandSeparator}
        displayType={displayType}
        prefix={currencyBefore && !hideCurrency ? `${currency} ${prefix ? prefix : ""}` : prefix}
        suffix={!currencyBefore && !hideCurrency ? `${reductionSymbol} ${currency}` : reductionSymbol}
        renderText={value => value}
        decimalScale={0}
        value={shouldTruncateValue ? Math.trunc(value / 1000) : value}
        {...props}
      />
    );
  }

  return (
    <NumericFormat
      fixedDecimalScale={fixedDecimalScale}
      thousandSeparator={thousandSeparator}
      displayType={displayType}
      prefix={currencyBefore && currency && !hideCurrency ? `${currency} ${prefix ? prefix : ""}` : prefix}
      suffix={!currencyBefore && currency && !hideCurrency ? ` ${currency}` : void 0}
      renderText={value => value}
      decimalScale={decimalScale}
      value={value}
      {...props}
    />
  );
};

const Component = memo(NumberFormat);

export { Component as NumberFormat };
