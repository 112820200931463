import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { useLocationPath } from "@/app/hooks";
import { IconArrowTransfer, IconChart, IconDeposit2, IconWithdraw2 } from "@/domains/icons";
import { getDepositRoute, getTransferRoute, getWithdrawalRoute } from "@/routes/cabinet.routes";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { TileButton } from "@/shared/ui";

import { AccountButtonWrapper } from "./wrapper";

type Props = {
  account: TradingAccount;
};

const AccountRealButtons: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const { id, platform, actions } = account;

  const path = useLocationPath();

  const { canTransferMoney } = actions!;

  const buttonsCount = 2 + Number(canTransferMoney) + Number(platform == TradingServerPlatform.MetaTrader5);
  const isEven = buttonsCount % 2 === 0;

  return (
    <AccountButtonWrapper isEven={isEven}>
      <TileButton variant="secondary" icon={<IconDeposit2 />} asChild>
        <Link to={{ pathname: getDepositRoute(id!), search: createSearchParams({ from: path }).toString() }}>
          {t("accounts.buttons.deposit")}
        </Link>
      </TileButton>
      <TileButton variant="secondary" icon={<IconWithdraw2 />} asChild>
        <Link to={{ pathname: getWithdrawalRoute(id!), search: createSearchParams({ from: path }).toString() }}>
          {t("accounts.buttons.withdraw")}
        </Link>
      </TileButton>
      {canTransferMoney && (
        <TileButton variant="secondary" icon={<IconArrowTransfer />} asChild>
          <Link to={getTransferRoute(id!, path)}>{t("accounts.buttons.transfer")}</Link>
        </TileButton>
      )}
      {platform == TradingServerPlatform.MetaTrader5 && (
        <TileButton variant="secondary" icon={<IconChart />} asChild>
          <Link to={getTerminalRoute(id!)}>{t("accounts.buttons.trade")}</Link>
        </TileButton>
      )}
    </AccountButtonWrapper>
  );
};

export { AccountRealButtons };
