import { type FC, type ReactNode } from "react";

import { cn } from "@/shared/styles";

type Props = {
  children: ReactNode;
  isEven: boolean;
};

const AccountButtonWrapper: FC<Props> = ({ children, isEven }) => {
  return (
    <div
      className={cn("grid gap-3", {
        "grid-cols-2 md:grid-auto-fit-28": isEven,
        "grid-auto-fit-[100px]": !isEven,
      })}
    >
      {children}
    </div>
  );
};

export { AccountButtonWrapper };
