import { cva } from "class-variance-authority";
import { type FC, type HTMLAttributeAnchorTarget, memo, type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link, NavLink, useLocation } from "react-router-dom";

import { Logo } from "@/app/components";
import { useLocationPath } from "@/app/hooks";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { toggleLiveChat } from "@/app/libs/intercom";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { getTerminalRoute, isCurrentPageTerminal } from "@/routes/terminal.routes";
import { Button, Skeleton as UISkeleton, UnstyledButton } from "@/shared/ui";

const linkStyles = cva(
  "typography-S-Medium rounded-full px-3 py-1 text-contrast-secondary outline-none transition-colors hover:text-contrast-primary focus-visible:text-contrast-primary focus-visible:outline-2 focus-visible:outline-accent-text active:text-contrast-primary",
  {
    variants: {
      active: {
        true: "!text-contrast-primary",
      },
    },
  },
);

const Frame: FC<{ linksSlot: ReactNode; buttonsSlot: ReactNode }> = ({ linksSlot, buttonsSlot }) => {
  return (
    <header className="flex h-header-height shrink-0 items-center justify-between bg-surface-canvas pe-4 ps-6">
      <div className="flex items-center gap-10">
        <div className="flex items-center gap-4">
          <Logo />
          {/* TODO: theme switch */}
        </div>
        <div className="flex items-center gap-2">{linksSlot}</div>
      </div>
      <div className="flex items-center gap-6">{buttonsSlot}</div>
    </header>
  );
};

const Skeleton = () => (
  <Frame
    linksSlot={new Array(5).fill("").map((_, i) => (
      <UISkeleton key={i} className="h-5 w-[72px] rounded-full" />
    ))}
    buttonsSlot={
      <div className="flex items-center gap-3">
        <UISkeleton className="h-10 w-24 rounded-full" />
        <UISkeleton className="h-10 w-24 rounded-full" />
      </div>
    }
  />
);

type Props = {
  isReadOnly: boolean;
  isHomeAvailable: boolean;
  hideTrading?: boolean;
  blankLinks?: boolean;
  children?: ReactNode;
};

const Header: FC<Props> = ({ children, isHomeAvailable, hideTrading = false, blankLinks = false, isReadOnly }) => {
  const { t } = useTranslation();

  const location = useLocation();

  const currentLocation = useLocationPath();

  const isTerminalActive = useMemo(() => isCurrentPageTerminal(location.pathname), [location.pathname]);

  const target: HTMLAttributeAnchorTarget | undefined = blankLinks ? "_blank" : void 0;

  return (
    <Frame
      linksSlot={
        <>
          {isHomeAvailable && (
            <NavLink
              className={({ isActive }) => linkStyles({ active: isActive })}
              to={cabinetRoutes.home}
              data-test="navlink-home"
              target={target}
            >
              {t("cabinet.navigation.home")}
            </NavLink>
          )}
          {!hideTrading && (
            <Link
              className={linkStyles({
                active: isTerminalActive,
              })}
              to={isTerminalActive ? currentLocation : getTerminalRoute(null)}
              data-test="navlink-terminal"
              target={target}
            >
              {t("cabinet.navigation.terminal")}
            </Link>
          )}
          <NavLink
            className={({ isActive }) => linkStyles({ active: isActive })}
            to={cabinetRoutes.myAccounts}
            data-test="navlink-accounts"
            target={target}
          >
            {t("cabinet.navigation.accounts")}
          </NavLink>
          <NavLink
            className={({ isActive }) => linkStyles({ active: isActive })}
            to={cabinetRoutes.profileSettings}
            data-test="navlink-profile-settings"
            target={target}
          >
            {t("cabinet.navigation.profile-settings")}
          </NavLink>
          <UnstyledButton data-test="navlink-support" onClick={toggleLiveChat} className={linkStyles()}>
            {t("cabinet.navigation.support")}
          </UnstyledButton>
        </>
      }
      buttonsSlot={
        <>
          {children}
          {!isReadOnly && (
            <div className="flex items-center gap-3">
              <Button size="sm" variant="secondary" asChild>
                <Link
                  to={{
                    pathname: cabinetRoutes.withdrawal,
                    search: createSearchParams({ from: currentLocation }).toString(),
                  }}
                  data-test="navlink-withdrawal"
                  target={target}
                >
                  {t("cabinet.navigation.withdraw-button")}
                </Link>
              </Button>
              <Button size="sm" asChild>
                <Link
                  to={{
                    pathname: cabinetRoutes.deposit,
                    search: createSearchParams({ from: currentLocation }).toString(),
                  }}
                  data-test="navlink-deposit"
                  target={target}
                >
                  {t("cabinet.navigation.deposit")}
                </Link>
              </Button>
            </div>
          )}
        </>
      }
    />
  );
};

const Component = Object.assign(memo(Header), {
  Skeleton,
});

export { Component as Header };
