import type { ComponentProps, FC } from "react";
import { useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { track } from "@/app/libs/amplitude";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import {
  type CreateTradingAccountResult,
  TradingAccountType,
  type TradingServer,
  TradingServerPlatform,
} from "@/services/openapi";
import { Dialog } from "@/shared/ui";
import { useCreateTradingAccountMutation } from "@/state/server/accounts";

import { CreateAccountForm } from "./form";
import { getCreateAccountSubmitValue } from "./helpers";
import { CreateAccountResultMT4Container } from "./result/mt4/container";
import { CreateAccountResultMT5Container } from "./result/mt5/container";

type Props = {
  type: TradingAccountType;
  servers: TradingServer[];
};

const CreateAccountContainer: FC<Props> = ({ servers, type }) => {
  const [accountResult, setAccountResult] = useState<CreateTradingAccountResult | null>(null);

  const [opened, { open, close }] = useDisclosure();

  const navigate = useNavigate();

  const { mutateAsync: createAccount } = useCreateTradingAccountMutation();

  const handleSubmit: ComponentProps<typeof CreateAccountForm>["onSubmit"] = useCallback(
    ({ platform, type, currency }) => {
      return createAccount(
        { createTradingAccountRequest: getCreateAccountSubmitValue({ platform, type, servers, currency }) },
        {
          onSuccess: accountResult => {
            track("open_new_real_account");
            setAccountResult(accountResult);
            open();
          },
        },
      );
    },
    [createAccount, servers, open],
  );

  const onCloseWithRedirect = useCallback(() => {
    close();
    navigate({
      pathname: cabinetRoutes.myAccounts,
      search: createSearchParams({ tab: accountResult?.type! }).toString(),
    });
  }, [navigate, close, accountResult?.type]);

  return (
    <>
      <CreateAccountForm servers={servers} onSubmit={handleSubmit} type={type} />
      <Dialog open={opened} onOpenChange={onCloseWithRedirect}>
        {accountResult && (
          <Dialog.Content>
            {accountResult.platform === TradingServerPlatform.MetaTrader5 ? (
              <CreateAccountResultMT5Container
                onClose={close}
                onCloseWithRedirect={onCloseWithRedirect}
                accountResult={accountResult}
              />
            ) : (
              <CreateAccountResultMT4Container
                onClose={close}
                onCloseWithRedirect={onCloseWithRedirect}
                accountResult={accountResult}
              />
            )}
          </Dialog.Content>
        )}
      </Dialog>
    </>
  );
};

export { CreateAccountContainer };
