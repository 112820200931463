import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

import { useLocationPath } from "@/app/hooks";
import { setWebviewSentry } from "@/app/libs/sentry";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountType } from "@/services/openapi";

type Insights = "analytics" | "calendar" | "news" | "none";

enum TerminalTableState {
  NONE = "none",
  OPEN = "openPositions",
  PENDING = "pendingPositions",
  CLOSED_POSITIONS = "closedPositions",
}

type ContextProps = {
  watchlistSelectedSymbol: string | null;
  demoDialogOpen: boolean;
  closeDemoDialog: () => void;
  onDeposit: () => void;
  table: TerminalTableState;
  changeTable: (table: TerminalTableState) => void;
  watchlistOpen: boolean;
  isMobile: boolean;
  isMobileAppMode: boolean;
  openWatchlist: (symbol?: string) => void;
  closeWatchlist: () => void;
  watchlistOnChange: (value: boolean) => void;
  closeTable: () => void;
  toggleTable: () => void;
  isChartExpanded: boolean;
  setIsChartExpanded: SetState<boolean>;
  isMobileChartPage: boolean;
  // insights
  insightsSection: Insights;
  changeInsightsSection: (value: Insights) => void;
};

const Context = createContext<ContextProps | undefined>(undefined);

// TODO: remove
const webviewChannel = window["WEBVIEW_CHANNEL"];

const Provider = memo(
  ({
    children,
    accountType,
    isMobileAppMode,
    isMobile,
    isMobileChartPage,
  }: {
    children: React.ReactNode;
    accountType: TradingAccountType;
    isMobileAppMode: boolean;
    isMobile: boolean;
    isMobileChartPage: boolean;
  }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const path = useLocationPath();

    const tab = searchParams.get("tab");
    const table = useMemo<ContextProps["table"]>(() => {
      if (tab === TerminalTableState.OPEN) {
        return TerminalTableState.OPEN;
      }
      if (tab === TerminalTableState.PENDING) {
        return TerminalTableState.PENDING;
      }
      if (tab === TerminalTableState.CLOSED_POSITIONS) {
        return TerminalTableState.CLOSED_POSITIONS;
      }

      return TerminalTableState.NONE;
    }, [tab]);

    const section = searchParams.get("insightsSection");
    const insightsSection: ContextProps["insightsSection"] = useMemo(() => {
      if (section === "news") {
        return "news";
      }
      if (section === "analytics") {
        return "analytics";
      }
      if (section === "calendar") {
        return "calendar";
      }
      return "none";
    }, [section]);

    const changeInsightsSection: ContextProps["changeInsightsSection"] = useCallback(
      value => {
        setSearchParams(params => {
          params.set("insightsSection", value);
          return params;
        });
      },
      [setSearchParams],
    );

    const [demoDialogOpen, { close: closeDemoDialog, open: openDemoDialog }] = useDisclosure();

    const [watchlistSelectedSymbol, setWatchlistSelectedSymbol] =
      useState<ContextProps["watchlistSelectedSymbol"]>(null);

    const [watchlistOpen, { open, close: closeWatchlist, onOpenChange }] = useDisclosure(false);

    const openWatchlist: ContextProps["openWatchlist"] = useCallback(
      symbol => {
        open();
        setWatchlistSelectedSymbol(symbol ? symbol : null);
      },
      [open],
    );

    const watchlistOnChange: ContextProps["watchlistOnChange"] = useCallback(
      flag => {
        onOpenChange(flag);
        setWatchlistSelectedSymbol(null);
      },
      [onOpenChange],
    );

    const { isDesktop } = useScreenSize();

    const [_isChartExpanded, setIsChartExpanded] = useState<ContextProps["isChartExpanded"]>(false);
    const isChartExpanded = _isChartExpanded || isDesktop;

    const navigate = useNavigate();

    const changeTable: ContextProps["changeTable"] = useCallback(
      table => {
        setSearchParams(params => {
          params.set("tab", table);
          return params;
        });
      },
      [setSearchParams],
    );

    const toggleTable = useCallback(() => {
      if (table === TerminalTableState.NONE) {
        changeTable(TerminalTableState.OPEN);
      } else {
        changeTable(TerminalTableState.NONE);
      }
    }, [table, changeTable]);

    const closeTable = useCallback(() => {
      changeTable(TerminalTableState.NONE);
    }, [changeTable]);

    useEffect(() => {
      setWebviewSentry(isMobileAppMode);
    }, [isMobileAppMode]);

    const onDeposit: ContextProps["onDeposit"] = useCallback(() => {
      const isDemoAccount = accountType === TradingAccountType.Demo;

      if (isDemoAccount) {
        openDemoDialog();
        return;
      }

      navigate({ pathname: cabinetRoutes.deposit, search: createSearchParams({ from: path }).toString() });
    }, [accountType, navigate, openDemoDialog, path]);

    const value: ContextProps = useMemo(
      () => ({
        insightsSection,
        changeInsightsSection,
        closeDemoDialog,
        demoDialogOpen,
        watchlistOpen,
        onDeposit,
        isMobileAppMode,
        table,
        isMobile,
        toggleTable,
        watchlistOnChange,
        closeTable,
        changeTable,
        openWatchlist,
        closeWatchlist,
        setIsChartExpanded,
        isChartExpanded,
        isMobileChartPage,
        watchlistSelectedSymbol,
      }),
      [
        insightsSection,
        changeInsightsSection,
        onDeposit,
        demoDialogOpen,
        closeDemoDialog,
        closeTable,
        isMobile,
        isMobileAppMode,
        table,
        toggleTable,
        watchlistOpen,
        openWatchlist,
        closeWatchlist,
        watchlistOnChange,
        changeTable,
        setIsChartExpanded,
        isChartExpanded,
        isMobileChartPage,
        watchlistSelectedSymbol,
      ],
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
  },
);

Provider.displayName = "TerminalLayoutContextProvider";

const useLayoutContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a TerminalLayoutContextProvider");
  }

  return context;
};

export { Provider as TerminalLayoutContextProvider, TerminalTableState, useLayoutContext, webviewChannel };
export type { Insights };
