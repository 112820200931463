import type { ComponentPropsWithoutRef, ElementRef, FC, ReactNode } from "react";
import { forwardRef } from "react";

import { IconClose } from "@/domains/icons";
import { cn } from "@/shared/styles";
import { centeredDialogContentStyles, Dialog, IconButton, Text } from "@/shared/ui";

type HeaderProps = {
  title: ReactNode;
  hideClose?: boolean;
};

const Header: FC<HeaderProps> = ({ title, hideClose }) => {
  return (
    <div className="flex items-start justify-between gap-2 pb-2 pt-1">
      <Text as="h3" className="py-2" variant="L / Medium" color="primary">
        {title}
      </Text>
      <div className="-me-2 flex items-center justify-end">
        {!hideClose && (
          <Dialog.Close asChild>
            <IconButton size="sm" variant="flat">
              <IconClose />
            </IconButton>
          </Dialog.Close>
        )}
      </div>
    </div>
  );
};

const Buttons: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div className="mt-6 flex flex-row-reverse flex-wrap gap-x-2 gap-y-3 *:flex-1">{children}</div>;
};

const Content = forwardRef<
  ElementRef<typeof Dialog.PrimitiveContent>,
  Omit<ComponentPropsWithoutRef<typeof Dialog.PrimitiveContent>, "className">
>((props, ref) => (
  <Dialog.PrimitiveContent
    className={cn(
      centeredDialogContentStyles(),
      "absolute w-[min(320px,_90vw)] rounded-xl bg-surface-elevation-2 px-6 pb-6 pt-4 shadow-modal outline-none",
    )}
    ref={ref}
    {...props}
  />
));
Content.displayName = Dialog.PrimitiveContent.displayName;

const Component = {
  Content,
  Buttons,
  Header,
};

export { Component as ChartDialog };
