import type { ComponentProps } from "react";

import { ClientButtonColor } from "@/services/openapi";
import type { Button } from "@/shared/ui";

const backendButtonStyles: Record<ClientButtonColor, Pick<ComponentProps<typeof Button>, "color" | "variant">> = {
  [ClientButtonColor.Default]: { color: "accent", variant: "primary" },
  [ClientButtonColor.Inverted]: { color: "accent", variant: "secondary" },
  [ClientButtonColor.Success]: { color: "positive", variant: "primary" },
  [ClientButtonColor.Warning]: { color: "warning", variant: "primary" },
  [ClientButtonColor.Danger]: { color: "negative", variant: "primary" },
};

export { backendButtonStyles };
