import type { FC } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { authRoutes } from "@/routes/auth.routes";

import { ResetPasswordContainer } from "./reset-password.container";

const ResetPasswordPage: FC = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const code = searchParams.get("code");

  if (!userId || !code) {
    return <Navigate to={authRoutes.signIn} replace />;
  }

  return <ResetPasswordContainer userId={userId} code={code} />;
};

export { ResetPasswordPage };
