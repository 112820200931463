import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { IconCross } from "@/domains/icons";

import { _Button, type _ExternalWrapperProps, _Input, _Wrapper } from "../_base";

type Props = Omit<_ExternalWrapperProps, "hasInputValue"> &
  OverrideProps<
    Omit<ComponentPropsWithoutRef<typeof _Input>, "size">,
    {
      value: string;
      onChange?: (value: string) => void;
      clearable?: boolean;
    }
  >;

const TextInput = forwardRef<ElementRef<typeof _Input>, Props>(
  (
    {
      value,
      onChange,
      className,
      label,
      startSection,
      disabled,
      invalid,
      pending,
      clearable,
      descriptor,
      endSection,
      floatingLabel = true,
      size,
      ...props
    },
    ref,
  ) => {
    const showClearButton = clearable && onChange && value !== "";

    const hasEndSection = !!(showClearButton || endSection);

    return (
      <_Wrapper
        type="input"
        className={className}
        startSection={startSection}
        invalid={invalid}
        pending={pending}
        label={label}
        disabled={disabled}
        descriptor={descriptor}
        floatingLabel={floatingLabel}
        hasInputValue={value !== ""}
        size={size}
        endSection={
          hasEndSection ? (
            <>
              {showClearButton && (
                <_Button disabled={disabled} onClick={() => onChange("")}>
                  <IconCross />
                </_Button>
              )}
              {endSection}
            </>
          ) : null
        }
      >
        <_Input
          disabled={disabled}
          value={value}
          onChange={onChange ? e => onChange(e.target.value) : void 0}
          {...props}
          ref={ref}
        />
      </_Wrapper>
    );
  },
);

const Component = Object.assign(TextInput, {
  IconButton: _Button,
});

export { Component as TextInput };
