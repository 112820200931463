interface ISkipTake {
  skip: number;
  take: number;
}
interface IPagingFilters {
  page: number;
  pageSize: number;
}

const calculateSkipTake = ({ page, pageSize }: IPagingFilters): ISkipTake => {
  const skip = (page - 1) * pageSize;
  return { skip, take: pageSize };
};

type OmitSkipTake<T> = Omit<T, "skip" | "take">;

type RequestParamsWithPaging<T = object> = OmitSkipTake<T> & IPagingFilters;

const getPagingRequestParams = <T>(requestParams: RequestParamsWithPaging<T>) => {
  const { page, pageSize, ...restFilters } = requestParams;
  const skipTake = calculateSkipTake({
    page,
    pageSize,
  });
  return { ...restFilters, ...skipTake };
};

export { getPagingRequestParams };
export type { ISkipTake, RequestParamsWithPaging };
