import Big from "big.js";
import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { IconChevronRight } from "@/domains/icons";
import { BonusDialog } from "@/entities/bonuses/dialog/bonus-dialog";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { BonusUserPlatform, TradingAccount } from "@/services/openapi";
import { Dialog, Text, TextButton } from "@/shared/ui";

import { BonusDialogContent } from "./bonus-dialog-content";

type Props = {
  account: TradingAccount;
  bonusBalance: number;
  bonus: BonusUserPlatform;
};

const TransferBonusBlock: FC<Props> = ({ account, bonusBalance, bonus }) => {
  const { t } = useTranslation();
  const { currency, credit, digits } = account;
  const lostBonusValue = new Big(credit!).minus(bonusBalance).toNumber();
  const [localDialogOpened, { open: openLocalDialog, close: closeLocalDialog }] = useDisclosure();
  const [bonusDialogOpened, { open: openBonusDialog, close: closeBonusDialog }] = useDisclosure();

  const handleBonusDialogOpen = () => {
    closeLocalDialog();
    openBonusDialog();
  };

  return (
    <>
      <div className="rounded-lg bg-card-bg-accent p-4">
        <Text variant="S / Regular" color="primary" className="mb-2.5">
          <Trans
            i18nKey="transfer.bonus.text-description"
            values={{
              accountId: account.login,
            }}
            components={{
              amount: <NumberFormat value={lostBonusValue} currency={currency!} decimalScale={digits} />,
            }}
          />
        </Text>
        <TextButton variant="positive" icon={<IconChevronRight />} underline onClick={openLocalDialog}>
          {t("transfer.bonus.details")}
        </TextButton>
      </div>

      <Dialog open={localDialogOpened} onOpenChange={closeLocalDialog}>
        <Dialog.Content>
          <BonusDialogContent openBonusDialog={handleBonusDialogOpen} />
        </Dialog.Content>
      </Dialog>

      <Dialog open={bonusDialogOpened} onOpenChange={closeBonusDialog}>
        <BonusDialog bonus={bonus} />
      </Dialog>
    </>
  );
};

export { TransferBonusBlock };
