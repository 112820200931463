import { useEffect, useState } from "react";

import { calculateSymbolPriceChange } from "../helpers/formulas";

const usePriceChange = ({
  bid,
  priceLast,
}: {
  bid: number | undefined | null;
  priceLast: number | null | undefined;
}) => {
  const [priceChange, setPriceChange] = useState(() => calculateSymbolPriceChange({ bid, priceLast }));

  useEffect(() => {
    setPriceChange(calculateSymbolPriceChange({ bid, priceLast }));
  }, [bid, priceLast]);

  return priceChange;
};

export { usePriceChange };
