import { Select as SelectPrimitive } from "radix-ui";
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  type FC,
  forwardRef,
  memo,
  type ReactNode,
  useMemo,
} from "react";

import { NumberFormat } from "@/app/components";
import { createImagePathUrl } from "@/app/utils/images/helpers";
import { type TradingAccount } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Image, popoverContentStyles, ScrollArea, Text } from "@/shared/ui";

type ItemProps = ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
  account: TradingAccount;
};

const Trigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    account: TradingAccount;
    label: string;
    position?: "top" | "bottom";
  }
>(({ className, position, account, label, ...props }, ref) => {
  const { login, availableToWithdraw, digits, currencyImageUrlPath, currency, platform } = account;
  return (
    <SelectPrimitive.Trigger
      ref={ref}
      className={cn(
        "border border-transparent bg-control-bg p-4 outline-none transition-colors hover:border-input-border hover:bg-control-bg-hover",
        position === "top" && "rounded-t-lg",
        position === "bottom" && "rounded-b-lg",
        "focus:border-accent-text data-[state=open]:border-accent-text data-[state=open]:bg-control-bg-hover",
        className,
      )}
      {...props}
    >
      <div className="flex w-full items-center gap-3">
        <Image className="size-8" src={createImagePathUrl(currencyImageUrlPath!)} />

        <div className="flex w-full flex-col justify-center gap-1">
          <div className="flex items-center justify-between">
            <Text variant="XS / Regular" color="secondary" align="start">
              {label}
            </Text>
            <Text variant="XS / Regular" color="secondary" align="end">
              #{login}
            </Text>
          </div>
          <div className="flex items-center justify-between">
            <Text variant="S / Medium" color="primary">
              <NumberFormat value={availableToWithdraw} decimalScale={digits} currency={currency!} />
            </Text>
          </div>
        </div>
      </div>
    </SelectPrimitive.Trigger>
  );
});

const Content: FC<{ children: ReactNode }> = ({ children }) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      className={cn(
        popoverContentStyles(),
        "w-[var(--radix-select-trigger-width)] overflow-hidden rounded-lg bg-surface-elevation-2 shadow-modal",
      )}
      collisionPadding={20}
      sideOffset={8}
      position="popper"
    >
      <ScrollArea scrollbars="vertical" scrollOffset="sm">
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
      </ScrollArea>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
);

const Item = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  Omit<ComponentPropsWithoutRef<typeof SelectPrimitive.Item>, "className"> & ItemProps
>(({ account, ...props }, ref) => {
  const { availableToWithdraw, currency, login, digits, currencyImageUrlPath, platform } = account;

  return (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        "relative flex cursor-pointer select-none items-center justify-between px-4 py-3 outline-none transition-colors hover:bg-control-bg-active data-[highlighted]:bg-control-bg-hover data-[state=checked]:!bg-control-bg-active",
      )}
      {...props}
    >
      <SelectPrimitive.ItemText asChild>
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-3">
            <Image className="size-8" src={createImagePathUrl(currencyImageUrlPath!)} />
            <div>
              <Text variant="XS / Regular" color="secondary" className="mb-1.5">
                #{login}
              </Text>
              <Text variant="S / Medium" color="primary">
                <NumberFormat value={availableToWithdraw} decimalScale={digits} currency={currency!} />
              </Text>
            </div>
          </div>
        </div>
      </SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});

type Props = ComponentPropsWithoutRef<typeof SelectPrimitive.Root> & {
  accounts: TradingAccount[];
  label: string;
  position: "top" | "bottom";
};

const _Select = forwardRef<ElementRef<typeof Trigger>, Props>(
  ({ accounts, value, position, onValueChange, ...props }, ref) => {
    const account = useMemo(() => accounts.find(account => account.id === value)!, [accounts, value]);

    return (
      <SelectPrimitive.Root {...props} value={value} onValueChange={onValueChange}>
        <Trigger ref={ref} position={position} account={account} {...props} />
        <Content>
          {accounts.map(account => (
            <Item key={account.id} account={account} value={account.id!} />
          ))}
        </Content>
      </SelectPrimitive.Root>
    );
  },
);

const Select = memo(_Select);

export { Select as TransferSelect };
