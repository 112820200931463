import type { FC } from "react";

import { useProfileData } from "@/state/server/profile/profile.hooks";
import { Header } from "@/widgets/header";

import { AccountDetailsContainer } from "./account-details/container";
import { AccountSummaryContainer } from "./account-summary/container";
import { ChartContainer } from "./chart/container";
import { ChartSymbolsContainer } from "./chart-symbols/container";
import { TerminalTableState, useLayoutContext } from "./layout/context";
import { DesktopPlaceOrderBlock } from "./place-order/desktop/block";
import { TerminalDesktopFrame } from "./terminal-desktop.frame";
import { DesktopTradingTablesBlock } from "./trading-tables/desktop/block";

const TerminalDesktop: FC = () => {
  const { table } = useLayoutContext();
  const { homeInfo, permissions } = useProfileData();

  const isTableExpanded = table !== TerminalTableState.NONE;

  return (
    <TerminalDesktopFrame
      isTableExpanded={isTableExpanded}
      headerSlot={
        <Header isReadOnly={!permissions!.isEnabled} isHomeAvailable={homeInfo!.isHomeAvailable!}>
          <AccountDetailsContainer />
        </Header>
      }
      chartSymbolsSlot={<ChartSymbolsContainer />}
      chartSlot={<ChartContainer />}
      placeOrderSlot={<DesktopPlaceOrderBlock />}
      tablesSlot={<DesktopTradingTablesBlock />}
      accountSummarySlot={<AccountSummaryContainer />}
    />
  );
};

export { TerminalDesktop };
