import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router-dom";

import { useLocationPath } from "@/app/hooks";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { getWithdrawalRoute } from "@/routes/cabinet.routes";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { useAllAccountsQuery, useLastAccountQuery } from "@/state/server/accounts";
import { useWithdrawMethodQuery } from "@/state/server/payment";

import { WithdrawalForm } from "./components/form/withdrawal.form";
import { WithdrawalNoAccounts } from "./components/no-accounts";

const WithdrawalPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");

  const { data: accounts } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    sorting: TradingAccountSorting.Newest,
    status: AvailabilityStatus.AvailableToWithdraw,
  });
  const { data: paymentMethodsData } = useWithdrawMethodQuery();

  const { data: lastAccount } = useLastAccountQuery({
    type: TradingAccountType.Real,
    status: AvailabilityStatus.Active,
  });

  const path = useLocationPath();

  useEffect(() => {
    track(amplitudeEvents.withdrawal.visit, {
      entryPoint: from,
    });
  }, [from]);

  if (!accounts || !paymentMethodsData || !lastAccount) {
    return <CabinetPage.Loader />;
  }

  if (!accounts.length) {
    return (
      <CabinetPage
        size="sm"
        header={
          <CabinetHeader>
            <CabinetHeader.Title>{t("cabinet.navigation.withdrawal")}</CabinetHeader.Title>
          </CabinetHeader>
        }
      >
        <WithdrawalNoAccounts paymentMethods={paymentMethodsData.items!} />
      </CabinetPage>
    );
  }

  if (accounts.length === 1) {
    return <Navigate to={getWithdrawalRoute(accounts[0]!.id!)} replace />;
  }

  return (
    <CabinetPage
      size="sm"
      header={
        <CabinetHeader>
          <CabinetHeader.Title>{t("withdrawal.account-select.title")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <WithdrawalForm lastAccount={lastAccount} accounts={accounts} path={path} />
    </CabinetPage>
  );
};

export { WithdrawalPage };
