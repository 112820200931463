import { type FC, type ReactNode } from "react";

import { useProfileData } from "@/state/server/profile/profile.hooks";
import { SidebarButton } from "@/widgets/sidebar/button";

type Props = {
  showFullscreenChart?: boolean;
  // slots
  chartSymbolsSlot: ReactNode;
  accountDetailsSlot: ReactNode;
  depositButtonSlot: ReactNode;
  chartSlot: ReactNode;
  placeOrderSlot: ReactNode;
  tablesSlot: ReactNode;
};

const TerminalMobileFrame: FC<Props> = ({
  showFullscreenChart,
  chartSymbolsSlot,
  accountDetailsSlot,
  depositButtonSlot,
  chartSlot,
  placeOrderSlot,
  tablesSlot,
}) => {
  const { homeInfo, permissions } = useProfileData();

  if (showFullscreenChart) {
    return chartSlot as JSX.Element;
  }

  return (
    <div className="flex h-full flex-col bg-surface-canvas">
      <div className="flex min-h-0 flex-col overflow-y-auto">
        <div className="sticky top-0 z-10 flex items-center justify-between gap-2 pb-2 pe-4 ps-2 pt-3 backdrop-blur">
          <div className="flex items-center gap-1">
            <SidebarButton isReadOnly={!permissions!.isEnabled} isHomeAvailable={homeInfo!.isHomeAvailable!} />
            {accountDetailsSlot}
          </div>
          {depositButtonSlot}
        </div>
        <div className="bg-surface-canvas px-4 pb-2 pt-1">{chartSymbolsSlot}</div>
        <div className="mb-4 h-80 shrink-0">{chartSlot}</div>
        <div className="bg-surface-canvas">{placeOrderSlot}</div>
      </div>
      {tablesSlot}
    </div>
  );
};

export { TerminalMobileFrame };
