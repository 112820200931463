import { Portal } from "@radix-ui/react-portal";
import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { CookieType } from "@/constants/enums";
import { Button, ExternalLink, Link, Text } from "@/shared/ui";

const TransLink: FC<any> = props => (
  <Link variant="inherit" asChild>
    <ExternalLink {...props} />
  </Link>
);

type Props = {
  onCookieAccept: (cookieType: CookieType) => void;
};

const CookiePopup: FC<Props> = ({ onCookieAccept }) => {
  const { t } = useTranslation();

  return (
    <Portal className="fixed bottom-2 end-0 start-0 z-50 mx-auto flex max-w-[328px] flex-col gap-4 rounded-xl bg-surface-elevation-2 p-8 shadow-modal md:max-w-[720px]">
      <Text as="h3" variant="XL / Medium" color="primary">
        {t("cookie-title")}
      </Text>
      <div className="flex flex-col gap-6 md:grid md:grid-cols-[1fr_200px]">
        <Text variant="M / Regular" color="primary">
          <Trans i18nKey="cookie-parapgraph" components={{ a: <TransLink /> }} />
        </Text>
        <div className="flex flex-col gap-2">
          <Button size="sm" onClick={() => onCookieAccept(CookieType.ACCEPT_ALL)}>
            {t("cookie-btn-accept")}
          </Button>
          <Button size="sm" variant="secondary" onClick={() => onCookieAccept(CookieType.ACCEPT_ESSENTIALS)}>
            {t("cookie-btn-essential")}
          </Button>
        </div>
      </div>
    </Portal>
  );
};

export { CookiePopup };
