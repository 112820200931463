import { cva, type VariantProps } from "class-variance-authority";
import type { FC } from "react";

import { createImagePathUrl } from "@/app/utils/images/helpers";
import fallbackImg from "@/assets/images/pair-no-icon.svg";
import { Image } from "@/shared/ui";

const containerStyles = cva("flex shrink-0", {
  variants: {
    size: {
      sm: "size-4",
      md: "size-6",
      lg: "size-8",
      xl: "size-9",
      xxl: "size-12",
    },
  },
});

const Icon: FC<{ symbol: string; symbolUrlPath: string }> = ({ symbol, symbolUrlPath }) => {
  return (
    <Image
      src={createImagePathUrl(symbolUrlPath)}
      alt={symbol}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        // currentTarget.src = isDarkTheme ? fallbackDarkImg : fallbackLightImg;
        currentTarget.src = fallbackImg;
      }}
    />
  );
};

const SymbolIcon: FC<{ symbol: string; symbolUrlPath: string } & VariantProps<typeof containerStyles>> = ({
  size = "md",
  symbol,
  symbolUrlPath,
}) => (
  <div className={containerStyles({ size })}>
    <Icon symbol={symbol} symbolUrlPath={symbolUrlPath} />
  </div>
);

export { SymbolIcon };
