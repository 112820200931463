import { AppProvider } from "@/providers/app";
import { AppRoutes } from "@/routes";

import { globalStyles } from "./styles/global";

function App() {
  globalStyles();

  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
