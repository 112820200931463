import dayjs from "dayjs";
import { useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { logError, setLocaleSentry } from "@/app/libs/sentry";

const languages = {
  english: "en",
  vietnamese: "vi",
  spanish: "es",
  thai: "th",
  malay: "ms",
  portuguese: "pt",
  indonesian: "id",
  russian: "ru",
};

const loadDayjsLocale = (language: string) => {
  switch (language) {
    case languages.vietnamese:
      return import("dayjs/locale/vi");
    case languages.spanish:
      return import("dayjs/locale/es");
    case languages.thai:
      return import("dayjs/locale/th");
    case languages.malay:
      return import("dayjs/locale/ms");
    case languages.portuguese:
      return import("dayjs/locale/pt");
    case languages.indonesian:
      return import("dayjs/locale/id");
    case languages.russian:
      return import("dayjs/locale/ru");
    case languages.english:
      return Promise.resolve();
    default:
      return Promise.reject();
  }
};

const useInitializeLanguage = () => {
  const { i18n } = useTranslation();

  const dir = i18n.dir();

  useLayoutEffect(() => {
    document.documentElement.dir = dir;
    document.documentElement.lang = i18n.resolvedLanguage;
    setLocaleSentry(i18n.resolvedLanguage);

    loadDayjsLocale(i18n.resolvedLanguage)
      .then(() => dayjs.locale(i18n.resolvedLanguage))
      .catch(() => logError("error loading dayjs locale"));
  }, []);

  const raLocale = useMemo(() => {
    if (["en", "th", "id", "ms", "vi"].includes(i18n.resolvedLanguage)) {
      return "en-GB";
    }
    return i18n.resolvedLanguage;
  }, [i18n.resolvedLanguage]);

  return { dir, raLocale };
};

export { useInitializeLanguage };
