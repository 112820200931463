import { cva, type VariantProps } from "class-variance-authority";
import { Slot } from "radix-ui";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, memo, type ReactNode } from "react";

import { cn } from "@/shared/styles";

import { Spinner } from "..";

const tileButtonStyles = cva(
  "typography-XS-Medium inline-flex flex-col items-center justify-center gap-1 rounded-lg p-3 outline-none transition-colors focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-text disabled:pointer-events-none disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
  {
    variants: {
      variant: {
        primary: "bg-accent-bg text-accent-over hover:bg-accent-bg-hover active:bg-accent-bg-active",
        secondary:
          "bg-secondary-button-bg text-contrast-secondary hover:bg-secondary-button-bg-hover active:bg-secondary-button-bg-active",
      },
      pending: {
        true: "",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        pending: true,
        className: "disabled:bg-accent-bg disabled:text-accent-over",
      },
      {
        variant: "secondary",
        pending: true,
        className: "disabled:bg-secondary-button-bg disabled:text-secondary-button-over",
      },
    ],
  },
);

type TileButtonProps = ComponentPropsWithoutRef<"button"> &
  VariantProps<typeof tileButtonStyles> & {
    icon: ReactNode;
    asChild?: boolean;
    pending?: boolean;
  };

const Component = forwardRef<ElementRef<"button">, TileButtonProps>(
  (
    {
      className,
      variant = "primary",
      asChild = false,
      pending,
      disabled: disabledProp,
      type = "button",
      children,
      icon,
      ...props
    },
    ref,
  ) => {
    const disabled = disabledProp || pending;
    const Comp = asChild && !pending ? Slot.Root : "button";

    return (
      <Comp
        className={cn(tileButtonStyles({ pending, variant }), className)}
        disabled={disabled}
        type={type}
        {...props}
        ref={ref}
      >
        {!pending && (
          <div
            className={cn("shrink-0 *:size-6", {
              "text-secondary-button-over": variant === "secondary",
            })}
          >
            {icon}
          </div>
        )}
        {!pending && <Slot.Slottable>{children}</Slot.Slottable>}
        {pending && (
          <div className="*:size-6">
            <Spinner />
          </div>
        )}
      </Comp>
    );
  },
);

const TileButton = memo(Component);

TileButton.displayName = "TileButton";

export { TileButton };
