import { type FC } from "react";

import { ExternalLink } from "@/shared/ui";

type Props = {
  link: string;
};

const DownloadIOSButton: FC<Props> = ({ link }) => {
  return (
    <ExternalLink href={link} className="rounded-md bg-accent-bg px-3 py-2.5 text-accent-over">
      <svg width="96" height="25" viewBox="0 0 96 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.6258 11.8938C14.636 11.0943 14.8477 10.3103 15.241 9.6149C15.6343 8.91949 16.1966 8.33522 16.8754 7.91644C16.4442 7.29842 15.8753 6.78982 15.2139 6.43103C14.5525 6.07224 13.8168 5.87314 13.0654 5.84955C11.4623 5.6807 9.90825 6.8121 9.09138 6.8121C8.25869 6.8121 7.00098 5.86631 5.64664 5.89427C4.77061 5.92267 3.91685 6.1783 3.16855 6.63624C2.42024 7.09418 1.80292 7.73883 1.37671 8.50736C-0.469521 11.7149 0.907601 16.4289 2.67614 19.0216C3.56097 20.2912 4.59509 21.7093 5.94821 21.6591C7.27232 21.6039 7.76686 20.8118 9.36517 20.8118C10.9487 20.8118 11.4126 21.6591 12.7932 21.6271C14.214 21.6039 15.1092 20.3519 15.963 19.0702C16.5988 18.1656 17.088 17.1658 17.4125 16.1079C16.5871 15.7575 15.8826 15.1711 15.3871 14.4217C14.8915 13.6723 14.6267 12.7932 14.6258 11.8938Z"
          fill="currentColor"
        />
        <path
          d="M12.0181 4.14439C12.7928 3.21118 13.1745 2.01169 13.0821 0.800648C11.8985 0.925389 10.8052 1.49301 10.02 2.39043C9.63615 2.82884 9.34213 3.33887 9.15479 3.89137C8.96745 4.44387 8.89047 5.02801 8.92823 5.61038C9.52023 5.6165 10.1059 5.48774 10.6411 5.23381C11.1763 4.97987 11.6472 4.60738 12.0181 4.14439Z"
          fill="currentColor"
        />
        <path
          d="M31.3635 18.445H26.8449L25.7597 21.6602H23.8458L28.1258 9.76473H30.1143L34.3942 21.6602H32.4477L31.3635 18.445ZM27.3129 16.9613H30.8946L29.1289 11.7433H29.0795L27.3129 16.9613Z"
          fill="currentColor"
        />
        <path
          d="M43.6374 17.3243C43.6374 20.0194 42.1999 21.751 40.0305 21.751C39.481 21.7798 38.9345 21.6528 38.4536 21.3844C37.9726 21.1161 37.5767 20.7172 37.3112 20.2336H37.2701V24.5293H35.4961V12.9875H37.2133V14.43H37.2459C37.5237 13.9486 37.9263 13.5519 38.4109 13.2819C38.8954 13.0119 39.4439 12.8788 39.9979 12.8967C42.1914 12.8967 43.6374 14.6367 43.6374 17.3243ZM41.8139 17.3243C41.8139 15.5684 40.9096 14.414 39.5299 14.414C38.1744 14.414 37.2627 15.5927 37.2627 17.3243C37.2627 19.0718 38.1744 20.242 39.5299 20.242C40.9096 20.242 41.8139 19.0961 41.8139 17.3243Z"
          fill="currentColor"
        />
        <path
          d="M53.15 17.3243C53.15 20.0194 51.7125 21.751 49.5431 21.751C48.9936 21.7798 48.4471 21.6528 47.9662 21.3844C47.4852 21.1161 47.0893 20.7172 46.8238 20.2336H46.7827V24.5293H45.0087V12.9875H46.7259V14.43H46.7585C47.0362 13.9486 47.4388 13.5519 47.9234 13.2819C48.408 13.0119 48.9565 12.8788 49.5105 12.8967C51.7041 12.8967 53.15 14.6367 53.15 17.3243ZM51.3265 17.3243C51.3265 15.5684 50.4222 14.414 49.0425 14.414C47.687 14.414 46.7753 15.5927 46.7753 17.3243C46.7753 19.0718 47.687 20.242 49.0425 20.242C50.4223 20.242 51.3265 19.0961 51.3265 17.3243H51.3265Z"
          fill="currentColor"
        />
        <path
          d="M59.4371 18.3458C59.5685 19.5255 60.7105 20.3 62.2711 20.3C63.7664 20.3 64.8422 19.5254 64.8422 18.4618C64.8422 17.5385 64.1934 16.9856 62.657 16.6068L61.1207 16.2354C58.9439 15.7078 57.9333 14.6862 57.9333 13.0286C57.9333 10.9761 59.7158 9.56638 62.2468 9.56638C64.7518 9.56638 66.469 10.9761 66.5268 13.0286H64.7359C64.6287 11.8415 63.6508 11.1249 62.2216 11.1249C60.7925 11.1249 59.8146 11.8499 59.8146 12.9051C59.8146 13.7461 60.4392 14.2409 61.9671 14.6198L63.2732 14.9416C65.7054 15.5188 66.716 16.4992 66.716 18.2391C66.716 20.4646 64.9494 21.8585 62.1396 21.8585C59.5107 21.8585 57.7357 20.4973 57.621 18.3457L59.4371 18.3458Z"
          fill="currentColor"
        />
        <path
          d="M70.5447 10.935V12.9875H72.1883V14.3972H70.5447V19.1784C70.5447 19.9212 70.8738 20.2673 71.5963 20.2673C71.7914 20.2639 71.9862 20.2501 72.1799 20.2261V21.6274C71.8551 21.6883 71.5249 21.7159 71.1945 21.7098C69.4447 21.7098 68.7623 21.0503 68.7623 19.3683V14.3972H67.5056V12.9875H68.7623V10.935H70.5447Z"
          fill="currentColor"
        />
        <path
          d="M73.1402 17.3243C73.1402 14.5955 74.7417 12.8808 77.2392 12.8808C79.7451 12.8808 81.3392 14.5955 81.3392 17.3243C81.3392 20.0606 79.7535 21.7678 77.2392 21.7678C74.7259 21.7678 73.1402 20.0606 73.1402 17.3243ZM79.5316 17.3243C79.5316 15.4524 78.6767 14.3476 77.2392 14.3476C75.8017 14.3476 74.9478 15.4608 74.9478 17.3243C74.9478 19.2037 75.8017 20.3 77.2392 20.3C78.6767 20.3 79.5316 19.2037 79.5316 17.3243H79.5316Z"
          fill="currentColor"
        />
        <path
          d="M82.8019 12.9875H84.4939V14.4636H84.535C84.6494 14.0026 84.9186 13.5951 85.2972 13.3098C85.6758 13.0244 86.1407 12.8786 86.6139 12.8967C86.8183 12.896 87.0222 12.9183 87.2217 12.9631V14.6283C86.9636 14.5491 86.6944 14.5128 86.4246 14.5207C86.1669 14.5102 85.9099 14.5558 85.6714 14.6543C85.4329 14.7529 85.2185 14.9021 85.0429 15.0917C84.8672 15.2812 84.7346 15.5067 84.654 15.7525C84.5734 15.9984 84.5468 16.2589 84.5759 16.516V21.6602H82.8019L82.8019 12.9875Z"
          fill="currentColor"
        />
        <path
          d="M95.4012 19.1129C95.1625 20.6873 93.6346 21.7678 91.6796 21.7678C89.1654 21.7678 87.6048 20.0774 87.6048 17.3655C87.6048 14.6451 89.1738 12.8808 91.6051 12.8808C93.9963 12.8808 95.5 14.5291 95.5 17.1587V17.7686H89.3957V17.8762C89.3675 18.1954 89.4073 18.5169 89.5125 18.8195C89.6177 19.122 89.7858 19.3987 90.0057 19.631C90.2256 19.8634 90.4922 20.0461 90.7879 20.1671C91.0836 20.2881 91.4016 20.3446 91.7207 20.3328C92.1399 20.3722 92.5606 20.2748 92.9202 20.055C93.2798 19.8352 93.5591 19.5048 93.7166 19.1129L95.4012 19.1129ZM89.404 16.5245H93.725C93.7409 16.2375 93.6976 15.9503 93.5976 15.6809C93.4977 15.4115 93.3434 15.1658 93.1443 14.9591C92.9452 14.7524 92.7058 14.5892 92.4409 14.4798C92.176 14.3704 91.8914 14.3171 91.6051 14.3233C91.3162 14.3216 91.0298 14.3773 90.7625 14.4872C90.4951 14.5971 90.2521 14.7591 90.0475 14.9637C89.8429 15.1683 89.6806 15.4116 89.5702 15.6795C89.4598 15.9474 89.4033 16.2346 89.404 16.5245V16.5245Z"
          fill="currentColor"
        />
        <path
          d="M27.0905 0.810925C27.4624 0.784143 27.8357 0.840526 28.1833 0.976008C28.5308 1.11149 28.8441 1.32269 29.1005 1.59438C29.3569 1.86607 29.55 2.19148 29.6659 2.54713C29.7818 2.90278 29.8176 3.2798 29.7707 3.65101C29.7707 5.47706 28.7872 6.5267 27.0905 6.5267H25.0331V0.810925H27.0905ZM25.9178 5.71837H26.9917C27.2575 5.73431 27.5234 5.69058 27.7702 5.59037C28.017 5.49016 28.2385 5.33598 28.4184 5.13906C28.5984 4.94215 28.7323 4.70745 28.8104 4.45205C28.8886 4.19665 28.909 3.92698 28.8702 3.66266C28.9062 3.39939 28.8837 3.13139 28.8043 2.87787C28.725 2.62434 28.5907 2.39156 28.4112 2.19621C28.2317 2.00086 28.0113 1.84778 27.7659 1.74793C27.5205 1.64808 27.2561 1.60393 26.9917 1.61866H25.9178V5.71837Z"
          fill="currentColor"
        />
        <path
          d="M30.7701 4.36802C30.7431 4.08455 30.7754 3.79855 30.865 3.52835C30.9545 3.25815 31.0994 3.00972 31.2903 2.79899C31.4811 2.58826 31.7138 2.41989 31.9733 2.30468C32.2328 2.18947 32.5135 2.12995 32.7972 2.12995C33.081 2.12995 33.3617 2.18947 33.6212 2.30468C33.8807 2.41989 34.1134 2.58826 34.3042 2.79899C34.4951 3.00972 34.64 3.25815 34.7295 3.52835C34.8191 3.79855 34.8514 4.08455 34.8244 4.36802C34.8519 4.65178 34.82 4.93818 34.7307 5.20882C34.6413 5.47945 34.4965 5.72834 34.3056 5.93948C34.1147 6.15062 33.8819 6.31934 33.6221 6.43481C33.3623 6.55027 33.0814 6.60992 32.7972 6.60992C32.5131 6.60992 32.2322 6.55027 31.9724 6.43481C31.7126 6.31934 31.4798 6.15062 31.2889 5.93948C31.0979 5.72834 30.9532 5.47945 30.8638 5.20882C30.7745 4.93818 30.7425 4.65178 30.7701 4.36802ZM33.9518 4.36802C33.9518 3.43302 33.5332 2.88624 32.7986 2.88624C32.0612 2.88624 31.6464 3.43302 31.6464 4.36803C31.6464 5.31052 32.0613 5.85309 32.7986 5.85309C33.5333 5.85308 33.9518 5.30677 33.9518 4.36802H33.9518Z"
          fill="currentColor"
        />
        <path
          d="M40.2137 6.52661H39.3337L38.4453 3.34975H38.3781L37.4934 6.52661H36.6218L35.4369 2.21315H36.2974L37.0674 5.50458H37.1308L38.0146 2.21315H38.8284L39.7122 5.50458H39.7793L40.5456 2.21315H41.3939L40.2137 6.52661Z"
          fill="currentColor"
        />
        <path
          d="M42.3905 2.21315H43.2071V2.89838H43.2705C43.3781 2.65227 43.5595 2.44596 43.7894 2.30821C44.0193 2.17045 44.2864 2.10812 44.5533 2.12989C44.7625 2.11411 44.9725 2.14576 45.1678 2.22248C45.3631 2.29921 45.5387 2.41905 45.6816 2.57312C45.8245 2.7272 45.931 2.91156 45.9933 3.11255C46.0555 3.31355 46.0719 3.52601 46.0412 3.73422V6.52657H45.1928V3.94799C45.1928 3.25481 44.8927 2.91008 44.2652 2.91008C44.1232 2.90344 43.9815 2.9277 43.8497 2.98118C43.7179 3.03466 43.5992 3.1161 43.5017 3.21993C43.4042 3.32375 43.3302 3.4475 43.2849 3.58269C43.2395 3.71789 43.2238 3.86132 43.2388 4.00318V6.52661H42.3905L42.3905 2.21315Z"
          fill="currentColor"
        />
        <path d="M47.3929 0.529297H48.2413V6.5266H47.3929V0.529297Z" fill="currentColor" />
        <path
          d="M49.4206 4.36802C49.3935 4.08453 49.4259 3.79851 49.5155 3.5283C49.6051 3.25809 49.75 3.00965 49.9409 2.79892C50.1318 2.58819 50.3644 2.41982 50.624 2.3046C50.8835 2.18939 51.1642 2.12987 51.448 2.12987C51.7318 2.12987 52.0124 2.18939 52.2719 2.3046C52.5315 2.41982 52.7641 2.58819 52.955 2.79892C53.1459 3.00965 53.2908 3.25809 53.3804 3.5283C53.47 3.79851 53.5024 4.08453 53.4753 4.36802C53.5029 4.65179 53.4709 4.93819 53.3815 5.20883C53.2921 5.47947 53.1473 5.72835 52.9564 5.93948C52.7655 6.15061 52.5326 6.31933 52.2728 6.43479C52.0131 6.55025 51.7321 6.60989 51.448 6.60989C51.1638 6.60989 50.8828 6.55025 50.6231 6.43479C50.3633 6.31933 50.1304 6.15061 49.9395 5.93948C49.7486 5.72835 49.6038 5.47947 49.5144 5.20883C49.425 4.93819 49.3931 4.65179 49.4206 4.36802ZM52.6023 4.36802C52.6023 3.43302 52.1837 2.88624 51.4491 2.88624C50.7117 2.88624 50.2969 3.43302 50.2969 4.36803C50.2969 5.31052 50.7117 5.85309 51.4491 5.85309C52.1837 5.85308 52.6023 5.30677 52.6023 4.36802H52.6023Z"
          fill="currentColor"
        />
        <path
          d="M54.3684 5.30677C54.3684 4.53032 54.9446 4.0827 55.9672 4.01909L57.1316 3.95174V3.57942C57.1316 3.12384 56.8314 2.86659 56.2516 2.86659C55.778 2.86659 55.4499 3.04106 55.3557 3.34602H54.5344C54.6211 2.60513 55.3156 2.12991 56.2907 2.12991C57.3684 2.12991 57.9762 2.66826 57.9762 3.57942V6.52662H57.1596V5.92044H57.0925C56.9562 6.13788 56.7649 6.31514 56.5381 6.43413C56.3112 6.55311 56.057 6.60956 55.8013 6.59769C55.6209 6.61653 55.4385 6.59721 55.2659 6.54098C55.0933 6.48475 54.9344 6.39286 54.7994 6.27123C54.6644 6.14961 54.5564 6.00094 54.4821 5.83482C54.4079 5.66871 54.3692 5.48882 54.3684 5.30677ZM57.1316 4.93819V4.57757L56.0819 4.64492C55.4899 4.68467 55.2214 4.88674 55.2214 5.267C55.2214 5.65522 55.557 5.88114 56.0185 5.88114C56.1538 5.89487 56.2904 5.88117 56.4202 5.84085C56.55 5.80053 56.6704 5.73442 56.7743 5.64644C56.8781 5.55846 56.9633 5.45042 57.0247 5.32875C57.0861 5.20708 57.1224 5.07425 57.1316 4.93819Z"
          fill="currentColor"
        />
        <path
          d="M59.0912 4.36802C59.0912 3.00504 59.7894 2.1416 60.8755 2.1416C61.1441 2.12918 61.4108 2.19374 61.6442 2.32775C61.8776 2.46176 62.0682 2.65967 62.1937 2.89836H62.2571V0.529297H63.1054V6.5266H62.2925V5.84511H62.2254C62.0902 6.08219 61.893 6.27764 61.655 6.41029C61.4171 6.54295 61.1475 6.60775 60.8755 6.59769C59.782 6.59774 59.0912 5.73428 59.0912 4.36802ZM59.9675 4.36802C59.9675 5.28291 60.3973 5.83344 61.1161 5.83344C61.8311 5.83344 62.273 5.27497 62.273 4.37177C62.273 3.47277 61.8264 2.90634 61.1161 2.90634C60.4019 2.90634 59.9675 3.46061 59.9675 4.36802H59.9675Z"
          fill="currentColor"
        />
        <path
          d="M66.6153 4.36802C66.5883 4.08455 66.6206 3.79855 66.7102 3.52835C66.7998 3.25815 66.9447 3.00972 67.1355 2.79899C67.3264 2.58826 67.559 2.41989 67.8186 2.30468C68.0781 2.18947 68.3587 2.12995 68.6425 2.12995C68.9263 2.12995 69.2069 2.18947 69.4664 2.30468C69.7259 2.41989 69.9586 2.58826 70.1495 2.79899C70.3403 3.00972 70.4852 3.25815 70.5748 3.52835C70.6644 3.79855 70.6967 4.08455 70.6697 4.36802C70.6972 4.65178 70.6653 4.93818 70.5759 5.20882C70.4866 5.47945 70.3418 5.72834 70.1509 5.93948C69.96 6.15062 69.7271 6.31934 69.4674 6.43481C69.2076 6.55027 68.9266 6.60992 68.6425 6.60992C68.3584 6.60992 68.0774 6.55027 67.8176 6.43481C67.5579 6.31934 67.325 6.15062 67.1341 5.93948C66.9432 5.72834 66.7984 5.47945 66.7091 5.20882C66.6197 4.93818 66.5878 4.65178 66.6153 4.36802ZM69.7971 4.36802C69.7971 3.43302 69.3785 2.88624 68.6439 2.88624C67.9065 2.88624 67.4917 3.43302 67.4917 4.36803C67.4917 5.31052 67.9065 5.85309 68.6439 5.85309C69.3785 5.85308 69.7971 5.30677 69.7971 4.36802Z"
          fill="currentColor"
        />
        <path
          d="M71.8079 2.21315H72.6246V2.89838H72.688C72.7955 2.65227 72.9769 2.44596 73.2068 2.30821C73.4368 2.17045 73.7038 2.10812 73.9707 2.12989C74.1799 2.11411 74.3899 2.14576 74.5852 2.22248C74.7805 2.29921 74.9561 2.41905 75.099 2.57312C75.2419 2.7272 75.3484 2.91156 75.4107 3.11255C75.4729 3.31355 75.4893 3.52601 75.4586 3.73422V6.52657H74.6103V3.94799C74.6103 3.25481 74.3101 2.91008 73.6827 2.91008C73.5407 2.90344 73.3989 2.9277 73.2671 2.98118C73.1353 3.03466 73.0166 3.1161 72.9191 3.21993C72.8216 3.32375 72.7477 3.4475 72.7023 3.58269C72.6569 3.71789 72.6412 3.86132 72.6563 4.00318V6.52661H71.8079V2.21315Z"
          fill="currentColor"
        />
        <path
          d="M80.2522 1.13924V2.2328H81.1835V2.94984H80.2522V5.16785C80.2522 5.61968 80.4377 5.81753 80.86 5.81753C80.9681 5.81718 81.0762 5.81062 81.1835 5.79788V6.50697C81.0312 6.53432 80.8768 6.54887 80.7221 6.55047C79.7786 6.55047 79.4029 6.21743 79.4029 5.3858V2.94981H78.7205V2.23276H79.4029V1.13924H80.2522Z"
          fill="currentColor"
        />
        <path
          d="M82.3423 0.529297H83.1832V2.90634H83.2503C83.3631 2.65793 83.5494 2.45045 83.784 2.31217C84.0185 2.1739 84.2899 2.11156 84.561 2.13364C84.7691 2.12227 84.9771 2.15713 85.1702 2.23572C85.3633 2.31431 85.5367 2.4347 85.6781 2.58829C85.8195 2.74188 85.9254 2.92489 85.9882 3.12427C86.051 3.32365 86.0691 3.53447 86.0414 3.74171V6.52661H85.1922V3.95172C85.1922 3.26274 84.8724 2.91381 84.273 2.91381C84.1272 2.90181 83.9805 2.92192 83.8433 2.97274C83.706 3.02356 83.5815 3.10386 83.4784 3.20803C83.3753 3.31219 83.2962 3.4377 83.2465 3.57577C83.1968 3.71384 83.1777 3.86114 83.1907 4.00737V6.5266H82.3423L82.3423 0.529297Z"
          fill="currentColor"
        />
        <path
          d="M90.9879 5.36195C90.8727 5.75618 90.6229 6.09718 90.2824 6.32531C89.9419 6.55344 89.5323 6.65417 89.1253 6.60987C88.8421 6.61737 88.5607 6.56294 88.3005 6.45036C88.0404 6.33777 87.8078 6.16973 87.6189 5.9579C87.4299 5.74607 87.2892 5.49552 87.2065 5.22364C87.1237 4.95176 87.1009 4.66506 87.1396 4.38345C87.1019 4.10098 87.1251 3.81366 87.2077 3.54097C87.2902 3.26828 87.4302 3.01656 87.6182 2.80288C87.8061 2.58919 88.0376 2.41852 88.297 2.30241C88.5563 2.1863 88.8376 2.12747 89.1216 2.1299C90.3176 2.1299 91.0392 2.94988 91.0392 4.30439V4.60143H88.0038V4.64913C87.9905 4.80743 88.0104 4.96676 88.062 5.11691C88.1137 5.26707 88.1961 5.40473 88.3038 5.52107C88.4116 5.63742 88.5424 5.72988 88.6879 5.79252C88.8334 5.85517 88.9903 5.88662 89.1486 5.88485C89.3515 5.9093 89.5571 5.87263 89.7392 5.77952C89.9213 5.6864 90.0717 5.54104 90.1713 5.36192L90.9879 5.36195ZM88.0038 3.97184H90.175C90.1857 3.82707 90.1661 3.68166 90.1174 3.54495C90.0688 3.40824 89.9922 3.28325 89.8925 3.17801C89.7929 3.07278 89.6724 2.98962 89.5388 2.9339C89.4052 2.87817 89.2615 2.8511 89.1169 2.85443C88.9702 2.85258 88.8246 2.88022 88.6886 2.93573C88.5527 2.99123 88.4293 3.07348 88.3255 3.17762C88.2218 3.28176 88.1399 3.40568 88.0846 3.54209C88.0294 3.67849 88.0019 3.82461 88.0038 3.97184H88.0038Z"
          fill="currentColor"
        />
      </svg>
    </ExternalLink>
  );
};

export { DownloadIOSButton };
