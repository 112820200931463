import type { FC } from "react";
import { useFormContext } from "react-hook-form";

import { useControlledField } from "@/app/form";
import type { SurveyField } from "@/services/openapi";
import { Checkbox, Text } from "@/shared/ui";

import { generateSurveyFieldId } from "../survey.helpers";

type Props = {
  field: SurveyField;
};

export const SurveyDeclarationField: FC<Props> = ({ field: { isRequired, title, id, type, description } }) => {
  const { control } = useFormContext();
  const [field, { invalid: isFieldInvalid }] = useControlledField({
    name: generateSurveyFieldId(id!, type!),
    control,
    defaultValue: true,
    rules: {
      required: isRequired,
    },
  });

  return (
    <>
      <Text variant="M / Regular">{title}</Text>
      <div>
        <Checkbox
          label={description}
          invalid={isFieldInvalid}
          checked={field.value}
          name={field.name}
          onCheckedChange={field.onChange}
        />
      </div>
    </>
  );
};
