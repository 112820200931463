import { type FC, useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";

const OnboardingLayout: FC = () => {
  useLayoutEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <div className="flex h-full flex-col bg-surface-elevation-1">
      <Outlet />
    </div>
  );
};

export { OnboardingLayout };
