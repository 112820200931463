import { Popover as PopoverPrimitive } from "radix-ui";
import type { ComponentPropsWithoutRef, ElementRef, FC, ReactNode } from "react";
import { forwardRef } from "react";

import { TooltipQuestionIcon } from "@/components/icons";
import { IconClose } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { IconButton, popoverContentStyles, Text, Tooltip } from "..";

type HeaderProps = {
  title: ReactNode;
  centered?: boolean;
  hideClose?: boolean;
  tooltip?: ReactNode;
  titleSize?: "sm" | "md";
};

const Header: FC<HeaderProps> = ({ title, centered, hideClose, tooltip, titleSize = "md" }) => {
  return (
    <div
      className={cn("items-start gap-2 pb-2 pt-1", centered ? "grid grid-cols-[1fr_auto_1fr]" : "flex justify-between")}
    >
      {centered && <div className={cn("-ms-2 size-10", tooltip && !hideClose && "size-20")}></div>}
      <Text
        as="h3"
        className="py-2"
        variant={titleSize === "md" ? "L / Medium" : "M / Medium"}
        color="primary"
        align={centered ? "center" : "start"}
      >
        {title}
      </Text>
      <div className="-me-2 flex items-center justify-end">
        {tooltip && (
          <div className="grid size-10 place-items-center">
            <Tooltip content={tooltip}>
              <TooltipQuestionIcon />
            </Tooltip>
          </div>
        )}
        {!hideClose && (
          <PopoverPrimitive.Close asChild>
            <IconButton size="sm" variant="flat">
              <IconClose />
            </IconButton>
          </PopoverPrimitive.Close>
        )}
      </div>
    </div>
  );
};

const Footer: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div className="mt-6 flex flex-row-reverse flex-wrap gap-x-2 gap-y-3 *:flex-1">{children}</div>;
};

const Content = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  Omit<ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>, "className"> & HeaderProps
>(
  (
    { sideOffset = 8, collisionPadding = 15, title, centered, hideClose, tooltip, children, titleSize, ...props },
    ref,
  ) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        sideOffset={sideOffset}
        collisionPadding={collisionPadding}
        className={cn(
          popoverContentStyles(),
          "w-[min(320px,_90vw)] rounded-xl bg-surface-elevation-2 px-6 pb-6 pt-4 shadow-modal outline-none",
        )}
        ref={ref}
        {...props}
      >
        <Header title={title} titleSize={titleSize} tooltip={tooltip} hideClose={hideClose} centered={centered} />
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  ),
);
Content.displayName = PopoverPrimitive.Content.displayName;

const Component = Object.assign(PopoverPrimitive.Root, {
  Trigger: PopoverPrimitive.Trigger,
  Portal: PopoverPrimitive.Portal,
  PrimitiveContent: PopoverPrimitive.Content,
  Content,
  Close: PopoverPrimitive.Close,
  Anchor: PopoverPrimitive.Anchor,
  Arrow: PopoverPrimitive.Arrow,
  Footer,
});

export { Component as Popover };
