import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { createImagePathUrl } from "@/app/utils/images/helpers";
import type { HomeBlockNewsItem } from "@/services/openapi";
import { Dialog, Text } from "@/shared/ui";

import { HomeCard } from "../ui/card";
import { HomeCarousel } from "../ui/carousel";
import { HomeNewsDialogContent } from "./dialog-content";

type Props = {
  news: HomeBlockNewsItem[];
};

const HomeNewsContainer: FC<Props> = ({ news }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <Text as="h3" variant="L / Medium" color="primary">
        {t("home.highlights.title")}
      </Text>
      <HomeCarousel
        items={news}
        renderItem={item => (
          <HomeCarousel.Item key={item.title}>
            <Dialog>
              <Dialog.Trigger asChild>
                <HomeCard
                  title={item.title}
                  tags={item.tags!}
                  imageUrl={item.imageUrlPathsPreview![0] ? createImagePathUrl(item.imageUrlPathsPreview![0]) : null}
                  type="news"
                  onClick={() => {
                    track(amplitudeEvents.home.news.open, {
                      news_name: item.analyticName,
                    });
                  }}
                />
              </Dialog.Trigger>
              <Dialog.Content size="lg">
                <HomeNewsDialogContent newsItem={item} />
              </Dialog.Content>
            </Dialog>
          </HomeCarousel.Item>
        )}
      />
    </div>
  );
};

export { HomeNewsContainer };
