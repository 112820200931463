import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Button } from "@/shared/ui";
import { useArchiveAccountMutation } from "@/state/server/accounts";

type Props = {
  accountId: string;
};

const ArchiveAccountButton: FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { mutate, isLoading } = useArchiveAccountMutation({ successMessage: "account-archived" });

  return (
    <Button
      variant="secondary"
      size="sm"
      pending={isLoading}
      onClick={() =>
        mutate(
          { id: accountId },
          {
            onSuccess: () => {
              navigate({
                pathname: cabinetRoutes.myAccounts,
                search: createSearchParams({ tab: "Archived" }).toString(),
              });
            },
          },
        )
      }
    >
      {t("accounts.buttons.archive")}
    </Button>
  );
};

export { ArchiveAccountButton };
