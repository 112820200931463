import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import negativeImage from "@/assets/images/negative.png";
import { useAuth } from "@/contexts/auth.context";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { AuthHeader } from "@/layouts/auth/header";
import { CabinetLayout } from "@/layouts/cabinet/cabinet.layout";
import { CabinetFooter } from "@/layouts/cabinet/footer";
import { Button, Image, Text } from "@/shared/ui";
import { useProfileHeaderQuery } from "@/state/server/profile";
import { Header } from "@/widgets/header";
import { SidebarButton } from "@/widgets/sidebar/button";

import { authRoutes } from "./auth.routes";
import { getTerminalRoute } from "./terminal.routes";

const ErrorBlock: FC<{ isAuthenticated: boolean; notFound: boolean }> = ({ isAuthenticated, notFound }) => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto flex max-w-80 flex-col items-center justify-center gap-6">
      <div className="flex flex-col items-center gap-3">
        <Image src={negativeImage} className="size-[72px]" />
        <Text align="center" color="primary" variant="L / Medium">
          {notFound ? t("error.not-found-message") : t("error.went-wrong")}
        </Text>
      </div>
      {notFound ? (
        <>
          {isAuthenticated ? (
            <Button size="sm" asChild>
              <Link to={getTerminalRoute(null)} replace>
                {t("error.back-trading")}
              </Link>
            </Button>
          ) : (
            <Button size="sm" asChild>
              <Link to={authRoutes.signUp} replace>
                {t("error.back-main")}
              </Link>
            </Button>
          )}
        </>
      ) : (
        <Button size="sm" onClick={() => window.location.reload()}>
          {t("error.reload")}
        </Button>
      )}
    </div>
  );
};

const AuthenticatedErrorPage: FC<{ notFound: boolean }> = ({ notFound }) => {
  const { isDesktop } = useScreenSize();

  const { data } = useProfileHeaderQuery();

  return (
    <CabinetLayout.Wrapper
      isDesktop={isDesktop}
      headerSlot={
        data ? (
          <Header isReadOnly={!data.permissions!.isEnabled!} isHomeAvailable={data.homeInfo!.isHomeAvailable!} />
        ) : (
          <Header.Skeleton />
        )
      }
      sidebarSlot={
        data ? (
          <SidebarButton isReadOnly={!data.permissions!.isEnabled!} isHomeAvailable={data.homeInfo!.isHomeAvailable!} />
        ) : (
          <SidebarButton.Skeleton />
        )
      }
    >
      <ErrorBlock isAuthenticated notFound={!!notFound} />
    </CabinetLayout.Wrapper>
  );
};

type Props = {
  notFound?: boolean;
};

const ErrorPage: FC<Props> = ({ notFound = false }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <AuthenticatedErrorPage notFound={notFound} />
  ) : (
    <div className="grid h-full grid-rows-[auto_1fr_auto] bg-surface-canvas">
      <AuthHeader />
      <ErrorBlock isAuthenticated={false} notFound={notFound} />
      <CabinetFooter />
    </div>
  );
};

export { ErrorPage };
