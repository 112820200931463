import { cva } from "class-variance-authority";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { PositiveNumberFormat } from "@/app/components";
import { createImagePathUrl } from "@/app/utils/images/helpers";
import { IconLock, IconWarningCircle } from "@/domains/icons";
import { type TradingAccount, TradingAccountStatus, TradingAccountType } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Image, Skeleton as UISkeleton, Text } from "@/shared/ui";

import { LeverageBadge } from "./leverage";

const rowStyles = cva("flex items-center justify-between gap-2")();

type Props = {
  children: ReactNode;
  account: TradingAccount;
  isActive?: boolean;
  pnlSlot?: ReactNode;
  actionSlot?: ReactNode;
};

const Card: FC<Props> = ({ children, account, isActive, pnlSlot, actionSlot }) => {
  const { equity, currency, digits, login, status, leverage, type, currencyImageUrlPath, sourceOfFunds } = account;
  const { t } = useTranslation();
  const blockedFunds = sourceOfFunds?.blockedFunds;

  const isArchived = status === TradingAccountStatus.Archived;
  const isReal = type === TradingAccountType.Real;

  return (
    <div
      className={cn(
        "flex flex-col gap-4 rounded-xl border bg-card-bg p-5",
        isActive ? "border-positive-text" : "border-card-border",
      )}
    >
      <div className={rowStyles}>
        <Image width={40} height={40} alt={currency!} src={createImagePathUrl(currencyImageUrlPath!)} />
        {actionSlot}
      </div>
      <div className="flex flex-col gap-1 px-1">
        <div className={rowStyles}>
          <Text as="h3" variant="S / Regular" color="secondary">
            {isReal ? t("accounts.common.name-real", { login }) : t("accounts.common.name-demo", { login })}
          </Text>
          <LeverageBadge leverage={leverage!} />
        </div>
        <div className={rowStyles}>
          {!equity && blockedFunds ? (
            <div className="flex items-center gap-2">
              <IconLock className="size-5 text-contrast-secondary" />
              <Text variant="M / Medium" color="secondary">
                <PositiveNumberFormat value={blockedFunds} decimalScale={digits} currency={currency!} />
              </Text>
            </div>
          ) : equity && blockedFunds ? (
            <div className="flex items-center gap-2">
              <IconWarningCircle className="size-5 text-warning-text" />
              <Text variant="M / Medium" color="primary">
                <PositiveNumberFormat value={equity} decimalScale={digits} currency={currency!} />
              </Text>
            </div>
          ) : (
            <Text variant="M / Medium" color={isArchived ? "secondary" : "primary"}>
              <PositiveNumberFormat value={equity} decimalScale={digits} currency={currency!} />
            </Text>
          )}
          {isArchived ? (
            <Text variant="M / Medium" color="primary">
              {t("accounts.common.archived")}
            </Text>
          ) : (
            pnlSlot
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

const Buttons: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="mt-auto flex flex-wrap gap-2 *:flex-1">{children}</div>;
};

const Skeleton: FC = () => {
  return (
    <div className="flex flex-col gap-4 rounded-xl border border-card-border bg-card-bg p-5">
      <div className={rowStyles}>
        <UISkeleton className="size-10 rounded-full" />
        <UISkeleton className="h-5 w-1/2 rounded-sm" />
      </div>
      <div className="flex flex-col gap-2 px-1">
        <div className={rowStyles}>
          <UISkeleton className="h-5 w-1/2" />
          <UISkeleton className="h-4 w-7 rounded-2xs" />
        </div>
        <div className={rowStyles}>
          <UISkeleton className="h-5 w-1/4" />
          <UISkeleton className="h-5 w-1/6" />
        </div>
      </div>
      <UISkeleton className="h-10 w-full rounded-full" />
    </div>
  );
};

const Component = Object.assign(Card, {
  Buttons,
  Skeleton,
});

export { Component as AccountCard };
