import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, useState } from "react";

import { IconEye, IconEyeDisabled } from "@/domains/icons";

import { _Button, type _ExternalWrapperProps, _Input, _Wrapper } from "../_base";

type Props = Omit<_ExternalWrapperProps, "hasInputValue" | "endSection" | "size"> &
  OverrideProps<
    Omit<ComponentPropsWithoutRef<typeof _Input>, "type">,
    {
      value: string;
      onChange: (value: string) => void;
    }
  >;

const PasswordInput = forwardRef<ElementRef<typeof _Input>, Props>(
  (
    {
      value,
      onChange,
      className,
      label,
      startSection,
      disabled,
      invalid,
      pending,
      descriptor,
      floatingLabel = true,
      ...props
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <_Wrapper
        type="input"
        className={className}
        startSection={startSection}
        invalid={invalid}
        pending={pending}
        label={label}
        disabled={disabled}
        descriptor={descriptor}
        floatingLabel={floatingLabel}
        hasInputValue={value !== ""}
        endSection={
          <_Button disabled={disabled} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <IconEyeDisabled /> : <IconEye />}
          </_Button>
        }
      >
        <_Input
          type={showPassword ? "text" : "password"}
          disabled={disabled}
          value={value}
          onChange={e => onChange(e.target.value)}
          {...props}
          ref={ref}
        />
      </_Wrapper>
    );
  },
);

export { PasswordInput };
