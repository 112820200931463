import type { FC, ReactNode } from "react";

import type { OnboardingStep } from "@/features/onboarding/helpers";

import { ProgressBar } from "./progress-bar/progress-bar";

type Props = {
  steps: OnboardingStep[];
  currentStepId: string;
  children: ReactNode;
};

const SurveyWrapper: FC<Props> = ({ steps, currentStepId, children }) => {
  return (
    <div className="flex min-h-0 grow flex-col lg:pt-10">
      <ProgressBar steps={steps} currentStepId={currentStepId} />
      {children}
    </div>
  );
};

export { SurveyWrapper };
