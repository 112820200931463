import { type FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Logo } from "@/app/components";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { LanguageSwitcherButton, LanguageSwitcherContainer } from "@/features/language-switcher";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { Button, Dialog } from "@/shared/ui";

import { ProfileInfo } from "../profile-info/profile-info";
import DialogContainer from "./dialog-container";

type Props = {
  isSurveyCompleted: boolean;
  isSurveyMandatory: boolean;
  email: string;
  from: string | null;
  activeStepCount?: number;
};

const OnboardingHeader: FC<Props> = ({ isSurveyCompleted, isSurveyMandatory, email, activeStepCount, from }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [closeCounter, setCloseCounter] = useState(1);
  const [closeDialogOpened, { open: openCloseDialog, close: closeCloseDialog }] = useDisclosure();
  const [languageSwitcherOpened, { open: openLanguageSwitcher, onOpenChange: onOpenChangeLanguageSwitcher }] =
    useDisclosure();
  const [profileInfoOpened, { onOpenChange: onOpenChangeProfileInfo }] = useDisclosure();

  const isCloseButtonVisible = !isSurveyMandatory || isSurveyCompleted;

  const toTerminal = () => {
    navigate(getTerminalRoute(null));
  };

  const closeOnboarding = () => {
    if (activeStepCount) {
      track(amplitudeEvents.kyc.close(activeStepCount));
    }

    if (from) {
      navigate(from);
      return;
    }

    toTerminal();
  };

  const closeButtonAction = () => {
    if (closeCounter > 1) {
      closeOnboarding();
      return;
    }

    openCloseDialog();
    setCloseCounter(counter => ++counter);
  };

  return (
    <div className="mx-auto flex h-20 w-full max-w-[976px] shrink-0 items-center justify-between bg-surface-elevation-1 px-4 lg:px-0">
      {!(isSurveyMandatory && !isSurveyCompleted) ? (
        <Link to={cabinetRoutes.home}>
          <Logo />
        </Link>
      ) : (
        <Logo />
      )}
      <div className="flex items-center gap-6">
        <LanguageSwitcherButton onOpen={openLanguageSwitcher} />
        <LanguageSwitcherContainer onOpenChange={onOpenChangeLanguageSwitcher} open={languageSwitcherOpened} />

        {isCloseButtonVisible ? (
          <>
            <Button variant="secondary" size="sm" onClick={closeButtonAction}>
              {t("button.skip")}
            </Button>
            <Dialog open={closeDialogOpened} onOpenChange={closeCloseDialog}>
              <Dialog.Content>
                <DialogContainer onExplore={toTerminal} onContinue={closeCloseDialog} />
              </Dialog.Content>
            </Dialog>
          </>
        ) : (
          <ProfileInfo email={email} onOpenChange={onOpenChangeProfileInfo} open={profileInfoOpened} />
        )}
      </div>
    </div>
  );
};

export { OnboardingHeader };
