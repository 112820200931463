import useEmblaCarousel, { type UseEmblaCarouselType } from "embla-carousel-react";
import {
  type ComponentPropsWithoutRef,
  createContext,
  type ElementRef,
  forwardRef,
  type KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { IconArrowLeft, IconArrowRight } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { IconButton } from "..";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
} & CarouselProps;

const CarouselContext = createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

const Carousel = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div"> & CarouselProps>(
  ({ opts, setApi, plugins, className, children, ...props }, ref) => {
    const [carouselRef, api] = useEmblaCarousel(opts, plugins);
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api,
          opts,
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn("group relative grid", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = "Carousel";

const CarouselContent = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { carouselRef } = useCarousel();

    return (
      <div
        ref={carouselRef}
        onScroll={() => {
          console.log("dsa");
        }}
        className="overflow-hidden"
      >
        <div ref={ref} className={cn("flex", className)} {...props} />
      </div>
    );
  },
);
CarouselContent.displayName = "CarouselContent";

const CarouselItem = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn("me-4 min-w-0 shrink-0 grow-0 last:me-0", className)}
      {...props}
    />
  );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = forwardRef<ElementRef<typeof IconButton>, ComponentPropsWithoutRef<typeof IconButton>>(
  ({ className, variant = "secondary", size = "sm", ...props }, ref) => {
    const { scrollPrev, canScrollPrev } = useCarousel();

    if (!canScrollPrev) {
      return null;
    }

    return (
      <div
        className={cn(
          "absolute -start-5 top-1/2 hidden -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100 has-[:focus-visible]:opacity-100 lg:block",
          className,
        )}
      >
        <IconButton ref={ref} variant={variant} size={size} onClick={scrollPrev} {...props}>
          <IconArrowLeft />
        </IconButton>
      </div>
    );
  },
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = forwardRef<ElementRef<typeof IconButton>, ComponentPropsWithoutRef<typeof IconButton>>(
  ({ className, variant = "secondary", size = "sm", ...props }, ref) => {
    const { scrollNext, canScrollNext } = useCarousel();

    if (!canScrollNext) {
      return null;
    }

    return (
      <div
        className={cn(
          "absolute -end-5 top-1/2 hidden -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100 has-[:focus-visible]:opacity-100 lg:block",
          className,
        )}
      >
        <IconButton ref={ref} variant={variant} size={size} onClick={scrollNext} {...props}>
          <IconArrowRight />
        </IconButton>
      </div>
    );
  },
);
CarouselNext.displayName = "CarouselNext";

const Component = Object.assign(Carousel, {
  Content: CarouselContent,
  Item: CarouselItem,
  Previous: CarouselPrevious,
  Next: CarouselNext,
});

export { Component as Carousel };
export type { CarouselApi };
