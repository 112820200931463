import dayjs from "dayjs";
import type { ComponentProps, FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { logError } from "@/app/libs/sentry";
import { useClientId } from "@/hooks/client-id.hook";
import { useCookie } from "@/hooks/cookie.hook";
import { analyticsClickProperty } from "@/hooks/marketing-cookies.hook";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { useSignUpMutation } from "@/state/server/auth";
import { usePlatformTranslationsQuery } from "@/state/server/platform";
import { usePlatformData } from "@/state/server/platform/platform.hooks";
import { useProfileHeaderQuery } from "@/state/server/profile";
import { userPlatform } from "@/utils/helpers";

import { AuthWrapperQR } from "../../wrapper-qr";
import { SignUpForm } from "./signup.form";

const showDownloadButtons = userPlatform.os == "IOS" || userPlatform.os === "Android";

const SignUpContainer: FC = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const { data: translationsData } = usePlatformTranslationsQuery({ locale: i18n.resolvedLanguage });

  const { refetch } = useProfileHeaderQuery({ enabled: false });

  const clientId = useClientId();
  const [_, setCookie, getCookie] = useCookie();

  const navigate = useNavigate();
  const platformInfo = usePlatformData();
  const clickId = getCookie(analyticsClickProperty);

  const { mutateAsync: signUp } = useSignUpMutation();

  const handleSubmit: ComponentProps<typeof SignUpForm>["onSubmit"] = useCallback(
    values => {
      // FIXME:
      const timezoneOffsetMinutes: number = new Date().getTimezoneOffset();
      const timezone: number = -timezoneOffsetMinutes * 60;
      const languageCode = localStorage.getItem("i18nextLng");

      return signUp(
        { signUpRequest: { ...values, timezone, languageCode } },
        {
          onSuccess: async () => {
            try {
              const { data } = await refetch();
              const { id: userID } = data!;

              window.dataLayer?.push({
                event: "gtm_events",
                custom_timestamp: dayjs().valueOf(),
                userID,
                user_id: userID,
                client_id: clientId,
                ec: "register",
                ea: "register",
                el: "email",
                ga4_event_name: "register",
              });
              navigate(onboardingRoutes.onboarding);

              if (clickId) setCookie(analyticsClickProperty, "");
            } catch (e) {
              logError(e);
            }
          },
        },
      );
    },
    [signUp, clientId, navigate, setCookie, clickId, refetch],
  );

  const isByInvestingCampaign = searchParams.get("utm_source") === "brand_investing";

  return (
    <AuthWrapperQR
      title={t("auth.sign-up.title")}
      type="sign-up"
      qrLink={platformInfo.app!.appUniversalUrl!}
      titleClassname={isByInvestingCampaign ? "mb-6" : void 0}
    >
      <SignUpForm
        isByInvestingCampaign={isByInvestingCampaign}
        platformInfo={platformInfo}
        onSubmit={handleSubmit}
        translationsData={translationsData}
        showDownloadButtons={showDownloadButtons}
        downloadLink={platformInfo.app!.appUniversalUrl!}
      />
    </AuthWrapperQR>
  );
};

export { SignUpContainer };
