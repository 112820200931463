import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { QRCode } from "@/app/components";
import { DownloadAndroidButton, DownloadIOSButton } from "@/app/components/download-buttons";
import googleAuth from "@/domains/icons/Logos/google-authenticator-color.svg";
import { Text } from "@/shared/ui";
import { userPlatform } from "@/utils/helpers";

type Props = {
  link: string;
};

const GoogleAuthenticatorBlock: FC<Props> = ({ link }) => {
  const { t } = useTranslation();

  if (userPlatform.os == "IOS" || userPlatform.os === "Android") {
    return (
      <div className="flex flex-col gap-3 rounded-lg bg-card-bg p-6">
        <Text color="primary" variant="M / Medium">
          {t("two-factor.scan-qr.title")}
        </Text>
        {userPlatform.os === "IOS" && <DownloadIOSButton link={link} />}
        {userPlatform.os === "Android" && <DownloadAndroidButton link={link} />}
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between gap-8 rounded-lg bg-card-bg p-6">
      <div className="flex flex-col gap-2">
        <Text color="primary" variant="L / Medium">
          {t("two-factor.scan-qr.title")}
        </Text>
        <Text color="secondary" variant="S / Regular">
          {t("two-factor.scan-qr.description")}
        </Text>
      </div>
      <div className="text-contrast-primary">
        <QRCode value={link} size={96} imageSettings={{ src: googleAuth, width: 26, height: 26, excavate: true }} />
      </div>
    </div>
  );
};

export { GoogleAuthenticatorBlock };
