import { useStore } from "@nanostores/react";
import { produce } from "immer";
import { type FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";

import { PnlFormat, PositiveNumberFormat } from "@/app/components";
import { useLocationPath } from "@/app/hooks";
import { getNumberTextColor } from "@/app/ui/colors";
import { createImagePathUrl } from "@/app/utils/images/helpers";
import { AccountBadge } from "@/entities/accounts/badge";
import { AccountMTDetailsButton } from "@/entities/accounts/mt-details/button";
import { getShowBonusCondition } from "@/entities/bonuses/helpers";
import { SourceOfFundsBanner } from "@/entities/source-of-funds/banner";
import { getDepositRoute } from "@/routes/cabinet.routes";
import {
  type BonusUserPlatform,
  type SourceOfFundsStatus,
  type TradingAccount,
  TradingAccountType,
} from "@/services/openapi";
import { $accountsBalancesMessage } from "@/services/websocket";
import { ActionList, Image, Text } from "@/shared/ui";
import { accountsQueryKeys } from "@/state/server/accounts";

import { AccountDetailsBonusRiskBlock } from "../terminal/account-details/bonus-risk/block";
import { AccountDetailsMarginLevelBlock } from "../terminal/account-details/margin-level/block";
import { ArchiveAccountButton } from "./archive/button";
import { AccountDemoButtons } from "./buttons/demo";
import { AccountRealButtons } from "./buttons/real";
import { ChangeLeverageActionButton } from "./change-leverage/action-button";
import { ChangeAccountPasswordActionButton } from "./change-password/action-button";
import { AccountDetailsDataList } from "./details/data-list";
import { MTDetailsActionButton } from "./mt-details/action-button";

type Props = {
  account: TradingAccount;
  bonuses: BonusUserPlatform[];
  sourceOfFundsStatus: SourceOfFundsStatus;
};

const AccountContainer: FC<Props> = ({ account, bonuses, sourceOfFundsStatus }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const {
    currency,
    type,
    id,
    pnL,
    digits,
    login,
    equity,
    actions,
    credit,
    marginFree,
    balance,
    serverAccountId,
    availableToWithdraw,
    currencyImageUrlPath,
    margin,
    marginLevel,
    attributes,
    sourceOfFunds,
  } = account;
  const path = useLocationPath();
  const { canArchive, canChangeLeverage } = actions!;
  const { hasOpenPositions } = attributes!;

  const isReal = type === TradingAccountType.Real;

  const accountsBalancesMessage = useStore($accountsBalancesMessage);

  const bonus = useMemo(() => (bonuses.length > 0 ? bonuses[0]! : null), [bonuses]);

  const showBonus = useMemo(
    () =>
      getShowBonusCondition({
        allowedTradingServerAccounts: bonus?.allowedTradingServerAccounts || [],
        serverAccountId: serverAccountId!,
        credit: credit!,
      }),
    [bonus?.allowedTradingServerAccounts, serverAccountId, credit],
  );

  useEffect(() => {
    if (!accountsBalancesMessage) return;

    const accountUpdates = accountsBalancesMessage.a!.find(({ ai }) => ai === account.id);

    if (accountUpdates) {
      queryClient.setQueryData<TradingAccount>(accountsQueryKeys.account(account.id!), oldData => {
        return produce(oldData, draft => {
          if (draft) {
            draft.balance = accountUpdates.b;
            draft.credit = accountUpdates.c;
            draft.equity = accountUpdates.e;
            draft.pnL = accountUpdates.p;
            draft.margin = accountUpdates.m;
            draft.marginFree = accountUpdates.mf;
            draft.marginLevel = accountUpdates.ml;
            draft.availableToWithdraw = accountUpdates.aw;
            draft.attributes!.hasOpenPositions = accountUpdates.op;
          }
        })!;
      });
    }
  }, [accountsBalancesMessage]);

  return (
    <>
      <div className="mb-6 flex items-start justify-between gap-2">
        <Image width={52} height={52} alt={currency!} src={createImagePathUrl(currencyImageUrlPath!)} />
        <div className="flex items-center gap-2">
          <AccountBadge type={type!} />
          <AccountMTDetailsButton account={account} />
        </div>
      </div>
      <div className="mb-6 mt-3 flex flex-col gap-1 px-1">
        <Text variant="S / Regular" color="secondary">
          {isReal ? t("accounts.common.name-real", { login }) : t("accounts.common.name-demo", { login })}
        </Text>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <Text variant="L / Medium" color="primary">
            <PositiveNumberFormat value={equity} decimalScale={digits} currency={currency!} showFullValue />
          </Text>
          {!!pnL && (
            <Text variant="L / Medium" color={getNumberTextColor(pnL)}>
              <PnlFormat value={pnL} decimalScale={digits} currency={currency!} showFullValue />
            </Text>
          )}
        </div>
      </div>

      {sourceOfFundsStatus.requested && (
        <SourceOfFundsBanner
          className="mb-4"
          amount={sourceOfFundsStatus.treshold!}
          currency={sourceOfFundsStatus.currency!}
          decimalScale={sourceOfFundsStatus.digits!}
        />
      )}

      <div className="mb-6 flex flex-col gap-4">
        {isReal ? <AccountRealButtons account={account} /> : <AccountDemoButtons account={account} />}
        {showBonus ? (
          <AccountDetailsBonusRiskBlock
            credit={credit!}
            currency={currency!}
            currencyDecimalScale={digits!}
            equity={equity!}
            onDeposit={() =>
              navigate({ pathname: getDepositRoute(id!), search: createSearchParams({ from: path }).toString() })
            }
            variant="default"
          />
        ) : (
          <AccountDetailsMarginLevelBlock
            variant="default"
            marginLevel={marginLevel!}
            hasOpenPositions={hasOpenPositions!}
          />
        )}
        <AccountDetailsDataList
          bonus={bonus}
          showBonus={showBonus}
          accountCurrency={currency!}
          equity={equity!}
          balance={balance!}
          credit={credit!}
          margin={margin!}
          marginFree={marginFree!}
          pnl={pnL!}
          currencyDecimalScale={digits!}
          marginLevel={marginLevel!}
          type={type!}
          availableToWithdraw={availableToWithdraw!}
          blockedFunds={sourceOfFunds?.blockedFunds}
        />
        <ActionList>
          <MTDetailsActionButton account={account} />
          <ChangeAccountPasswordActionButton account={account} />
          {canChangeLeverage && <ChangeLeverageActionButton account={account} hasOpenPositions={hasOpenPositions!} />}
        </ActionList>
      </div>
      {canArchive && <ArchiveAccountButton accountId={id!} />}
    </>
  );
};

export { AccountContainer };
