import { cva, type VariantProps } from "class-variance-authority";
import { Slot } from "radix-ui";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, memo } from "react";

import { cn } from "@/shared/styles";

const linkStyles = cva("inline outline-none transition-colors hover:underline focus-visible:underline", {
  variants: {
    variant: {
      inherit: "typography-inherit",
      "M Compact / Medium": "typography-M-Compact-Medium",
    },
    color: {
      accent: "text-positive-text hover:text-positive-bg-hover",
      regular: "text-contrast-secondary hover:text-contrast-primary",
    },
  },
});

type Props = ComponentPropsWithoutRef<"a"> &
  VariantProps<typeof linkStyles> & {
    asChild?: boolean;
  };

const Link = forwardRef<ElementRef<"a">, Props>(
  ({ children, className, asChild, variant = "inherit", color = "accent", ...props }, ref) => {
    const Comp = asChild ? Slot.Root : "a";
    return (
      <Comp className={cn(linkStyles({ variant, color }), className)} {...props} ref={ref}>
        {children}
      </Comp>
    );
  },
);

const Component = memo(Link);

Component.displayName = "Link";

export { Component as Link };
