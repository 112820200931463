import { type ComponentProps, type FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { type BonusUserPlatform, FundsTransferType, type TradingAccount } from "@/services/openapi";
import { useTransferMutation } from "@/state/server/funds";

import { getInputNumberValue } from "../terminal/helpers/formatting";
import { TransferForm } from "./transfer.form";

type Props = {
  accounts: TradingAccount[];
  bonuses: BonusUserPlatform[];
  accountId: string | null;
  from: string | null;
};

const TransferContainer: FC<Props> = ({ accounts, bonuses, accountId, from }) => {
  const navigate = useNavigate();

  const { mutateAsync: transfer } = useTransferMutation({ successMessage: "transfer-confirmed" });

  const handleSubmit: ComponentProps<typeof TransferForm>["onSubmit"] = useCallback(
    ({ amount, destinationId, sourceId }) => {
      return transfer(
        {
          fundsTransferRequest: {
            destinationId,
            sourceId,
            amount: getInputNumberValue(amount),
            sourceType: FundsTransferType.TradingAccount,
            destinationType: FundsTransferType.TradingAccount,
          },
        },
        {
          onSuccess: () => {
            navigate(from || cabinetRoutes.myAccounts);
          },
        },
      );
    },
    [transfer, navigate, from],
  );

  return <TransferForm bonus={bonuses} accounts={accounts} accountId={accountId} onSubmit={handleSubmit} />;
};

export { TransferContainer };
